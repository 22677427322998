import React, { FC } from 'react';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';

import css from './AdminMenuButton.module.css';
import { CogOutline } from 'heroicons-react';
import { Link } from 'react-router-dom';

export const AdminMenuButton: FC = (props) => {
  return (
    <Link to="/admin">
      <ButtonBase className={css.AdminMenuButton} {...props}>
        <CogOutline path={undefined} />
        Admin
      </ButtonBase>
    </Link>
  );
};
