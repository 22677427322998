import { makeRouteMap, makeUseNavigate } from 'toolbox';
import { useHistory } from 'react-router-dom';
import { makeUseParamsAndSearch } from 'toolbox/src/utils/makeUseNavigate';

export const routeMap = makeRouteMap({
  authenticator: {
    path: '/auth',
  },
  root: {
    path: '/',
  },
  loans: {
    path: '/loans',
  },
  adminNewLoan: {
    path: '/admin/loans/new',
    search: {
      customerId: true,
    },
  },
  adminMessages: {
    path: '/admin/messages',
  },
  adminGroups: {
    path: '/admin/groups',
  },
  adminBroadcasts: {
    path: '/admin/notices',
  },
  adminSettings: {
    path: '/admin/settings',
  },
  adminMessageTemplates: {
    path: '/admin/templates',
  },
  adminMessageTemplateById: {
    path: '/admin/templates/:id',
    params: {
      id: true,
    },
  },
  adminUsers: {
    path: '/admin/users',
  },
  admin: {
    path: '/admin',
  },
});

export const useNavigate = makeUseNavigate(routeMap, useHistory);
export const useParamsAndSearch = makeUseParamsAndSearch(routeMap);
