import { Messages_Insert_Input, Users } from 'graphql/schemaTypes.generated';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetMessageByIdQuery } from './graphql/GetMessageById.generated';
import { useGetUsersForMessageQuery } from './graphql/GetUsers.generated';
import { useUpsertMessageMutation } from './graphql/UpsertMessage.generated';
import { useDeleteMessageMutation } from './graphql/DeleteMessage.generated';
import { ButtonBase, useWrappedUrqlMutation } from 'toolbox';
import { Input } from 'components/Inputs/Input';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { InputWrapper } from 'components/Inputs/InputWrapper';
import {
  ChatAltOutline,
  EyeOutline,
  PhoneOutline,
  MailOutline,
  BellOutline,
} from 'heroicons-react';
import { toast } from 'react-toastify';
import Select, { MultiValue, Options } from 'react-select';
import dayjs from 'dayjs';
interface IEditMessagePage {
  messageId: string;
  onClose: () => void;
}

export const EditMessagePage: FC<IEditMessagePage> = ({
  messageId,
  onClose,
}) => {
  const history = useHistory();
  const isNewMessage = messageId === 'new';

  const [q, reload] = useGetMessageByIdQuery({
    variables: { id: messageId || '' },
    pause: !messageId || isNewMessage,
  });

  const [qUsers] = useGetUsersForMessageQuery({
    // pause: Boolean(messageId) || !isNewMessage,
  });

  const message = q.data?.message;

  type UserOption = {
    label: string;
    value: Users['cognito_sub'];
  };

  // form state
  const [users, setUsers] = useState<UserOption[]>([]);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [recipients, setRecipients] = useState<MultiValue<UserOption>>([]);

  // load the list of users
  useEffect(() => {
    if (qUsers?.data) {
      setUsers([
        { label: 'Select All', value: 'all' },
        ...qUsers?.data?.users.map((user) => ({
          label: user.email,
          value: user.cognito_sub,
        })),
      ]);
    }
  }, [qUsers]);

  // if not a new message, load the data
  useEffect(() => {
    if (isNewMessage) {
      setRecipients([]);
      setSubject('');
      setContent('');
    } else {
      if (q?.data) {
        setRecipients(
          message?.recipients?.map(({ user }) => ({
            value: user?.cognito_sub,
            label: user?.email,
          })) || [],
        );
        setSubject(message?.subject || '');
        setContent(message?.content || '');
      }
    }
  }, [q, messageId]);

  const valid =
    subject.length > 0 && content.length > 0 && recipients.length > 0;

  const deleteMutation = useWrappedUrqlMutation(useDeleteMessageMutation, {
    onSuccess: () => history.push('/admin/messages'),
  });

  const del = async () => {
    if (
      confirm(
        'Deleting this message will not recall notifications delivered by email or SMS. Continue?',
      )
    )
      await deleteMutation.dispatch({ id: messageId });
  };

  const saveMessageMutation = useWrappedUrqlMutation(useUpsertMessageMutation, {
    onSuccess: (data) => {
      if (isNewMessage) {
        toast.success('Message sent');
        onClose?.();
        history.push(`/admin/messages/${data?.insert_messages_one?.id}`);
      } else {
        toast.success('Saved changes');
        reload();
      }
    },
  });

  const save = async () => {
    await saveMessageMutation.dispatch({
      message: {
        recipients: {
          data: recipients.map((r) => ({ user_cognito_sub: r.value })),
        },
        subject,
        content,
      },
    });
  };

  if (q?.fetching) {
    return <LoadingSpinner className="float-right w-6 h-6 text-gblue-600" />;
  }

  return (
    <section className="self-stretch">
      <h3 className="p-3 pb-3 bg-white rounded-t flex space-x-2 font-semibold">
        <ChatAltOutline path={undefined} />
        <span>{isNewMessage ? `New Message` : message?.subject}</span>
      </h3>

      <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
        <div className="flex justify-end p-2">
          {!isNewMessage ? (
            <ButtonBase
              className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
              onClick={() => del()}
            >
              Delete
            </ButtonBase>
          ) : (
            <button
              className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-500 disabled:opacity-50"
              disabled={!valid}
              onClick={() => save()}
            >
              Send
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-3 p-3 pb-10 bg-white rounded-b">
        <label className="bg-gray-100 rounded border-2">
          <span className="text-2xs font-semibold text-gray-700 uppercase pl-2 py-1 block">
            Recipients
          </span>

          <div className="text-xs">
            <Select
              options={users}
              // filterOption={(option) => formData.recipients.some(option)}
              value={recipients}
              onChange={(selected: MultiValue<UserOption>) => {
                if (
                  selected?.length &&
                  selected.find((option) => option.value === 'all')
                ) {
                  setRecipients(users.filter((user) => user.value !== 'all'));
                } else {
                  setRecipients(selected);
                }
              }}
              isMulti={true}
              isLoading={qUsers.fetching}
              isDisabled={!isNewMessage}
            />
          </div>
        </label>

        <Input
          disabled={!isNewMessage}
          type="text"
          labelText="Subject"
          className="col-span-3"
          value={subject || ''}
          onChange={(e) => setSubject(e.target.value)}
        />

        <InputWrapper labelText="Message Body">
          <textarea
            disabled={!isNewMessage}
            // labelText="Subject"
            className="text-base p-2 text-black disabled:bg-gray-100 disabled:text-gray-600"
            rows={12}
            value={content || ''}
            onChange={(e) => setContent(e.target.value)}
          />
        </InputWrapper>

        {isNewMessage && (
          <ul>
            <li>
              <code>{`{{name}}`}</code> will be replaced with the recipient's
              full name (e.g. John Doe)
            </li>
            <li>
              <code>{`{{firstname}}`}</code> will be replaced with the
              recipient's first name (e.g. John)
            </li>
          </ul>
        )}

        {!isNewMessage && (
          <>
            <h2 className="font-semibold">Message Activity</h2>
            {message && message.read_reciepts?.length > 0 ? (
              <ul className="text-xs space-y-1">
                {message?.read_reciepts.map(({ created_at, user, type }, i) => {
                  switch (type) {
                    case 'email':
                      return (
                        <li className="flex items-center space-x-2">
                          <MailOutline
                            path={undefined}
                            className="inline w-3 h-3"
                          />
                          <span>
                            Email delivered to {user?.email}
                            {dayjs(created_at).format(
                              ` [at] HH:mm [on] DD/MM/YYYY`,
                            )}
                          </span>
                        </li>
                      );
                    case 'sms':
                      return (
                        <li className="flex items-center space-x-2">
                          <PhoneOutline
                            path={undefined}
                            className="inline w-3 h-3"
                          />
                          <span>
                            SMS delivered to {user?.phone_number} ({user.name})
                            {dayjs(created_at).format(
                              ` [at] HH:mm [on] DD/MM/YYYY`,
                            )}
                          </span>
                        </li>
                      );
                    case 'push':
                      return user?.user_devices.map((device) => (
                        <li className="flex items-center space-x-2">
                          <BellOutline
                            path={undefined}
                            className="inline w-3 h-3"
                          />
                          <span>
                            Push notification to {user.name}'s{' '}
                            {device.manufacturer} {device.model}
                            {dayjs(created_at).format(
                              ` [at] HH:mm [on] DD/MM/YYYY`,
                            )}
                          </span>
                        </li>
                      ));
                    case 'app':
                    default:
                      return (
                        <li className="flex items-center space-x-2" key={i}>
                          <EyeOutline
                            path={undefined}
                            className="inline w-3 h-3"
                          />
                          <span>
                            Read by {user.name} in app
                            {dayjs(created_at).format(
                              ` [at] HH:mm [on] DD/MM/YYYY`,
                            )}
                          </span>
                        </li>
                      );
                  }
                })}
              </ul>
            ) : (
              <p className="text-sm text-gray-600">No events yet</p>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default EditMessagePage;
