import { ComponentType, LazyExoticComponent, lazy } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

export function lazyWithRetry(
  component: () => ComponentPromise,
  retries?: number,
  interval?: number,
  onFail?: () => void,
): LazyExoticComponent<ComponentType<any>> {
  return lazy(() => retry(component, retries, interval, onFail));
}

function retry(
  fn: () => ComponentPromise,
  retriesLeft = 1,
  interval = 1000,
  onFail = () => window.location.reload(), // by default we reload the page when a ChunkLoadError occurs
): ComponentPromise {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return onFail();
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default lazyWithRetry;
