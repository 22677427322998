import React, { FC, ChangeEvent, useState } from 'react';
import { InputWrapper, IInputWrapper } from './InputWrapper';
import classNames from 'classnames';
import {
  Backspace,
  LockClosed,
  EyeOutline,
  EyeOffOutline,
} from 'heroicons-react';

interface IInput
  extends IInputWrapper,
    React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  type?: 'text' | 'number' | 'password' | 'email' | 'search' | 'tel';
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  clearButton?: boolean;
  clearButtonHandler?: () => void;
  prefix?: string;
  suffix?: string;
  error?: string | null;
  disabled?: boolean;
}

export const Input: FC<IInput> = ({
  type = 'text',
  value = '',
  onChange = () => undefined,
  small = false,
  clearButton = false,
  clearButtonHandler = () => undefined,
  hintText,
  labelText,
  labelPosition,
  prefix,
  className,
  suffix,
  placeholder,
  spellCheck = false,
  autoCapitalize = 'false',
  disabled = false,
  error = null,
  ...props
}) => {
  const defaultFilter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 27 && clearButtonHandler) clearButtonHandler(); // clear on Esc key
  };
  const numericFilter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 27 && clearButtonHandler) clearButtonHandler(); // clear on Esc key
    if (
      (event.keyCode <= 46 && event.keyCode !== 32) ||
      event.ctrlKey ||
      event.metaKey
    )
      return; // allow non-char keys (other than space)
    if (event.key.match(/[^\d.-]/g)) event.preventDefault(); // numbers and decimals only
    if (event.key === '.' && event?.currentTarget?.value.match(/.*\..*/g))
      event.preventDefault(); // decimal can't exist more than once
    //@ts-ignore
    if (event.key === '-' && event.currentTarget.selectionStart > 0)
      event.preventDefault(); // only allow minus symbol as the first char
  };

  const [toggleType, setToggleType] = useState(type);
  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <InputWrapper
      isFocussed={isFocussed}
      disabled={disabled}
      error={error}
      hintText={hintText}
      className={className}
      labelPosition={labelPosition}
      labelText={labelText}
      {...props}
    >
      <div
        className={classNames(
          'w-full flex justify-between items-center overflow-hidden',
          disabled ? '' : 'bg-white focus-within:bg-white',
          (hintText || error) && 'pt-0',
        )}
      >
        {prefix && (
          <span
            className={classNames(
              'pl-3 font-normal flex-shrink',
              small ? 'text-sm' : 'text-base',
            )}
          >
            {prefix}
          </span>
        )}

        <input
          aria-label={labelText as string}
          type={type === 'number' ? 'text' : toggleType}
          inputMode={type === 'number' ? 'numeric' : undefined}
          value={value || ''}
          disabled={disabled}
          onChange={onChange}
          className={classNames(
            'min-w-0 flex-grow bg-transparent placeholder-gray-400 focus:outline-none px-2 py-2',
            small ? 'text-xs' : 'text-base',
            disabled
              ? 'cursor-default select-none text-gray-600'
              : 'text-gray-900',
          )}
          placeholder={
            placeholder ? placeholder : type === 'number' ? '0.00' : undefined
          }
          onKeyDown={(e) =>
            type === 'number' ? numericFilter(e) : defaultFilter(e)
          }
          onFocusCapture={() => setIsFocussed(true)}
          onBlurCapture={() => setIsFocussed(false)}
          spellCheck={spellCheck}
          autoCapitalize={autoCapitalize}
          size={props.size || 16}
          {...props}
        />

        {type === 'password' && (
          <span
            className="pr-3 cursor-pointer"
            onClick={(e) =>
              toggleType === 'password'
                ? setToggleType('text')
                : setToggleType('password')
            }
          >
            {toggleType === 'password' ? (
              <EyeOffOutline path={undefined} className="w-5 text-gray-400" />
            ) : (
              <EyeOutline path={undefined} className="w-5 text-red-400" />
            )}
          </span>
        )}

        {suffix && (
          <span
            className={classNames(
              'pr-3 font-normal text-gray-500 flex-shrink-0',
              small ? 'text-2xs' : 'text-xs',
            )}
          >
            {suffix}
          </span>
        )}

        {disabled === true ? (
          <span className="flex items-center mr-1 text-gray-400">
            <LockClosed path={undefined} className="inline w-5 mr-1" />
          </span>
        ) : (
          clearButton &&
          value?.length > 0 && (
            <Backspace
              path={undefined}
              className="w-5 mr-2 text-gray-600 opacity-25 cursor-pointer hover:opacity-100"
              onClick={clearButtonHandler}
            />
          )
        )}
      </div>
    </InputWrapper>
  );
};
