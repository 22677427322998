import { GetLoggedInUserQuery } from 'components/LoggedInUserProvider/graphql/GetLoggedInUserByUsername.generated';
import css from 'components/ModalBase/Modal.module.css';
import listCss from 'components/ModalBase/ModalList.module.css';
import { ModalTopBar } from 'components/ModalBase/ModalTopBar/ModalTopBar';
import { Overlay } from 'components/Overlay/Overlay';
import dayjs from 'dayjs';
import { motion, useReducedMotion } from 'framer-motion';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { FC } from 'react';
import { CheckCircle, Clock } from 'heroicons-react';
import classNames from 'classnames';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { calcEarlySettlementFee } from 'helpers/earlySettlement';

import advancedFormat from 'dayjs/plugin/advancedFormat';
// const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface ILoanDetailsModal {
  loan: NonNullable<
    NonNullable<GetLoggedInUserQuery['user']>['selected_customer']
  >['loans'][number];
  closeHandler?: () => void;
}

export const LoanDetailsModal: FC<ILoanDetailsModal> = ({
  loan,
  closeHandler,
}) => {
  const reducedMotion = useReducedMotion();

  // if (!loan?.payments || loan?.payments.length < 1) return null;

  const start_date = dayjs(loan?.contract_date);

  const first_payment_date = dayjs(loan?.first_payment_date);

  const next_payment_date = dayjs(
    loan?.payments?.find((payment) => payment.reconciled === false)
      ?.payment_due_date,
  );

  // const end_date = dayjs(start_date).add(loan?.term_months ?? 0, 'month');
  const number_of_payments = loan?.payments?.length - 1 ?? 0;
  const end_date = dayjs(
    loan?.payments?.[number_of_payments]?.payment_due_date,
  );

  const remaining_principal =
    loan?.payments_aggregate_unpaid?.aggregate?.sum?.split_principal ?? 0;

  const calculateMonthsMessage = (months: number) => {
    if (months > 1) {
      return months + ' months interest';
    } else {
      return 'the current months interest';
    }
  };

  // const remaining_interest_and_fees =
  //   (loan?.payments_aggregate_unpaid?.aggregate?.sum?.split_fee ?? 0) +
  //   (loan?.payments_aggregate_unpaid?.aggregate?.sum?.split_interest ?? 0);

  return (
    <motion.div className={css.Modal}>
      <Overlay>
        <ModalTopBar
          title={`Loan GRF${loan?.reference}`}
          buttons={
            <ButtonBase
              onClick={closeHandler}
              className="text-white bg-teal-400 btn"
            >
              Close
            </ButtonBase>
          }
        />
        <motion.div className={css.CardPadding}>
          <motion.div
            className={classNames(
              css.Card,
              'bg-gradient-to-t from-gnavy-900 to-gnavy-700 rounded-t-lg',
            )}
            initial={
              reducedMotion
                ? { opacity: 1, scale: 1 }
                : { opacity: 0, scale: 0 }
            }
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 200 }}
          >
            <section className="text-white">
              <h2 className="text-lg">Agreement</h2>
              <ul className="leading-loose">
                <li>
                  A <b>{formatCurrency(loan?.amount, 'GBP')}</b>{' '}
                  {loan?.type === 'personal'
                    ? 'Personal Loan'
                    : loan?.type === 'hpa'
                      ? 'Hire Purchase Agreement'
                      : loan?.type === 'interest_only'
                        ? 'Interest Only Loan'
                        : loan?.type === 'bespoke'
                          ? loan?.custom_type_name || 'Loan'
                          : 'Loan'}{' '}
                  was agreed on the {start_date.format('Do [of] MMMM YYYY')},
                  with an Interest Rate of{' '}
                  {parseFloat(formatCurrency(loan?.apr)).toFixed(2)}%.
                </li>

                {loan?.fees > 0 ? (
                  <li>
                    The documentation fee for this loan is{' '}
                    {formatCurrency(loan?.fees, 'GBP')}.
                  </li>
                ) : undefined}

                {loan?.payments?.length > 0 && (
                  <li>
                    The first of repayment of{' '}
                    {formatCurrency(loan?.payments[0].amount, 'GBP')}{' '}
                    {loan?.payments[0].reconciled ? 'was ' : 'will be '}
                    collected on the{' '}
                    {first_payment_date.format('Do [of] MMMM YYYY')}.
                  </li>
                )}

                {loan?.payments && loan?.payments?.length > 1 && (
                  <li>
                    Payments will be collected on the{' '}
                    {first_payment_date.format('Do')} of each month.
                  </li>
                )}
                {loan?.last_payment_fee ? (
                  <li>
                    There is an option-to-purchase fee of{' '}
                    {formatCurrency(loan?.last_payment_fee, 'GBP')} to be added
                    to the final repayment
                  </li>
                ) : undefined}
              </ul>
            </section>
            <section className="grid grid-flow-col gap-3 text-white">
              {remaining_principal > 0 ? (
                <div className="flex flex-col items-center justify-center px-3 py-4 font-semibold text-center bg-red-100 rounded-lg md:px-6 ">
                  <p className="text-sm text-red-700">
                    NET Balance
                    {/*formatCurrency(remaining_interest_and_fees, 'GBP')*/}
                  </p>
                  <p className="text-lg leading-tight text-red-700 sm:text-2xl md:text-3xl">
                    {formatCurrency(remaining_principal, 'GBP')}{' '}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center px-3 py-4 font-semibold text-center bg-blue-100 rounded-lg md:px-6 ">
                  <p className="text-lg leading-tight text-blue-700 sm:text-2xl md:text-3xl">
                    Zero Balance
                  </p>
                </div>
              )}

              {/*<div className="flex flex-col items-center justify-center px-3 py-4 font-semibold text-center text-white bg-blue-600 rounded-lg md:px-6 ">
                <p className="text-blue-200">Fees and Interest</p>
                <p className="text-xl leading-tight">
                  {formatCurrency(remaining_interest_and_fees, 'GBP')}
                </p>
              </div>*/}

              <div className="flex flex-col items-center justify-center px-3 py-4 font-semibold text-center text-white bg-blue-600 rounded-lg md:px-6 ">
                <p className="text-blue-200">Ends</p>
                <p className="text-base leading-tight sm:text-lg">
                  {end_date.format('MMMM YYYY')}
                </p>
              </div>
            </section>

            <section className="-mt-3 text-white">
              <ButtonBase
                onClick={() =>
                  alert(
                    `Early settlement balance - £${
                      calcEarlySettlementFee(
                        loan.payments,
                        loan?.early_settlement_penalty_months_interest,
                        loan?.last_payment_fee,
                      ).total
                    }, which includes ${calculateMonthsMessage(
                      loan?.early_settlement_penalty_months_interest,
                    )}.\n\nPlease pay to Gouldson Finance Limited, Natwest 60-12-03, a/c 27299805, quoting reference GRF${
                      loan.reference
                    }`,
                  )
                }
                className="text-white bg-gnavy-800 btn"
              >
                Early Settlement Quote
              </ButtonBase>
            </section>

            <section className="-mt-3 text-white">
              <h2 className="text-base">Repayment Schedule</h2>
            </section>

            <ul className={listCss.ModalList}>
              {loan?.payments.map((repayment, i) => (
                <li key={i}>
                  <div className="flex items-stretch justify-between px-4 py-3">
                    <div className="flex-grow truncate">
                      <div>
                        {formatCurrency(repayment?.amount, 'GBP')} on{' '}
                        {dayjs(repayment?.payment_due_date).format(
                          'Do MMMM YYYY',
                        )}
                      </div>
                      <div className="text-gray-500 text-2xs">
                        {repayment?.split_interest > 0 && (
                          <span>
                            {formatCurrency(repayment?.split_interest, 'GBP')}{' '}
                            interest
                          </span>
                        )}
                        {repayment?.split_fee > 0 && (
                          <span>
                            {repayment?.split_interest ? ', ' : undefined}
                            {formatCurrency(repayment?.split_fee, 'GBP')} fees
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center flex-shrink-0">
                      {repayment?.reconciled && (
                        <div className="flex items-center flex-shrink-0 py-px pl-px pr-2 text-xs font-semibold tracking-wide text-green-500 uppercase bg-green-200 rounded-full">
                          <CheckCircle
                            path={undefined}
                            className="w-5 h-5 ml-px mr-1"
                          />
                          received
                        </div>
                      )}
                      {dayjs(repayment?.payment_due_date).isSame(
                        next_payment_date,
                      ) && (
                        <div className="flex items-center flex-shrink-0 py-px pl-px pr-2 text-xs font-semibold tracking-wide uppercase rounded-full bg-gblue-200 text-gblue-600">
                          <Clock
                            path={undefined}
                            className="inline w-5 h-5 ml-px mr-1"
                          />
                          due next
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
      </Overlay>
    </motion.div>
  );
};
