import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertLoanMutationVariables = {
  object: Types.Loans_Insert_Input
};


export type UpsertLoanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_loans_one: Types.Maybe<(
    { __typename?: 'loans' }
    & Pick<Types.Loans, 'id' | 'customer_id' | 'reference' | 'amount' | 'contract_date' | 'first_payment_date' | 'apr' | 'term_months' | 'type' | 'last_payment_fee' | 'custom_type_name' | 'early_settlement_penalty_months_interest'>
  )> }
);


export const UpsertLoanDocument = gql`
    mutation UpsertLoan($object: loans_insert_input!) {
  insert_loans_one(object: $object, on_conflict: {constraint: loans_pkey, update_columns: [customer_id, reference, amount, contract_date, first_payment_date, apr, term_months, type, last_payment_fee, custom_type_name, early_settlement_penalty_months_interest]}) {
    id
    customer_id
    reference
    amount
    contract_date
    first_payment_date
    apr
    term_months
    type
    last_payment_fee
    custom_type_name
    early_settlement_penalty_months_interest
  }
}
    `;

export function useUpsertLoanMutation() {
  return Urql.useMutation<UpsertLoanMutation, UpsertLoanMutationVariables>(UpsertLoanDocument);
};