import { Input } from 'components/Inputs/Input';
import React, { FC, useState, Suspense } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { EditLoanPage } from './EditLoanPage';
import { NewLoanPage } from './NewLoanPage';
import { GetLoansQuery, useGetLoansQuery } from './graphql/GetLoans.generated';
import { formatCurrency } from 'helpers/formatCurrency';
import dayjs from 'dayjs';
import { ToggleSwitch } from 'components/Inputs/ToggleSwitch/ToggleSwitch';

export const ManageLoans: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query] = useGetLoansQuery();
  const [loanListFilter, setLoanListFilter] = useState('');
  const [showQuotesOnly, setShowQuotesOnly] = useState(false);
  const [expandedLoan, setExpandedLoan] = useState(
    location.pathname.split('/admin/loans/')?.[1] || null,
  );

  const loans = query?.data?.loans || [];

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-grow bg-gblue-700">
        <div className="overflow-hidden border-l-8 border-r-8 rounded-tl-lg border-gblue-700 AdminScrollbars">
          <h3 className="mt-2 text-lg font-semibold text-white">Loans</h3>
          <div className="flex items-stretch justify-end py-2">
            <Input
              type="number"
              placeholder="1234"
              value={loanListFilter}
              onChange={(e) => setLoanListFilter(e.target.value)}
              small
              icon={
                <div className="font-semibold text-gray-500 text-2xs">REF</div>
              }
              clearButtonHandler={() => setLoanListFilter('')}
              spellCheck="false"
              className="flex-grow"
              size={6}
            />
            <Link
              to="/admin/loans/new"
              className="flex items-center px-2 my-px ml-2 text-sm font-semibold text-teal-700 bg-teal-200 rounded"
              onClick={() => setExpandedLoan('new')}
            >
              <span className="truncate">New Loan</span>
            </Link>
          </div>
          <div className="flex items-center justify-end w-full h-6 mb-2">
            <div className="mr-2 text-sm font-semibold text-gray-300">
              Show Quotes Only
            </div>
            <ToggleSwitch
              small
              onChange={(e) => setShowQuotesOnly(!showQuotesOnly)}
              checked={showQuotesOnly}
            />
          </div>
          <div className="h-full pb-48 overflow-x-hidden overflow-y-scroll rounded h-200 AdminScrollbars">
            {query?.fetching ? (
              <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-gray-500">
                  Loading...
                </div>
              </li>
            ) : query?.error ? (
              <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-red-700">
                  Error: {query?.error?.message}
                </div>
              </li>
            ) : (
              loans
                ?.filter(({ is_approved }) =>
                  showQuotesOnly ? is_approved === false : true,
                )
                ?.filter(({ reference }) =>
                  reference
                    ?.toString()
                    .toLowerCase()
                    .includes(
                      loanListFilter.toLowerCase(),
                      //.replace(/^[0]+/g, ''),
                    ),
                )
                .sort((a, b) => (a?.reference > b?.reference ? 1 : -1))
                .map(
                  (loan) =>
                    loan?.id && (
                      <LoanRow
                        key={loan.id}
                        loan={loan}
                        expanded={expandedLoan === String(loan?.id)}
                        openHandler={() => setExpandedLoan(loan?.id.toString())}
                        closeHandler={() => {
                          setExpandedLoan('');
                          history.push('/admin/loans');
                        }}
                      />
                    ),
                )
            )}
          </div>
        </div>

        <div className="flex-grow w-2/3 h-screen mt-2 overflow-x-hidden overflow-y-scroll AdminScrollbars">
          {expandedLoan ? (
            expandedLoan === 'new' ? (
              <NewLoanPage
                closeHandler={(loan_id) => {
                  loan_id ? setExpandedLoan(loan_id) : setExpandedLoan(null);
                  history.push('/admin/loans/' + loan_id);
                }}
                nextLoanId={loans[loans?.length - 1]?.reference + 1}
              />
            ) : (
              <EditLoanPage
                loanId={expandedLoan}
                closeHandler={() => setExpandedLoan(null)}
              />
            )
          ) : (
            <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
              Click on a loan to view information
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

interface ILoanRow {
  loan: GetLoansQuery['loans'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}
const LoanRow: FC<ILoanRow> = ({
  loan,
  expanded = false,
  openHandler,
  closeHandler,
}) => {
  if (!loan?.reference) return null;
  const contract_month = dayjs(loan?.contract_date).format('MMM YYYY');
  return (
    <NavLink
      to={`/admin/loans/${loan?.id}`}
      className="flex justify-between p-2 bg-teal-100"
      activeClassName="bg-teal-300"
      id={loan?.id}
      onClick={expanded ? closeHandler : openHandler}
    >
      <div className="flex flex-col justify-center text-xs">
        <h3
          className={`font-semibold text-gray-800 flex items-center ${
            expanded ? 'text-teal-900' : ''
          }`}
        >
          {loan?.is_approved ? 'GRF' : 'QUO'}
          {loan?.reference.toString().padStart(4, '0')}
        </h3>
        <span className="truncate opacity-50 text-2xs">
          {loan?.customer?.name.substring(0, 15)}... | {contract_month} |{' '}
          {formatCurrency(loan?.amount, 'GBP')}
        </span>
      </div>
    </NavLink>
  );
};
