import { makeMutationForm } from 'components/makeMutationForm';
import { Customers } from 'graphql/schemaTypes.generated';

export const EditCustomerForm = makeMutationForm<
  Partial<Customers> & { type: 'business' | 'individual' }
>({
  config: {
    name: {
      type: 'text',
    },
    rate_id: {
      type: 'radioGroup',
    },
    // business_unit_id: {
    //   type: 'select',
    // },
    // individual: {
    //   type: 'radioGroup',
    // },
    type: {
      type: 'radioGroup',
    },
    date_established: {
      type: 'date',
    },
    date_incorporated: {
      type: 'date',
    },
    nature_of_business: {
      type: 'text',
    },
    place_of_incorporation: {
      type: 'text',
    },
    loan_fee: {
      type: 'number',
    },
  },
  showErrorsOnTouched: true,
  validate: ({ rate_id, loan_fee, name, business_unit_id }) => {
    if (!name) {
      return {
        rate_id: 'You must specify a name',
      };
    }
    // if (!business_unit_id) {
    //   return {
    //     rate_id: 'Please choose a business unit',
    //   };
    if (!rate_id) {
      return {
        rate_id: 'Please choose a rate',
      };
    }
    if (!loan_fee || isNaN(loan_fee)) {
      return {
        rate_id: 'Please specify a documentation fee',
      };
    }
    if (loan_fee < 0) {
      return {
        rate_id: 'Documentation fee cannot be less than zero',
      };
    }
  },
});
