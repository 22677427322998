import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetLoggedInUserQueryVariables = {
  cognito_sub: Types.Scalars['String']
};


export type GetLoggedInUserQuery = (
  { __typename?: 'query_root' }
  & { user: Types.Maybe<(
    { __typename?: 'users' }
    & Pick<Types.Users, 'cognito_sub' | 'email' | 'name' | 'phone_number' | 'self_employed' | 'maiden_name' | 'date_of_birth' | 'title' | 'gender' | 'marital_status' | 'number_of_children' | 'nationality' | 'residential_status' | 'place_of_birth' | 'current_employer' | 'current_occupation' | 'years_at_current_employer' | 'previous_employer' | 'previous_occupation' | 'years_at_previous_employer' | 'notify_by_app' | 'notify_by_email' | 'notify_by_sms' | 'notify_on_payment_received' | 'last_seen_at' | 'selected_customer_id'>
    & { selected_customer: Types.Maybe<(
      { __typename?: 'customers' }
      & Pick<Types.Customers, 'id' | 'name' | 'individual' | 'loan_fee'>
      & { rate: (
        { __typename?: 'rate_cards' }
        & Pick<Types.Rate_Cards, 'id' | 'apr_lte_3000' | 'apr_gt_3000_lte_5000' | 'apr_gt_5000_lte_10000' | 'apr_gt_10000_lte_15000' | 'apr_gt_15000_lte_25000' | 'apr_gt_25000'>
      ), loans: Array<(
        { __typename?: 'loans' }
        & Pick<Types.Loans, 'id' | 'reference' | 'amount' | 'apr' | 'created_at' | 'contract_date' | 'first_payment_date' | 'is_approved' | 'term_months' | 'last_payment_fee' | 'fees' | 'email_address' | 'early_settlement_penalty_months_interest' | 'customer_name' | 'type' | 'custom_type_name'>
        & { payments: Array<(
          { __typename?: 'payments' }
          & Pick<Types.Payments, 'id' | 'amount' | 'split_principal' | 'split_interest' | 'split_fee' | 'reconciled' | 'payment_due_date'>
        )>, payments_aggregate: (
          { __typename?: 'payments_aggregate' }
          & { aggregate: Types.Maybe<(
            { __typename?: 'payments_aggregate_fields' }
            & Pick<Types.Payments_Aggregate_Fields, 'count'>
            & { sum: Types.Maybe<(
              { __typename?: 'payments_sum_fields' }
              & Pick<Types.Payments_Sum_Fields, 'amount' | 'split_principal' | 'split_interest' | 'split_fee'>
            )> }
          )> }
        ), payments_aggregate_repaid: (
          { __typename?: 'payments_aggregate' }
          & { aggregate: Types.Maybe<(
            { __typename?: 'payments_aggregate_fields' }
            & Pick<Types.Payments_Aggregate_Fields, 'count'>
            & { sum: Types.Maybe<(
              { __typename?: 'payments_sum_fields' }
              & Pick<Types.Payments_Sum_Fields, 'amount' | 'split_principal' | 'split_interest' | 'split_fee'>
            )> }
          )> }
        ), payments_aggregate_unpaid: (
          { __typename?: 'payments_aggregate' }
          & { aggregate: Types.Maybe<(
            { __typename?: 'payments_aggregate_fields' }
            & Pick<Types.Payments_Aggregate_Fields, 'count'>
            & { sum: Types.Maybe<(
              { __typename?: 'payments_sum_fields' }
              & Pick<Types.Payments_Sum_Fields, 'amount' | 'split_principal' | 'split_interest' | 'split_fee'>
            )> }
          )> }
        ) }
      )> }
    )>, customers: Array<(
      { __typename?: 'delegates' }
      & { customer: (
        { __typename?: 'customers' }
        & Pick<Types.Customers, 'id' | 'name' | 'individual'>
      ) }
    )> }
  )> }
);


export const GetLoggedInUserDocument = gql`
    query GetLoggedInUser($cognito_sub: String!) {
  user: users_by_pk(cognito_sub: $cognito_sub) {
    cognito_sub
    email
    name
    phone_number
    self_employed
    maiden_name
    date_of_birth
    title
    gender
    marital_status
    number_of_children
    nationality
    residential_status
    place_of_birth
    current_employer
    current_occupation
    years_at_current_employer
    previous_employer
    previous_occupation
    years_at_previous_employer
    notify_by_app
    notify_by_email
    notify_by_sms
    notify_on_payment_received
    last_seen_at
    selected_customer_id
    selected_customer {
      id
      name
      individual
      loan_fee
      rate {
        id
        apr_lte_3000
        apr_gt_3000_lte_5000
        apr_gt_5000_lte_10000
        apr_gt_10000_lte_15000
        apr_gt_15000_lte_25000
        apr_gt_25000
      }
      loans(where: {is_declined: {_eq: false}}, order_by: {is_approved: desc, contract_date: asc}) {
        id
        reference
        amount
        apr
        created_at
        contract_date
        first_payment_date
        is_approved
        term_months
        last_payment_fee
        fees
        email_address
        early_settlement_penalty_months_interest
        customer_name
        type
        custom_type_name
        payments(order_by: {payment_due_date: asc}) {
          id
          amount
          split_principal
          split_interest
          split_fee
          reconciled
          payment_due_date
        }
        payments_aggregate {
          aggregate {
            count
            sum {
              amount
              split_principal
              split_interest
              split_fee
            }
          }
        }
        payments_aggregate_repaid: payments_aggregate(where: {reconciled: {_eq: true}}) {
          aggregate {
            count
            sum {
              amount
              split_principal
              split_interest
              split_fee
            }
          }
        }
        payments_aggregate_unpaid: payments_aggregate(where: {_and: {reconciled: {_eq: false}}}) {
          aggregate {
            count
            sum {
              amount
              split_principal
              split_interest
              split_fee
            }
          }
        }
      }
    }
    customers {
      customer {
        id
        name
        individual
      }
    }
  }
}
    `;

export function useGetLoggedInUserQuery(options: Omit<Urql.UseQueryArgs<GetLoggedInUserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetLoggedInUserQuery>({ query: GetLoggedInUserDocument, ...options });
};