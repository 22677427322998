import React, { FC, Suspense, useEffect, useState } from 'react';
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { EditBroadcastPage } from './EditBroadcastPage';
import {
  useGetBroadcastsQuery,
  GetBroadcastsQuery,
} from './graphql/GetBroadcasts.generated';

export const ManageBroadcasts: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query, refresh] = useGetBroadcastsQuery();
  const [expandedBroadcast, setExpandedBroadcast] = useState(
    location.pathname.split('/admin/notices/')?.[1] || null,
  );

  useEffect(() => {
    setExpandedBroadcast(
      location.pathname.split('/admin/notices/')?.[1] || null,
    );
  }, [location]);

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-col flex-grow overflow-hidden bg-gblue-700 rounded-tl-md">
        <div className="flex self-stretch justify-start">
          <div className="relative flex-grow pb-2 overflow-x-hidden overflow-y-scroll border-l-8 border-r-8 AdminScrollbars border-gblue-700 w-32">
            <h3 className="mt-2 text-lg font-semibold text-white">Notices</h3>
            <div className="flex items-stretch justify-end py-2">
              <Link
                to="/admin/notices/new"
                className="flex items-center p-2 my-px text-sm font-semibold text-teal-700 bg-teal-200 rounded w-full justify-center"
                onClick={() => setExpandedBroadcast('new')}
              >
                <span className="truncate">New Notice</span>
              </Link>
            </div>

            <div className="text-2xs mb-2 text-white bg-blue-500 p-2 rounded">
              ℹ️ Notices are displayed in the Messages screen (in-app) but do
              not trigger SMS/PUSH/Email notifications
            </div>

            <div className="overflow-hidden rounded">
              {query?.fetching ? (
                <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-gray-500">
                    Loading...
                  </div>
                </li>
              ) : query?.error ? (
                <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-red-700">
                    Error: {query?.error?.message}
                  </div>
                </li>
              ) : (
                query?.data?.broadcasts.map(
                  (broadcast) =>
                    broadcast?.id && (
                      <BroadcastRow
                        key={broadcast.id}
                        broadcast={broadcast}
                        expanded={expandedBroadcast === broadcast.id}
                        openHandler={() => {
                          setExpandedBroadcast(broadcast.id);
                        }}
                        closeHandler={() => {
                          setExpandedBroadcast(null);
                          history.push('/admin/notices');
                          refresh();
                        }}
                      />
                    ),
                )
              )}
            </div>
          </div>

          <div className="w-2/3 flex-grow h-screen overflow-y-scroll overflow-x-hidden AdminScrollbars mt-2">
            {expandedBroadcast ? (
              <EditBroadcastPage
                broadcastId={expandedBroadcast}
                // closeHandler={() => setExpandedBroadcast(null)}
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
                Choose a broadcast to edit
              </div>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

interface IBroadcastRow {
  broadcast: GetBroadcastsQuery['broadcasts'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}

const BroadcastRow: FC<IBroadcastRow> = ({
  broadcast,
  expanded = false,
  // openHandler,
  // closeHandler,
}) => {
  if (!broadcast?.id) return null;
  return (
    <NavLink
      to={`/admin/notices/${broadcast?.id}`}
      className="flex flex-col justify-center p-2 text-xs bg-teal-100"
      activeClassName="bg-teal-300"
      id={broadcast?.id}
      // onClick={expanded ? closeHandler : openHandler}
    >
      <h3
        className={`text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        } flex justify-between items-center`}
      >
        <div className="truncate flex-grow font-semibold">
          {broadcast?.subject}
        </div>
      </h3>
      <h4
        className={`text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        } flex justify-between items-center text-2xs`}
      >
        <div className="flex-shrink-0">
          {dayjs(broadcast?.activate_at).format('YYYY-MM-DD')}
        </div>
        <div className="flex-shrink-0 px-1">&rarr;</div>
        <div className="truncate flex-grow">
          {broadcast?.recipient_group_id}
        </div>
      </h4>
    </NavLink>
  );
};
