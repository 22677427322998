import classNames from 'classnames';
import { motion, MotionProps } from 'framer-motion';
import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';

import css from './ToggleSwitch.module.css';

interface IToggleSwitch extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  label?: string;
  small?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => any | void;
}

export const ToggleSwitch: FC<IToggleSwitch & MotionProps> = ({
  checked = false,
  label,
  small = false,
  onChange,
  ...props
}) => {
  return (
    <label className={css.ToggleSwitchLabel}>
      {label}
      <motion.div
        animate
        className={classNames(css.ToggleSwitch, {
          [css.SwitchedOn]: checked,
          [css.Small]: small,
        })}
      >
        <input
          // {...props}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={css.HiddenCheckBox}
          name={props.name}
          id={props.id}
        />
        <motion.div className={css.SwitchKnob} animate />
      </motion.div>
    </label>
  );
};
