import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useGetBroadcastByIdQuery } from './graphql/GetBroadcastById.generated';
import { useGetGroupsForBroadcastQuery } from './graphql/GetGroups.generated';
import { ButtonBase, useWrappedUrqlMutation } from 'toolbox';
import { Broadcasts_Insert_Input } from 'graphql/schemaTypes.generated';
import { Input } from 'components/Inputs/Input';
import { Select } from 'components/Inputs/SelectDropdown/Select';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { InputWrapper } from 'components/Inputs/InputWrapper';
import { useDeleteBroadcastByIdMutation } from './graphql/DeleteBroadcastById.generated';
import { useUpsertBroadcastMutation } from './graphql/UpsertBroadcast.generated';
import { useHistory } from 'react-router-dom';
import { SpeakerphoneOutline } from 'heroicons-react';

interface IEditBroadcastPage {
  broadcastId: string;
}

export const EditBroadcastPage: FC<IEditBroadcastPage> = ({ broadcastId }) => {
  const history = useHistory();
  const isNewBroadcast = broadcastId === 'new';

  console.log(broadcastId);

  const [q, reload] = useGetBroadcastByIdQuery({
    variables: { id: broadcastId || '' },
    pause: !broadcastId || isNewBroadcast,
  });

  const [qGroups] = useGetGroupsForBroadcastQuery();

  const broadcast = q.data?.broadcast;
  const groups = qGroups.data?.groups;

  const initialFormData = {
    recipient_group_id: '',
    subject: '',
    content: '',
  };

  const [formData, setFormData] = useState<Broadcasts_Insert_Input>(
    initialFormData,
  );

  const valid = () => {
    if (formData.subject && formData.content && formData.recipient_group_id)
      return true;
    return false;
  };

  const touched = () => {
    if (
      formData !== initialFormData &&
      (formData.content !== broadcast?.content ||
        formData.recipient_group_id !== broadcast?.recipient_group_id ||
        formData.subject !== broadcast?.subject)
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (q?.data) {
      if (!isNewBroadcast) setFormData(broadcast as any);
      else setFormData(initialFormData);
    } else {
      setFormData(initialFormData);
    }
  }, [q, broadcastId]);

  const deleteMutation = useWrappedUrqlMutation(
    useDeleteBroadcastByIdMutation,
    { onSuccess: () => history.push('/admin/notices') },
  );

  const del = async () => {
    if (confirm('Are you sure you want to delete this notice?'))
      await deleteMutation.dispatch({ id: broadcastId });
  };

  const saveMutation = useWrappedUrqlMutation(useUpsertBroadcastMutation, {
    onSuccess: (data) => {
      if (isNewBroadcast)
        history.push(`/admin/notices/${data?.insert_broadcasts_one?.id}`);
      else reload();
    },
  });

  const save = async () => {
    await saveMutation.dispatch({
      object: {
        id: formData.id,
        recipient_group_id: formData.recipient_group_id,
        subject: formData.subject,
        content: formData.content,
        draft: formData.draft,
      },
    });
  };

  if (q?.fetching) {
    return <LoadingSpinner className="float-right w-6 h-6 text-gblue-600" />;
  }

  return (
    <section className="self-stretch">
      <h3 className="p-3 pb-3 bg-white font-semibold rounded-t flex space-x-2">
        <SpeakerphoneOutline path={undefined} />{' '}
        <span>{isNewBroadcast ? `New Notice` : broadcast?.subject}</span>
      </h3>

      <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
        <div className="flex justify-end p-2">
          {!isNewBroadcast ? (
            <ButtonBase
              className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
              onClick={() => del()}
            >
              Delete
            </ButtonBase>
          ) : (
            <button
              className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-500 disabled:opacity-50"
              disabled={!touched() || (!valid() && touched())}
              onClick={() => save()}
            >
              Create Broadcast
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-3 p-3 pb-10 bg-white rounded-b">
        <Select
          value={formData.recipient_group_id || ''}
          label="Visible To"
          options={groups || []}
          valueAccessor={(i) => i.id}
          labelAccessor={(i) => i.id}
          onChange={(e: any) => {
            setFormData({ ...formData, recipient_group_id: e.target.value });
          }}
          disabled={!isNewBroadcast}
        />

        {/*!isNewBroadcast && (
          <InputWrapper labelText="Visibility">
            <div className="p-2 flex items-center h-full space-x-2">
              <ToggleSwitch
                small
                checked={!formData.draft || false}
                onChange={(e) => {
                  setFormData({ ...formData, draft: !e.target.checked });
                }}
              />
              {formData.draft ? (
                <span className="text-gray-500">Draft</span>
              ) : (
                <span className="text-green-600">Published</span>
              )}
            </div>
          </InputWrapper>
        )*/}

        <Input
          type="text"
          labelText="Subject"
          className="col-span-3"
          value={formData.subject || ''}
          onChange={(e) =>
            setFormData({ ...formData, subject: e.target.value })
          }
          disabled={!isNewBroadcast}
        />

        <InputWrapper labelText="Message Body">
          <textarea
            disabled={!isNewBroadcast}
            // labelText="Subject"
            className="text-base p-2 text-black disabled:bg-gray-100 disabled:text-gray-600"
            rows={12}
            value={formData.content || ''}
            onChange={(e) =>
              setFormData({ ...formData, content: e.target.value })
            }
          />
        </InputWrapper>
      </div>
    </section>
  );
};
