import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertBroadcastMutationVariables = {
  object: Types.Broadcasts_Insert_Input
};


export type UpsertBroadcastMutation = (
  { __typename?: 'mutation_root' }
  & { insert_broadcasts_one: Types.Maybe<(
    { __typename?: 'broadcasts' }
    & Pick<Types.Broadcasts, 'id'>
  )> }
);


export const UpsertBroadcastDocument = gql`
    mutation UpsertBroadcast($object: broadcasts_insert_input!) {
  insert_broadcasts_one(object: $object, on_conflict: {constraint: broadcasts_pkey, update_columns: [id, activate_at, disable_at, subject, content, draft, recipient_group_id]}) {
    id
  }
}
    `;

export function useUpsertBroadcastMutation() {
  return Urql.useMutation<UpsertBroadcastMutation, UpsertBroadcastMutationVariables>(UpsertBroadcastDocument);
};