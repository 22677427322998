import { Input } from 'components/Inputs/Input';
import React, { FC, useState, Suspense } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { EditUserPage } from './EditUserPage';
import { GetUsersQuery, useGetUsersQuery } from './graphql/GetUsers.generated';
import { SearchOutline } from 'heroicons-react';

export const ManageUsers: FC = () => {
  const location = useLocation();
  const [query] = useGetUsersQuery();
  const [userListFilter, setUserListFilter] = useState('');
  const [expandedUser, setExpandedUser] = useState(
    location.pathname.split('/admin/users/')?.[1] || null,
  );

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-grow bg-gblue-700">
        <div className="overflow-hidden border-l-8 border-r-8 rounded-tl-lg border-gblue-700 AdminScrollbars">
          <h3 className="mt-2 text-lg font-semibold text-white">User Logins</h3>
          <div className="flex items-stretch justify-end py-2">
            <Input
              type="search"
              placeholder="Filter Users"
              value={userListFilter}
              onChange={(e) => setUserListFilter(e.target.value)}
              icon={<SearchOutline path={undefined} className="w-4" />}
              small
              clearButtonHandler={() => setUserListFilter('')}
              spellCheck="false"
              className="flex-grow"
              size={6}
            />
            <Link
              to="/admin/users/new"
              className="flex items-center px-2 my-px ml-2 text-sm font-semibold text-teal-700 bg-teal-200 rounded"
              onClick={() => setExpandedUser('new')}
            >
              <span className="truncate">New User</span>
            </Link>
          </div>
          <div className="h-full pb-48 overflow-x-hidden overflow-y-scroll rounded h-200 AdminScrollbars">
            {query?.fetching ? (
              <li className="!bg-gray-300 rounded h-24 flex items-center">
                <div className="w-full font-semibold text-center text-gray-500">
                  Loading...
                </div>
              </li>
            ) : query?.error ? (
              <li className="!bg-red-200 rounded h-24 flex items-center">
                <div className="w-full font-semibold text-center text-red-700">
                  Error: {query?.error?.message}
                </div>
              </li>
            ) : (
              query?.data?.users
                ?.filter(
                  ({ email, name }) =>
                    email
                      ?.toLowerCase()
                      .includes(userListFilter.toLowerCase()) ||
                    name?.toLowerCase().includes(userListFilter.toLowerCase()),
                )
                .sort((a, b) => (a?.email > b?.email ? 1 : -1))
                .map(
                  (user) =>
                    user?.cognito_sub && (
                      <UserRow
                        key={user.cognito_sub}
                        user={user}
                        expanded={expandedUser === user?.cognito_sub}
                        openHandler={() => setExpandedUser(user?.cognito_sub)}
                        closeHandler={() => {
                          setExpandedUser('');
                          // history.push('/admin/users');
                        }}
                      />
                    ),
                )
            )}
          </div>
        </div>

        <div className="flex-grow w-2/3 h-screen mt-2 overflow-x-hidden overflow-y-scroll AdminScrollbars">
          {expandedUser ? (
            <EditUserPage
              cognitoSub={expandedUser}
              closeHandler={(nextCognitoSub) => {
                setExpandedUser(nextCognitoSub || null);
              }}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
              Click on a user to edit their information
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

interface IUserRow {
  user: GetUsersQuery['users'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}
const UserRow: FC<IUserRow> = ({
  user,
  expanded = false,
  openHandler,
  closeHandler,
}) => {
  if (!user?.cognito_sub) return null;
  // const customer_count = user?.customers_aggregate?.aggregate?.count || 0;
  return (
    <NavLink
      to={`/admin/users/${user?.cognito_sub}`}
      className="flex justify-between p-2 bg-teal-100"
      activeClassName="bg-teal-300"
      id={user?.cognito_sub}
      onClick={expanded ? closeHandler : openHandler}
    >
      <div className="flex items-center text-xs">
        <h3
          className={`font-semibold text-teal-800 flex items-center ${
            expanded ? 'text-teal-900' : ''
          }`}
        >
          {user?.email}
        </h3>
        {/* {customer_count > 0 && (
            <span
              className={classNames(
                'text-gray-500 text-2xs px-2 truncate flex items-center rounded-full border border-gray-300 ml-2',
                customer_count > 1 &&
                  'bg-blue-100 text-blue-500 border-blue-300',
              )}
            >
              {customer_count} account{customer_count > 1 && 's'}
            </span>
          )} */}
      </div>
    </NavLink>
  );
};
