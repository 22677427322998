import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetTemplateByIdQueryVariables = {
  id: Types.Scalars['uuid']
};


export type GetTemplateByIdQuery = (
  { __typename?: 'query_root' }
  & { message_templates_by_pk: Types.Maybe<(
    { __typename?: 'message_templates' }
    & Pick<Types.Message_Templates, 'id' | 'template_title' | 'template_body' | 'template_type_id' | 'is_active' | 'is_archived' | 'created_at' | 'updated_at'>
    & { message_template_type: (
      { __typename?: 'message_template_types' }
      & Pick<Types.Message_Template_Types, 'title' | 'description'>
    ), messages_sents: Array<(
      { __typename?: 'messages_sent' }
      & Pick<Types.Messages_Sent, 'user_id'>
    )> }
  )>, message_template_types: Array<(
    { __typename?: 'message_template_types' }
    & Pick<Types.Message_Template_Types, 'id' | 'title' | 'description'>
  )> }
);


export const GetTemplateByIdDocument = gql`
    query GetTemplateById($id: uuid!) {
  message_templates_by_pk(id: $id) {
    id
    template_title
    template_body
    template_type_id
    message_template_type {
      title
      description
    }
    messages_sents {
      user_id
    }
    is_active
    is_archived
    created_at
    updated_at
  }
  message_template_types {
    id
    title
    description
  }
}
    `;

export function useGetTemplateByIdQuery(options: Omit<Urql.UseQueryArgs<GetTemplateByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetTemplateByIdQuery>({ query: GetTemplateByIdDocument, ...options });
};