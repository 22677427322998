import { makeMutationForm } from 'components/makeMutationForm';
import { Payments } from 'graphql/schemaTypes.generated';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useWrappedUrqlMutation } from 'toolbox';
import { useDeletePaymentByIdMutation } from './graphql/DeletePaymentById.generated';
import { useUpsertPaymentMutation } from './graphql/UpsertPayment.generated';

export const EditPaymentForm = makeMutationForm<Partial<Payments>>({
  config: {
    payment_due_date: { type: 'date' },
    amount: { type: 'number', className: ' w-16', label: '' },
    split_fee: { type: 'number', className: ' w-16' },
    split_interest: { type: 'number', className: ' w-16' },
    split_principal: { type: 'number', className: ' w-16' },
  },
  showErrorsOnTouched: true,
  validate: ({ amount, split_fee, split_interest, split_principal }) => {
    if (amount < 0) return { amount: 'Loan amount must be greater than zero' };
    if (split_fee < 0) return { amount: 'Split fee must be greater than zero' };
    if (split_interest < 0)
      return { amount: 'Split interest must be greater than zero' };
    if (split_principal < 0)
      return { amount: 'Split principal must be greater than zero' };
  },
});

interface EditPaymentProps {
  setActivePaymentId: (paymentId: string) => void;
  paymentId?: string;
  loanId?: string;
  customerId?: string;
  paymentDueDate?: string;
  amount?: number;
  splitFee?: number;
  splitInterest?: number;
  splitPrincipal?: number;
  onSuccess?: () => void;
}

export const EditPaymentRow: FC<EditPaymentProps> = ({
  setActivePaymentId,
  paymentId,
  loanId,
  customerId,
  paymentDueDate,
  amount,
  splitFee,
  splitInterest,
  splitPrincipal,
  onSuccess,
}) => {
  const upsertPayment = useWrappedUrqlMutation(useUpsertPaymentMutation, {
    onSuccess: (res) => {
      toast.success('Updated payment succesfully');
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: (e) => {
      console.warn(e);
      toast.error('Could not save payment changes');
    },
  });

  const submitForm = async (formValues: Partial<Payments>) => {
    setActivePaymentId('');
    upsertPayment.dispatch({
      object: {
        id: paymentId,
        loan_id: loanId,
        customer_id: customerId,
        amount: formValues?.amount,
        payment_due_date: formValues?.payment_due_date,
        split_principal: formValues?.split_principal,
        split_interest: formValues?.split_interest,
        split_fee: formValues?.split_fee,
      },
    });
  };

  const deletePaymentMutation = useWrappedUrqlMutation(
    useDeletePaymentByIdMutation,
    {
      onSuccess: (res) => console.log(res),
      onError: (e) => console.warn(e),
    },
  );

  const deletePayment = () => {
    if (
      window.confirm(`Are you sure you want to delete this payment?`) &&
      paymentId
    ) {
      deletePaymentMutation.dispatch({ id: paymentId });
      toast.success('Deleted payment');
    }
  };

  return (
    <EditPaymentForm.Wrapper
      onSubmit={submitForm}
      initialValues={{
        amount: amount,
        payment_due_date: paymentDueDate,
        split_fee: splitFee,
        split_principal: splitPrincipal,
        split_interest: splitInterest,
      }}
      className="flex items-center w-full space-x-2"
    >
      <EditPaymentForm.Inputs.payment_due_date
        labelText="Date"
        className="w-40"
      />
      <EditPaymentForm.Inputs.amount labelText="Amount" className="w-20" />
      <EditPaymentForm.Inputs.split_principal
        labelText="Principal"
        className="w-20"
      />
      <EditPaymentForm.Inputs.split_interest
        labelText="Interest"
        className="w-20"
      />
      <EditPaymentForm.Inputs.split_fee labelText="Fees" className="w-20" />
      <button
        type="button"
        className="h-8 px-4 py-1 text-xs font-semibold text-red-500 bg-white border-2 border-red-500 rounded-md shadow-none hover:bg-red-100 hover:shadow-md"
        onClick={deletePayment}
      >
        Delete
      </button>
      <button
        className="h-8 px-4 py-1 text-xs font-semibold text-white bg-teal-500 rounded-md shadow-none hover:bg-teal-600 hover:shadow-md"
        type="submit"
      >
        Save
      </button>
    </EditPaymentForm.Wrapper>
  );
};
