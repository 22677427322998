import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteRateByIdMutationVariables = {
  id: Types.Scalars['String']
};


export type DeleteRateByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_rate_cards_by_pk: Types.Maybe<(
    { __typename?: 'rate_cards' }
    & Pick<Types.Rate_Cards, 'id'>
  )> }
);


export const DeleteRateByIdDocument = gql`
    mutation DeleteRateById($id: String!) {
  delete_rate_cards_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteRateByIdMutation() {
  return Urql.useMutation<DeleteRateByIdMutation, DeleteRateByIdMutationVariables>(DeleteRateByIdDocument);
};