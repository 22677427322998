import css from 'components/ModalBase/Modal.module.css';
import { ModalTopBar } from 'components/ModalBase/ModalTopBar/ModalTopBar';
import { Overlay } from 'components/Overlay/Overlay';
import { motion, useReducedMotion } from 'framer-motion';
import React, { FC } from 'react';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import { Calculator, DocumentReportOutline } from 'heroicons-react';
import classNames from 'classnames';
import { formatCurrency } from 'helpers/formatCurrency';
import { repayment } from 'helpers/loanRepaymentCalc';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import { NewLoanForm } from './newLoanForm';
import { Loans } from 'graphql/schemaTypes.generated';
import { useNewDraftLoanMutation } from './graphql/NewDraftLoan.generated';
import { useGetNextLoanRefQuery } from './graphql/GetNextLoanRef.generated';
import { useWrappedUrqlMutation } from 'toolbox';

interface NewLoanModal {
  closeHandler?: () => void;
}

const AmountInput = (props: any) => {
  const { values, setValues, setTouched } = NewLoanForm.useContext();
  return (
    <NewLoanForm.Inputs.amount
      type="number"
      labelText="Amount"
      hintText="Principal to borrow"
      placeholder="1000"
      labelPosition="top"
      prefix="£"
      clearButton
      clearButtonHandler={() => setValues({ ...values, amount: '' })}
      spellCheck={false}
      autoCapitalize="false"
      // autoFocus
      onKeyUp={() => setTouched({ amount: true, term_months: true }, true)}
      {...props}
    />
  );
};

const TermInput = (props: any) => {
  const { values, setValues, setTouched } = NewLoanForm.useContext();
  const term_years = (values?.term_months || 12) / 12;
  return (
    <NewLoanForm.Inputs.term_months
      type="number"
      labelText="Months"
      hintText="Term of the loan"
      placeholder="12"
      labelPosition="top"
      clearButton
      clearButtonHandler={() => setValues({ ...values, term_months: 12 })}
      spellCheck={false}
      autoCapitalize="false"
      suffix={
        !isNaN(term_years) &&
        `${term_years.toFixed(1).replace('.0', '')} yr${
          term_years > 1 ? 's' : ''
        }`
      }
      onKeyUp={() => setTouched({ amount: true, term_months: true }, true)}
      {...props}
    />
  );
};

const Quote = (props: any) => {
  const { user } = useLoggedInUser();
  const { values, dirty, isValid } = NewLoanForm.useContext();

  const getRate = () => {
    const a = values?.amount;
    const r = user?.selected_customer?.rate;
    if (a <= 3000) return r?.apr_lte_3000;
    if (a <= 5000 && a > 3000) return r?.apr_gt_3000_lte_5000;
    if (a <= 10000 && a > 5000) return r?.apr_gt_5000_lte_10000;
    if (a <= 15000 && a > 10000) return r?.apr_gt_10000_lte_15000;
    if (a <= 25000 && a > 15000) return r?.apr_gt_15000_lte_25000;
    if (a > 25000) return r?.apr_gt_25000;
  };

  const repaymentAmount = repayment(
    values?.amount,
    getRate(),
    values?.term_months || 12,
  );

  if (dirty && isValid)
    return (
      <>
        <div className="flex justify-center bg-white">
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: '16px solid transparent',
              borderRight: '16px solid transparent',
              borderTop: '16px solid #1B7BB3',
            }}
          />
        </div>
        <div className="flex justify-center w-64 pb-2 mx-auto -mt-2 bg-white rounded-b-lg sm:w-96">
          <section className="flex flex-col items-center w-full">
            <h2 className="flex items-center justify-center w-full pb-3 -mt-1 text-xl font-semibold text-gblue-700">
              <DocumentReportOutline
                path={undefined}
                className="inline w-6 h-6 mr-1"
              />
              Instant Quote
            </h2>
            <div className="flex items-center w-full">
              <div className="flex flex-col items-center justify-center px-3 py-1 font-semibold text-white rounded-lg shadow-inner md:py-4 md:px-6 bg-gblue-700">
                <p className="w-full pb-1 text-xs text-gblue-200">
                  Approximately
                </p>
                <p className="text-2xl leading-tight md:text-3xl">
                  {formatCurrency(repaymentAmount, 'GBP')}
                </p>
                <p className="w-full text-right text-gblue-100">per month</p>
              </div>
              <div className="flex flex-col justify-center flex-grow py-1 pl-3 leading-relaxed text-gray-600 text-2xs md:text-xs">
                <p>{formatCurrency(values?.amount, 'GBP')} principal</p>
                <p>{values?.term_months} repayments</p>
                <p>{getRate().toFixed(2)}% Interest rate</p>
                <p>Fees may apply</p>
              </div>
            </div>
            <SubmitButton className="w-full p-2 mt-3 text-base font-semibold text-white bg-teal-400 rounded-md shadow">
              Apply for loan
            </SubmitButton>
            <p className="flex-shrink mt-3 text-xs text-center text-gray-500">
              An email with further instructions will be sent to {user?.email}
            </p>
          </section>
        </div>
      </>
    );
  return (
    <div className="rounded-b-lg bg-gblue-700">
      <section className="-mt-4 text-center">
        <span className="text-sm cursor-default select-none text-gblue-200">
          Enter an Amount and Term to see an instant quote
        </span>
      </section>
    </div>
  );
};

export const NewLoanModal: FC<NewLoanModal> = ({ closeHandler }) => {
  const { user } = useLoggedInUser();
  const reducedMotion = useReducedMotion();

  const [loanRefQuery] = useGetNextLoanRefQuery();
  const nextLoanRef =
    Number(loanRefQuery?.data?.loans?.[0]?.reference || -1) + 1;

  const createLoan = useWrappedUrqlMutation(useNewDraftLoanMutation, {
    onSuccess: () => {
      console.log('success');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const save = async (formValues: Partial<Loans>) => {
    console.log(nextLoanRef);
    createLoan.dispatch({
      object: {
        customer_id: user?.selected_customer?.id,
        customer_name: user?.selected_customer?.name,
        email_address: user?.email,
        reference: nextLoanRef,
        amount: formValues?.amount,
        term_months: formValues.term_months,
        type: formValues?.type,
        is_approved: false,
      },
    });
    closeHandler?.();
  };

  return (
    <div className={css.Modal}>
      <Overlay>
        <ModalTopBar
          title="New Loan"
          buttons={
            <ButtonBase
              onClick={closeHandler}
              className="text-white bg-teal-400 btn"
            >
              Close
            </ButtonBase>
          }
        />
        <div className={css.CardPadding}>
          <motion.div
            className={classNames(css.Card)}
            initial={
              reducedMotion
                ? { opacity: 1, scale: 1 }
                : { opacity: 0, scale: 0 }
            }
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 200 }}
          >
            <NewLoanForm.Wrapper
              onSubmit={save}
              initialValues={{
                customer_id: user?.selected_customer?.id,
                customer_name: user?.selected_customer?.name,
                email_address: user?.email,
                amount: '',
                term_months: 12,
                type: 'personal',
              }}
            >
              <NewLoanForm.Consumer>
                {({ dirty, isValid }) => (
                  <section
                    className={classNames(
                      'bg-gradient-to-t from-gblue-700 to-gblue-500 grid gap-3 rounded-t-lg',
                      // dirty && isValid ? 'rounded-t-lg' : 'rounded-lg',
                    )}
                  >
                    <h2 className="flex items-center text-xl font-semibold text-gblue-100">
                      <Calculator
                        path={undefined}
                        className="inline w-6 h-6 mr-1"
                      />
                      Calculator
                    </h2>
                    <NewLoanForm.Inputs.customer_id
                      disabled
                      small
                      labelText="Customer"
                      labelPosition="left"
                    />
                    <div className="grid grid-flow-col gap-3">
                      <AmountInput />
                      <TermInput />
                    </div>

                    {/* <NewLoanForm.Inputs.type
                      labelText="Loan Type"
                      name="type"
                      options={[
                        {
                          value: 'personal',
                          labelText: 'Personal Loan',
                        },
                        {
                          value: 'hpa',
                          labelText: 'Hire Purchase Agreement',
                          hintText:
                            'HP loans are typically associated with the purchase of equipment or vehicles, and include an option-to-purchase fee on the final repayment',
                        },
                      ]}
                    /> */}
                  </section>
                )}
              </NewLoanForm.Consumer>
              <Quote />
            </NewLoanForm.Wrapper>
          </motion.div>
        </div>
      </Overlay>
    </div>
  );
};
