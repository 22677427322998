import { motion, useReducedMotion } from 'framer-motion';
import React, { FC } from 'react';

interface IOverlay {
  light?: boolean;
}

export const Overlay: FC<IOverlay> = ({ children, light = false }) => {
  const reducedMotion = useReducedMotion();

  return (
    <motion.div
      className={`absolute w-full h-screen overflow-y-auto overflow-x-hidden ${
        light
          ? 'bg-smoke-400 backdrop-blur-400'
          : 'bg-smoke-600 backdrop-blur-800'
      }`}
      // onClick={onCollapse}
      initial={reducedMotion ? { opacity: 1 } : { opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ willChange: 'opacity' }}
    >
      {children}
    </motion.div>
  );
};
