import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteUserFromGroupMutationVariables = {
  group_id?: Types.Maybe<Types.Scalars['String']>,
  user_id?: Types.Maybe<Types.Scalars['String']>
};


export type DeleteUserFromGroupMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_groups_by_pk: Types.Maybe<(
    { __typename?: 'user_groups' }
    & Pick<Types.User_Groups, 'group_id' | 'user_id'>
  )> }
);


export const DeleteUserFromGroupDocument = gql`
    mutation DeleteUserFromGroup($group_id: String = "", $user_id: String = "") {
  delete_user_groups_by_pk(group_id: $group_id, user_id: $user_id) {
    group_id
    user_id
  }
}
    `;

export function useDeleteUserFromGroupMutation() {
  return Urql.useMutation<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>(DeleteUserFromGroupDocument);
};