import React, { FC } from 'react';
import { ReactComponent as LoadingSpinnerSvg } from 'assets/LoadingSpinner.svg';

interface ILoadingSpinner {
  className?: string;
}

export const LoadingSpinner: FC<ILoadingSpinner> = ({
  children,
  className = 'w-8 h-8 text-gblue-700',
  ...props
}) => {
  return (
    <div className={className} {...props}>
      <LoadingSpinnerSvg className="w-full h-full animate-spin" />
    </div>
  );
};
