import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetBroadcastByIdQueryVariables = {
  id: Types.Scalars['uuid']
};


export type GetBroadcastByIdQuery = (
  { __typename?: 'query_root' }
  & { broadcast: Types.Maybe<(
    { __typename?: 'broadcasts' }
    & Pick<Types.Broadcasts, 'id' | 'activate_at' | 'disable_at' | 'recipient_group_id' | 'subject' | 'content' | 'draft'>
    & { group: (
      { __typename?: 'groups' }
      & Pick<Types.Groups, 'id'>
    ) }
  )> }
);


export const GetBroadcastByIdDocument = gql`
    query GetBroadcastById($id: uuid!) {
  broadcast: broadcasts_by_pk(id: $id) {
    id
    activate_at
    disable_at
    recipient_group_id
    group {
      id
    }
    subject
    content
    draft
  }
}
    `;

export function useGetBroadcastByIdQuery(options: Omit<Urql.UseQueryArgs<GetBroadcastByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetBroadcastByIdQuery>({ query: GetBroadcastByIdDocument, ...options });
};