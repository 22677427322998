import { Users } from 'graphql/schemaTypes.generated';

import { makeMutationForm } from 'components/makeMutationForm';

export const MyDetailsForm = makeMutationForm<Partial<Users>>({
  config: {
    cognito_sub: {
      type: 'text',
      label: 'cognito_sub',
    },
    email: {
      type: 'text',
      label: 'Email Address',
    },
    phone_number: {
      type: 'text',
      label: 'Phone Number',
    },
    name: {
      type: 'text',
      label: 'Full Name',
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    gender: {
      type: 'text',
      label: 'Gender',
    },
    maiden_name: {
      type: 'text',
      label: 'Maiden Name',
    },
    date_of_birth: {
      type: 'date',
      label: 'Date of Birth',
    },
    place_of_birth: {
      type: 'text',
      label: 'Place of Birth',
    },
    nationality: {
      type: 'text',
      label: 'Gender',
    },
    residential_status: {
      type: 'text',
      label: 'Residential Status',
    },
    marital_status: {
      type: 'text',
      label: 'Marital Status',
    },
    number_of_children: {
      type: 'number',
      label: 'Number of Children',
    },
    self_employed: {
      type: 'radioGroup',
      label: 'Self Employed',
    },
    current_employer: {
      type: 'text',
      label: 'Current Employer',
    },
    current_occupation: {
      type: 'text',
      label: 'Current Occupation/Role',
    },
    years_at_current_employer: {
      type: 'number',
      label: 'Years at Current Employer',
    },
    previous_employer: {
      type: 'text',
      label: 'Previous Employer',
    },
    previous_occupation: {
      type: 'text',
      label: 'Previous Occupation/Role',
    },
    years_at_previous_employer: {
      type: 'number',
      label: 'Years at Previous Employer',
    },
    notify_by_email: {
      type: 'checkbox',
      label: 'Email',
    },
    notify_by_sms: {
      type: 'checkbox',
      label: 'SMS',
    },
    notify_by_app: {
      type: 'checkbox',
      label: 'app',
    },
    notify_on_payment_received: {
      type: 'checkbox',
      label: '',
    },
  },
  showErrorsOnTouched: true,
  validate: ({
    cognito_sub,
    email,
    phone_number,
    name,
    title,
    gender,
    maiden_name,
    date_of_birth,
    place_of_birth,
    nationality,
    residential_status,
    marital_status,
    number_of_children,
    self_employed,
    current_employer,
    current_occupation,
    years_at_current_employer,
    previous_employer,
    previous_occupation,
    years_at_previous_employer,
    notify_by_email,
    notify_by_sms,
    notify_by_app,
    notify_on_payment_received,
  }) => {
    // if (notify_by_email && !email) {
    //   console.warn('form validation failed: email');
    //   return {
    //     email: 'Please specify an email if alert is notified by email',
    //   };
    // }
    if (phone_number && !phone_number?.match(/^\+[1-9]\d{1,14}$/)) {
      return {
        phone_number: `Phone number must be valid with international '+' prefix (e.164 format) to allow for SMS delivery`,
      };
    }
    if (notify_by_sms && !phone_number) {
      console.warn('form validation failed: phone number');
      return {
        phone_number:
          'Please specify a phone number if alert is notified by SMS',
      };
    }
  },
});
