import { Auth } from '@aws-amplify/auth';
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import React, { FC, useEffect } from 'react';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';

export const AutoLogout: FC = ({ children }) => {
  const { authStatus } = useLoggedInUser();

  const idleTimer = useIdleTimer({
    startOnMount: false,
    startManually: true,
    timeout:
      Number(
        process.env?.REACT_APP_IDEALTIMEOUT || 300 /* default = 5 minutes */,
      ) * 1000,
    eventsThrottle: 300,
    crossTab: true,
    // promptBeforeIdle: 10_000 /* 10 seconds before timeout */,
    onIdle: async () => {
      // user has been idle for 10 seconds, sign them out
      toast.warn('You have been signed out due to inactivity', {
        hideProgressBar: true,
        toastId: 'auto-logout',
      });
      await Auth.signOut(); // wait for signout or else the login page will redirect back to homescreen
      window.location.href = '/auth';
    },
    onActive: () => {
      // user has become active again, remove the warnings
      if (authStatus === 'loggedIn') toast.dismiss('auto-logout');
    },
    // onPrompt: () => {
    //   // user has been idle for 5 seconds, warn them
    //   toast.warn('You will be logged out in 5 seconds due to inactivity', {
    //     hideProgressBar: true,
    //     toastId: 'auto-logout',
    //   });
    // },
  });

  useEffect(() => {
    console.log(authStatus.toString());
    if (authStatus === 'loggedIn') {
      console.log('starting idle timer');
      idleTimer.start();
    }
    if (authStatus === 'loggedOut') {
      console.log('idle timer stopped');
      idleTimer.pause();
    }
  }, [authStatus]);

  return <>{children}</>;
};
