import dayjs from 'dayjs';
import React, { FC, useState, Suspense, useEffect } from 'react';
import { Link, NavLink, useLocation, Route, Switch } from 'react-router-dom';
import { EditMessageTemplate } from './EditMessageTemplatePage';
import {
  GetTemplatesQuery,
  useGetTemplatesQuery,
} from './graphql/GetTemplates.generated';
import { MailOutline, BellOutline } from 'heroicons-react';
import { useParamsAndSearch, useNavigate, routeMap } from 'routeMap';

export const ManageMessageTemplates: FC = () => {
  const location = useLocation();
  const [query, refresh] = useGetTemplatesQuery();

  const navigate = useNavigate();
  const { params } = useParamsAndSearch('adminMessageTemplateById');

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-grow bg-gblue-700">
        <div className="overflow-hidden border-l-8 border-r-8 rounded-tl-lg border-gblue-700 AdminScrollbars">
          <h3 className="mt-2 text-lg font-semibold text-white">
            Message Templates
          </h3>
          <div className="flex items-stretch justify-end py-2">
            <Link
              to="/admin/templates/new"
              className="flex items-center p-2 my-px ml-2 text-sm font-semibold text-teal-700 bg-teal-200 rounded"
            >
              <span className="truncate">New Template</span>
            </Link>
          </div>
          <div className="h-full pb-48 overflow-x-hidden overflow-y-scroll rounded h-200 AdminScrollbars">
            {query?.fetching ? (
              <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-gray-500">
                  Loading...
                </div>
              </li>
            ) : query?.error ? (
              <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-red-700">
                  Error: {query?.error?.message}
                </div>
              </li>
            ) : (
              query?.data?.message_templates.map(
                (template) =>
                  template?.id && (
                    <MessageRow key={template.id} template={template} />
                  ),
              )
            )}
          </div>
        </div>

        <div className="flex-grow w-2/3 h-screen mt-2 overflow-x-hidden overflow-y-scroll AdminScrollbars">
          <Switch>
            <Route
              path={routeMap.adminMessageTemplateById()}
              component={EditMessageTemplate}
            />
            <Route>
              <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
                Choose a template to edit
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </Suspense>
  );
};

interface IMessageRow {
  template: GetTemplatesQuery['message_templates'][number];
}

const MessageRow: FC<IMessageRow> = ({ template }) => {
  if (!template?.id) return null;
  return (
    <NavLink
      to={`/admin/templates/${template?.id}`}
      className="flex flex-col justify-center p-2 text-xs bg-teal-100"
      activeClassName="bg-teal-300"
      id={template?.id}
    >
      <div className="flex items-center justify-between flex-grow">
        <div className={`flex ${template.is_active ? '' : 'opacity-50'}`}>
          <div className={`mr-1`}>
            {template.template_type_id === 'promo_message' ? (
              <MailOutline
                path={undefined}
                className="inline w-4 h-4 text-teal-800"
              />
            ) : (
              <BellOutline
                path={undefined}
                className="inline w-4 h-4 text-teal-800"
              />
            )}
          </div>
          <div>
            <h3 className={`font-semibold text-teal-800 w-full `}>
              {template?.template_title}
            </h3>
            <div className="text-gray-600 text-2xs">
              {template.message_template_type.title}
              {' | '}
              {dayjs(template.created_at).format('DD MMM YYYY')}
            </div>
          </div>
        </div>
        {template.is_active ? (
          <div
            title="Active"
            className="w-2 h-2 bg-red-800 border-2 border-red-800 rounded-full"
          />
        ) : (
          <div
            title="Inctive"
            className="w-2 h-2 border-2 border-transparent rounded-full"
          />
        )}
      </div>
    </NavLink>
  );
};
