import React, { useContext, useEffect, createContext } from 'react';
import LoadingOverlay from 'components/Loading/LoadingOverlay';
import {
  useAuthWrapperContext,
  State,
} from 'toolbox/src/components/AuthWrapper/AuthWrapper';
import * as Sentry from '@sentry/react';
import Auth from '@aws-amplify/auth';
import { useGetNewMessageCountQuery } from './graphql/GetNewMessageCount.generated';

import {
  GetLoggedInUserQuery,
  useGetLoggedInUserQuery,
} from './graphql/GetLoggedInUserByUsername.generated';

type LoggedInUser = GetLoggedInUserQuery['user'];

type LoggedInUserContextType = {
  user?: LoggedInUser;
  authStatus: State['type'];
  newMessageCount?: number;
  refreshUserData: () => void;
};

const LoggedInUserContext = createContext<LoggedInUserContextType>({
  user: undefined,
  authStatus: 'pending',
  newMessageCount: undefined,
  refreshUserData: () => {},
});

interface ILoggedInUserProvider {
  // autoRefreshIdTokenInterval?: number;
}

export const LoggedInUserProvider: React.FC<ILoggedInUserProvider> = ({
  children,
  // autoRefreshIdTokenInterval = null,
}) => {
  const { username, status } = useAuthWrapperContext();

  const [result, refreshUserData] = useGetLoggedInUserQuery({
    variables: {
      cognito_sub: username as string,
    },
    pause: !username || status !== 'loggedIn',
    pollInterval: 300000, // retetch every 5 mins
  });

  const [newMessageCountResult] = useGetNewMessageCountQuery({
    variables: {
      userId: username!,
    },
    pause: !username,
    pollInterval: 3000,
  });

  useEffect(() => {
    if (result?.data?.user)
      Sentry.setContext('user', {
        cognito_sub: result.data.user?.cognito_sub,
        username: result.data.user?.email,
        name: result.data.user?.name,
      });
  }, [result]);

  return (
    <LoggedInUserContext.Provider
      value={{
        user: result.data?.user,
        authStatus: status,
        newMessageCount:
          newMessageCountResult.data?.unread?.aggregate?.count || 0,
        refreshUserData: () =>
          refreshUserData({
            requestPolicy: 'network-only',
          }),
      }}
    >
      {result?.fetching && <LoadingOverlay />}
      {children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUser = () => useContext(LoggedInUserContext);

export default LoggedInUserProvider;
