import classNames from 'classnames';
import LoadingDots from 'components/Loading/LoadingDots';
import { motion, MotionProps, useReducedMotion } from 'framer-motion';
import React, { FC } from 'react';

import css from './ButtonBase.module.css';

interface IButtonBase extends React.HTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button' | 'reset';
  loading?: boolean;
  disabled?: boolean;
}

export const ButtonBase: FC<IButtonBase & MotionProps> = ({
  className,
  type = 'button',
  loading = false,
  children,
  disabled = false,
  ...props
}) => {
  const reducedMotion = useReducedMotion();
  return (
    // @ts-ignore
    <motion.button
      type={type}
      className={classNames({ [css.ButtonBase]: true }, className)}
      whileTap={reducedMotion ? {} : { scale: 0.99 }}
      disabled={loading || disabled}
      // @ts-ignore
      {...props}
    >
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingDots className={`w-2 h-2 mb-px mr-2 bg-white`} />
        </div>
      ) : (
        children
      )}
    </motion.button>
  );
};
