import dayjs from 'dayjs';
import { User_Devices } from 'graphql/schemaTypes.generated';
import { DeviceMobileOutline, TrashOutline } from 'heroicons-react';
import React from 'react';
import { useWrappedUrqlMutation } from 'toolbox';
import { useDeleteDeviceMutation } from './graphql/DeleteDevice.generated';

export const ManageDevices = ({ devices }: { devices: User_Devices[] }) => {
  const deleteDeviceMutation = useWrappedUrqlMutation(useDeleteDeviceMutation, {
    onError: (e) => console.log(e),
  });

  const deleteDevice = async (uuid: string) => {
    const result = await deleteDeviceMutation.dispatch({ uuid });
  };

  return (
    <div className="col-span-6">
      <h2 className="flex justify-between col-span-6 text-xl font-normal mb-3">
        Devices that recieve app notifications
      </h2>
      {devices?.length < 1 ? (
        <p>
          No devices registered (user needs to sign in to app at least once)
        </p>
      ) : (
        <ul className="space-y-1 text-xs">
          {devices?.map((device) => (
            <li className="rounded bg-blue-100 flex" key={device.token}>
              <div className="flex items-center p-1 text-gray-500">
                <DeviceMobileOutline path={{}} className="w-5 h-5" />
              </div>
              <span className="py-2">
                {device.manufacturer} {device.model} running <b>{device.os}</b>{' '}
                {device.os_version}, last seen{' '}
                {dayjs(device.updated_at).format('DD/MM/YYYY')}
              </span>
              <div className="flex-grow" />
              <div
                className="text-white bg-red-500 flex-shrink-0 rounded p-1 flex items-center m-1 cursor-pointer"
                onClick={() => device?.uuid && deleteDevice(device.uuid)}
              >
                <TrashOutline path={{}} className="w-4 h-4" />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ManageDevices;
