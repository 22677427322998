import { makeMutationForm } from 'components/makeMutationForm';
import { Loans } from 'graphql/schemaTypes.generated';
import dayjs from 'dayjs';

export const NewLoanForm = makeMutationForm<Partial<Loans>>({
  config: {
    customer_id: { type: 'select' },
    reference: { type: 'number' },
    amount: { type: 'number' },
    contract_date: { type: 'date' },
    first_payment_date: { type: 'date' },
    apr: { type: 'number' },
    term_months: { type: 'number' },
    fees: { type: 'number' },
    type: { type: 'radioGroup' },
    custom_type_name: { type: 'text' },
    last_payment_fee: { type: 'number' },
  },
  showErrorsOnTouched: true,
  validate: ({
    customer_id,
    reference,
    amount,
    contract_date,
    first_payment_date,
    apr,
    term_months,
    type,
    custom_type_name,
    last_payment_fee,
  }) => {
    if (!customer_id) return { customer_id: 'Please select a customer' };
    if (!reference) return { reference: 'Please specify a loan reference' };
    if (!contract_date)
      return { contract_date: 'Please specify a contract date' };
    if (!first_payment_date)
      return {
        first_payment_date: 'Please specify a date for the first payment',
      };
    if (
      first_payment_date &&
      Number(dayjs(first_payment_date).format('D')) > 28
    )
      return {
        first_payment_date:
          'First payment date cannot be after the 28th of any month',
      };
    if (first_payment_date < contract_date)
      return {
        first_payment_date:
          'First payment date must be after the contract date',
      };
    if (!amount) return { amount: 'Please specify a loan amount' };
    if (amount <= 0) return { amount: 'Loan amount must be greater than zero' };
    if (!apr) return { apr: 'Please specify a loan APR' };
    if (!term_months) return { term_months: 'Please specify a loan term' };
    if (!type) return { type: 'Please specify a loan type' };
  },
});
