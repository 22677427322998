import React, { FC, useState } from 'react';
import { LoanDetailsModal } from 'components/LoanDetailsModal/LoanDetailsModal';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import { ModalToggleLink } from 'components/ModalBase/ModalToggleLink/ModalToggleLink';
import { NewLoanModal } from 'components/NewLoanModal/NewLoanModal';
import { useHistory } from 'react-router-dom';
import { LoanCardCompact } from '../LoanCardCompact/LoanCardCompact';
import { NewLoanCardCompact } from '../NewLoanCardCompact/NewLoanCardCompact';
import { MessageModal } from '../MessageModal/MessageModal';
import { MessageCard } from '../MessageCard/MessageCard';
import css from './MyLoansPage.module.css';
export const LoanList: FC = () => {
  const { user, newMessageCount } = useLoggedInUser();
  const history = useHistory();

  const [expandedCard, setExpandedCard] = useState<string | number | undefined>(
    undefined,
  );

  if (
    !user ||
    !user?.customers ||
    user?.customers?.length < 1 ||
    !user?.selected_customer ||
    user?.selected_customer?.loans?.length < 1
  )
    return null;

  return (
    <div className={css.MyLoansPage}>
      <ModalToggleLink
        path="/loans/new"
        exact
        modal={
          <NewLoanModal
            closeHandler={() => {
              setExpandedCard(undefined);
              history.push('/loans');
            }}
          />
        }
      >
        <NewLoanCardCompact
          disabled={expandedCard !== 'NEW_LOAN'}
          onClick={() => {
            setExpandedCard('NEW_LOAN');
            history.push('/loans/new');
          }}
        />
      </ModalToggleLink>

      {Boolean(newMessageCount) && (
        <ModalToggleLink
          path="/loans/messages"
          exact
          modal={
            <MessageModal
              closeHandler={() => {
                setExpandedCard(undefined);
                history.push('/loans');
              }}
            />
          }
        >
          <MessageCard
            disabled={expandedCard !== 'VIEW_MESSAGES'}
            newMessageCount={newMessageCount}
            onClick={() => {
              setExpandedCard('VIEW_MESSAGES');
              history.push('/loans/messages');
            }}
          />
        </ModalToggleLink>
      )}

      {user?.selected_customer?.loans?.map((loan: any) => {
        if (loan?.is_approved === false)
          return (
            <LoanCardCompact
              key={loan.id}
              loan={loan}
              disabled={true}
              onClick={() => null}
            />
          );

        return (
          <div key={loan.id}>
            <ModalToggleLink
              path={`/loans/GRF${loan?.reference.toString()}`}
              exact
              modal={
                <LoanDetailsModal
                  loan={loan}
                  closeHandler={() => {
                    setExpandedCard(undefined);
                    history.push('/loans');
                  }}
                />
              }
            >
              <LoanCardCompact
                loan={loan}
                disabled={
                  expandedCard !== undefined && expandedCard !== loan?.reference
                }
                onClick={() => {
                  // console.log(loan?.reference);
                  setExpandedCard(loan?.reference);
                  history.push(`/loans/GRF${loan?.reference.toString()}`);
                }}
              />
            </ModalToggleLink>
          </div>
        );
      })}
    </div>
  );
};
