import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import React from 'react';
import { AwsAuthComponents } from 'toolbox';
import Stack from 'toolbox/src/components/Stack/Stack';

import { PasswordOnlyForm } from './Forms';

export const RequireNewPassword: AwsAuthComponents['requireNewPassword'] = ({
  apiState,
  sendNewPassword,
  requiredAttributes,
}) => {
  return (
    <div className="w-full max-w-sm p-5 bg-white">
      <PasswordOnlyForm.Wrapper
        onSubmit={sendNewPassword}
        validate={({ password, confirmPassword, phone_number, email }) => {
          if (!password) {
            return {
              password: 'You must include a password.',
            };
          }
          if (!confirmPassword) {
            return {
              confirmPassword: 'You must complete both password fields.',
            };
          }
          if (password !== confirmPassword) {
            return {
              confirmPassword: 'The two passwords you provided do not match.',
            };
          }
          if (!phone_number && requiredAttributes.includes('phone_number')) {
            return {
              phone_number: 'You must include your phone number.',
            };
          }
          if (!email && requiredAttributes.includes('email')) {
            return {
              email: 'You must include your email.',
            };
          }
        }}
      >
        <Stack my={5} className="rounded-lg">
          <h2 className="text-xl font-semibold text-center text-gray-700">
            New Password Required
          </h2>
          <PasswordOnlyForm.Inputs.password autoFocus label="Password" />
          <PasswordOnlyForm.Inputs.confirmPassword
            label="Confirm Password"
            hint="Type your new password a second time to make sure you got it right."
          />
          {requiredAttributes.includes('email') && (
            <PasswordOnlyForm.Inputs.email label="Email" />
          )}
          {requiredAttributes.includes('phone_number') && (
            <PasswordOnlyForm.Inputs.phone_number label="Phone Number" />
          )}
          <ButtonBase
            type="submit"
            loading={apiState.type === 'loading'}
            className="justify-center text-xl text-white bg-gnavy-800"
          >
            Submit
          </ButtonBase>
          {apiState.type === 'error' && (
            <div className="font-semibold text-center">
              <p className="text-red-600">{apiState.errorMessage}</p>
            </div>
          )}
        </Stack>
      </PasswordOnlyForm.Wrapper>
    </div>
  );
};
