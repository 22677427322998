const round = (val: number) => Math.round((val + Number.EPSILON) * 100) / 100;

type Payment = {
  reconciled: boolean;
  payment_due_date: string;
  split_interest: number;
  split_principal?: number;
  split_fee?: number;
};

export const calcEarlySettlementFee = (
  payments: Payment[],
  numMonthsInterestPenalty: number,
  lastPaymentFee = 0,
) => {
  const remainingPayments = payments
    .filter((p) => p.reconciled === false)
    .sort((a, b) => (a.payment_due_date > b.payment_due_date ? 1 : -1));

  const completed_payments = payments.filter(
    (p) => p.reconciled === true,
  ).length;

  const interestPenalty = remainingPayments
    .slice(0, numMonthsInterestPenalty)
    .reduce((sum, p) => (sum += p.split_interest), 0);

  const remainingPrincipal = remainingPayments.reduce(
    (sum, p) => (sum += p?.split_principal ?? 0),
    0,
  );

  const docFee = completed_payments < 1 ? payments[0]?.split_fee ?? 0 : 0;

  return {
    total: round(
      remainingPrincipal + interestPenalty + lastPaymentFee + docFee,
    ),
    fee: docFee,
    principal: remainingPrincipal,
    interest: interestPenalty,
  };
};
