import React from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

interface SubmitButtonProps {
  className?: string;
}

// Note, only works with Formik
export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  className,
}) => {
  const { isSubmitting, dirty } = useFormikContext();
  const disabled = !dirty || isSubmitting;
  return (
    <button
      type="submit"
      // onClick={() => submitForm()}
      disabled={disabled}
      className={classNames(className, disabled ? 'opacity-50' : '')}
    >
      {children}
    </button>
  );
};
