import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteGroupByIdMutationVariables = {
  id: Types.Scalars['String']
};


export type DeleteGroupByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_groups_by_pk: Types.Maybe<(
    { __typename?: 'groups' }
    & Pick<Types.Groups, 'id'>
  )> }
);


export const DeleteGroupByIdDocument = gql`
    mutation DeleteGroupById($id: String!) {
  delete_groups_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteGroupByIdMutation() {
  return Urql.useMutation<DeleteGroupByIdMutation, DeleteGroupByIdMutationVariables>(DeleteGroupByIdDocument);
};