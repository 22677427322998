import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteQuoteMutationVariables = {
  id: Types.Scalars['uuid']
};


export type DeleteQuoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_loans_by_pk: Types.Maybe<(
    { __typename?: 'loans' }
    & Pick<Types.Loans, 'id'>
  )> }
);


export const DeleteQuoteDocument = gql`
    mutation DeleteQuote($id: uuid!) {
  delete_loans_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteQuoteMutation() {
  return Urql.useMutation<DeleteQuoteMutation, DeleteQuoteMutationVariables>(DeleteQuoteDocument);
};