import React from 'react';

import { lazyWithRetry } from './utils/lazyWithRetry';
export { lazyWithRetry } from './utils/lazyWithRetry';

/**
 * We use lazy imports to stop bundle size creep,
 * this will mean our bundle sizes stay super-small by default
 */
export const ButtonBase = lazyWithRetry(
  () => import('./components/ButtonBase/ButtonBase'),
);

export { declareMakeMutationForm } from './utils/declareMakeMutationForm';

export const Flex = lazyWithRetry(() => import('./components/Flex/Flex'));
export const RTLAwareFlex = lazyWithRetry(
  () => import('./components/Flex/RTLAwareFlex'),
);

export const TextInput = lazyWithRetry(
  () => import('./components/TextInput/TextInput'),
);

export const UrqlWrapper = lazyWithRetry(
  () => import('./components/UrqlWrapper/UrqlWrapper'),
);

export const HeroIconAnnouncement = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconAnnouncement'),
);
export const HeroIconYourAgency = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconYourAgency'),
);
export const HeroIconUSAFlag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUSAFlag'),
);
export const HeroIconAEDFlag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconAEDFlag'),
);
export const HeroIconEUFlag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconEUFlag'),
);

export const HeroIconArchive = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconArchive'),
);

export const HeroIconArrowDown = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconArrowDown'),
);

export const HeroIconArrowLeft = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconArrowLeft'),
);

export const HeroIconArrowRight = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconArrowRight'),
);

export const HeroIconArrowUp = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconArrowUp'),
);

export const HeroIconAtSymbol = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconAtSymbol'),
);
export const HeroIconUsers = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUsers'),
);

export const HeroIconBook = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconBook'),
);

export const HeroIconBookmark = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconBookmark'),
);

export const HeroIconBriefcase = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconBriefcase'),
);

export const HeroIconBrowser = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconBrowser'),
);

export const HeroIconBuilding = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconBuilding'),
);

export const HeroIconCalander = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCalander'),
);

export const HeroIconCallIncoming = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCallIncoming'),
);

export const HeroIconCallOutgoing = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCallOutgoing'),
);

export const HeroIconCall = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCall'),
);

export const HeroIconCamera = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCamera'),
);

export const HeroIconCart = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCart'),
);

export const HeroIconChat = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconChat'),
);

export const HeroIconCheck = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheck'),
);

export const HeroIconCheckCircle = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheckCircle'),
);

export const HeroIconCheveronDown = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheveronDown'),
);

export const HeroIconCheveronLeft = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheveronLeft'),
);

export const HeroIconCheveronRight = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheveronRight'),
);

export const HeroIconCheveronUp = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCheveronUp'),
);

export const HeroIconClip = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconClip'),
);

export const HeroIconClipboard = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconClipboard'),
);

export const HeroIconClock = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconClock'),
);

export const HeroIconCode = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCode'),
);

export const HeroIconCog = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCog'),
);

export const HeroIconComment = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconComment'),
);

export const HeroIconCompass = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCompass'),
);

export const HeroIconCurrencyDollar = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconCurrencyDollar'),
);

export const HeroIconDashboard = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconDashboard'),
);

export const HeroIconDesktop = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconDesktop'),
);

export const HeroIconDiscount = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconDiscount'),
);

export const HeroIconDownload = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconDownload'),
);

export const HeroIconDuplicate = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconDuplicate'),
);

export const HeroIconEdit = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconEdit'),
);

export const HeroIconEmotionHappy = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconEmotionHappy'),
);

export const HeroIconEmotionSad = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconEmotionSad'),
);

export const HeroIconExclamation = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconExclamation'),
);

export const HeroIconExternalLink = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconExternalLink'),
);

export const HeroIconFileBlank = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFileBlank'),
);

export const HeroIconFileMinus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFileMinus'),
);

export const HeroIconFilePlus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFilePlus'),
);

export const HeroIconFile = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFile'),
);

export const HeroIconFilm = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFilm'),
);

export const HeroIconFilter = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFilter'),
);

export const HeroIconFlag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFlag'),
);

export const HeroIconFolderMinus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFolderMinus'),
);

export const HeroIconFolderPlus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFolderPlus'),
);

export const HeroIconFolder = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconFolder'),
);

export const HeroIconGlobe = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconGlobe'),
);

export const HeroIconGraphBar = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconGraphBar'),
);

export const HeroIconGrid = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconGrid'),
);

export const HeroIconGroup = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconGroup'),
);

export const HeroIconHashtag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconHashtag'),
);

export const HeroIconHeart = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconHeart'),
);

export const HeroIconHelp = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconHelp'),
);

export const HeroIconHome = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconHome'),
);

export const HeroIconImage = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconImage'),
);

export const HeroIconInbox = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconInbox'),
);

export const HeroIconInformation = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconInformation'),
);

export const HeroIconKey = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconKey'),
);

export const HeroIconLink = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconLink'),
);

export const HeroIconLocation = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconLocation'),
);

export const HeroIconLockClosed = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconLockClosed'),
);

export const HeroIconLockOpen = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconLockOpen'),
);

export const HeroIconMail = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMail'),
);

export const HeroIconMap = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMap'),
);

export const HeroIconMenu = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMenu'),
);

export const HeroIconMicrophone = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMicrophone'),
);

export const HeroIconMinusCircle = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMinusCircle'),
);

export const HeroIconMinusSquare = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMinusSquare'),
);

export const HeroIconMinus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMinus'),
);

export const HeroIconMobile = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMobile'),
);

export const HeroIconMoon = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMoon'),
);

export const HeroIconMoreHoriz = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMoreHoriz'),
);

export const HeroIconMusic = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconMusic'),
);

export const HeroIconNews = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconNews'),
);

export const HeroIconNotification = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconNotification'),
);

export const HeroIconPlusCircle = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconPlusCircle'),
);

export const HeroIconPlusSquare = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconPlusSquare'),
);

export const HeroIconPlus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconPlus'),
);

export const HeroIconPrint = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconPrint'),
);

export const HeroIconPuzzle = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconPuzzle'),
);

export const HeroIconRefresh = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconRefresh'),
);

export const HeroIconRepeat = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconRepeat'),
);

export const HeroIconRocket = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconRocket'),
);

export const HeroIconSearch = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconSearch'),
);

export const HeroIconServer = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconServer'),
);

export const HeroIconSpeaker = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconSpeaker'),
);

export const HeroIconStar = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconStar'),
);

export const HeroIconStore = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconStore'),
);

export const HeroIconTablet = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTablet'),
);

export const HeroIconTag = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTag'),
);

export const HeroIconThumbDown = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconThumbDown'),
);

export const HeroIconThumbUp = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconThumbUp'),
);

export const HeroIconTrash = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTrash'),
);

export const HeroIconTrendingDown = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTrendingDown'),
);

export const HeroIconTrendingUp = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTrendingUp'),
);

export const HeroIconTrophy = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconTrophy'),
);

export const HeroIconUpload = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUpload'),
);

export const HeroIconUserCheck = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUserCheck'),
);

export const HeroIconUserMinus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUserMinus'),
);

export const HeroIconUserPlus = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUserPlus'),
);

export const HeroIconUser = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconUser'),
);

export const HeroIconVideo = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconVideo'),
);

export const HeroIconView = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconView'),
);

export const HeroIconXCircle = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconXCircle'),
);

export const HeroIconXSquare = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconXSquare'),
);

export const HeroIconX = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconX'),
);

export const HeroIconZoomIn = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconZoomIn'),
);

export const HeroIconZoomOut = lazyWithRetry(
  () => import('./components/HeroIcons/HeroIconZoomOut'),
);

export const AnimatedButtonBase = lazyWithRetry(
  () => import('./components/AnimatedButtonBase/AnimatedButtonBase'),
);

export const SankeyDiagram = lazyWithRetry(
  () => import('./components/DataVisualisation/SankeyDiagram'),
);

export const Modal = lazyWithRetry(() => import('./components/Modal/Modal'));

export {
  useThrottleUserInput as useThrottle,
  useDeduplicationThrottle,
} from './hooks/useThrottle';

export { usePagination } from './hooks/usePagination';

export const ToastContainer = lazyWithRetry(
  () => import('./components/ToastContainer/ToastContainer'),
);

export { useToast } from './components/ToastContainer/ToastContainer';

export const ProtectedRoute = lazyWithRetry(
  () => import('./components/Auth/ProtectedRoute/ProtectedRoute'),
);

export {
  useWrappedUrqlMutation,
  makeUseWrappedDocumentNodeMutation,
} from './hooks/useWrappedUrqlMutation';

export const TableRow = lazyWithRetry(
  () => import('./components/TableRow/TableRow'),
);

export const Alert = lazyWithRetry(() => import('./components/Alert/Alert'));

export const UnderlinedTabs = lazyWithRetry(
  () => import('./components/UnderlinedTabs/UnderlinedTabs'),
);

export const ServiceWorkerProvider = lazyWithRetry(
  () => import('./components/ServiceWorkerProvider/ServiceWorkerProvider'),
);

export { useServiceWorker } from './components/ServiceWorkerProvider/ServiceWorkerProvider';

export const AwsAuthenticator = lazyWithRetry(
  () => import('./components/AwsAuthenticator/AwsAuthenticator'),
);

export const Checkbox = lazyWithRetry(
  () => import('./components/Checkbox/Checkbox'),
);

export const RadioButton = lazyWithRetry(
  () => import('./components/RadioButton/RadioButton'),
);

export const RadioGroup = lazyWithRetry(
  () => import('./components/RadioButton/RadioGroup'),
);

export type { AwsAuthComponents } from './components/AwsAuthenticator/awsAuthenticatorTypes';

export { useAmplifyQuery } from './hooks/useAmplifyQuery';

export { useCheckForActivity } from './hooks/useCheckForActivity';

export { useSessionTimeout } from './hooks/useSessionTimeout';

export const AwsSetupTotpWrapper = lazyWithRetry(
  () => import('./components/AwsSetupTotpWrapper/AwsSetupTotpWrapper'),
);

export { useAwsChangePassword } from './hooks/useAwsChangePassword';

export { usePaginatedAmplifyQuery } from './hooks/usePaginatedAmplifyQuery';

export { isEmailAddress } from './utils/isEmailAddress';

export { useConfirm } from './hooks/useConfirm';

export { useModal } from './hooks/useModal';

export { useSearchParamsModal } from './hooks/useSearchParamsModal';

export const SelectBase = lazyWithRetry(
  () => import('./components/SelectBase/SelectBase'),
);

export { Pagination } from './components/Pagination/Pagination';

export { useCopyToClipboard } from './hooks/useCopyToClipboard';

export { addComponentSeparator } from './utils/addComponentSeparator';

export { useOnClickOutside } from './hooks/useOnClickOutside';

export const Stack = lazyWithRetry(() => import('./components/Stack/Stack'));

export { useInterval } from './hooks/useInterval';

export { useKeepScrolledToBottom } from './hooks/useKeepScrolledToBottom';

export const AsyncSearchSelect = lazyWithRetry(
  () => import('./components/AsyncSearchSelect/AsyncSearchSelect'),
);

export { useAsyncSearchSelect } from './hooks/useAsyncSearchSelect';

export { validateMutationForm } from './utils/validateMutationForm';

export { useTextInput } from './hooks/useTextInput';
export {
  useSearchParamsTextInput,
  useSearchParamsSelectInput,
} from './hooks/useSearchParamsTextInput';
export { useSearchParams } from './hooks/useSearchParams';
export { makeUseNavigate, makeRouteMap } from './utils/makeUseNavigate';

export const PreventLeavingFormIfTouched = lazyWithRetry(
  () =>
    import(
      './components/PreventLeavingFormIfTouched/PreventLeavingFormIfTouched'
    ),
);

export { useNprogressOnInitialLoad } from './hooks/useNprogressOnInitialLoad';

export { useFetchConversionRates } from './hooks/useFetchConversionRates';

export const PopoverMenu = lazyWithRetry(
  () => import('./components/PopoverMenu/PopoverMenu'),
);

export const Appear = lazyWithRetry(() => import('./components/Appear/Appear'));

export const AuthWrapper = lazyWithRetry(
  () => import('./components/AuthWrapper/AuthWrapper'),
);

export { useSortedTable } from './hooks/useSortedTable';

export { useValueDidUpdate } from './hooks/useValueDidUpdate';

export const ScrollToTop = lazyWithRetry(
  () => import('./components/ScrollToTop/ScrollToTop'),
);

export { useMutationForm } from './hooks/useMutationForm';

export { useLegacyState } from './hooks/useLegacyState';

export { formatCurrency } from './utils/formatCurrency';

export { extractOnlySomeAttributes } from './utils/extractOnlySomeAttributes';

export {
  addFileToStorage,
  getS3Url,
  usePublicS3Url,
} from './utils/addToStorage';

export const CanGoToNextPage = lazyWithRetry(
  () => import('./components/CanGoToNextPage/CanGoToNextPage'),
);

export { useAsyncLoadScript } from './hooks/useAsyncLoadScript';

export { useSearchParamsState } from './hooks/useSearchParamsState';

export { useDataMap, makeMapFromArray } from './hooks/useDataMap';

export const AnimateInHeight = lazyWithRetry(
  () => import('./components/AnimateInHeight/AnimateInHeight'),
);

export { useDataStatuses } from './hooks/useDataStatuses';

export { usePreventCallsWhilePending } from './hooks/usePreventCallsWhilePending';

export { downloadAsCsv } from './utils/downloadAsCsv';

export { useFsmReducer } from './hooks/useFsmReducer';
