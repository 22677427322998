import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetLoansQueryVariables = {};


export type GetLoansQuery = (
  { __typename?: 'query_root' }
  & { loans: Array<(
    { __typename?: 'loans' }
    & Pick<Types.Loans, 'id' | 'reference' | 'email_address' | 'customer_name' | 'amount' | 'contract_date' | 'is_approved'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Types.Customers, 'name'>
    ) }
  )> }
);


export const GetLoansDocument = gql`
    query GetLoans {
  loans(where: {is_declined: {_eq: false}}, order_by: {reference: asc}) {
    id
    reference
    email_address
    customer_name
    customer {
      name
    }
    amount
    contract_date
    is_approved
  }
}
    `;

export function useGetLoansQuery(options: Omit<Urql.UseQueryArgs<GetLoansQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetLoansQuery>({ query: GetLoansDocument, ...options });
};