import './LoadingDots.css';

import React from 'react';

interface LoadingDotsProps {
  //   size?: 'px' | 1 | 2 | 3 | 4 | 5 | 6 | 8 | 10 | 12;
  className?: string;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ className }) => (
  <span className="LoadingDots">
    <span className={`bounce1 ${className}`} />
    <span className={`bounce2 ${className}`} />
    <span className={`bounce3 ${className}`} />
  </span>
);

export default LoadingDots;
