import React from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

interface ResetButtonProps {
  className?: string;
  onClick?: () => any | void;
}

// Note, only works with Formik
export const ResetButton: React.FC<ResetButtonProps> = ({
  children,
  className,
  onClick,
}) => {
  const { resetForm, isSubmitting, dirty } = useFormikContext();
  const disabled = !dirty || isSubmitting;
  return (
    <button
      type="reset"
      onClick={() => {
        resetForm();
        onClick && onClick();
      }}
      disabled={disabled}
      className={classNames(className, disabled ? 'opacity-50' : '')}
    >
      {children}
    </button>
  );
};
