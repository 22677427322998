import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteAddressMutationVariables = {
  id: Types.Scalars['uuid']
};


export type DeleteAddressMutation = (
  { __typename?: 'mutation_root' }
  & { delete_addresses_by_pk: Types.Maybe<(
    { __typename?: 'addresses' }
    & Pick<Types.Addresses, 'id'>
  )> }
);


export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: uuid!) {
  delete_addresses_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteAddressMutation() {
  return Urql.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument);
};