import React, { FC, useEffect, useState } from 'react';
import { CogOutline } from 'heroicons-react';
import { InputWrapper } from 'components/Inputs/InputWrapper';
import { ButtonBase, useWrappedUrqlMutation } from 'toolbox';
import { useGetSettingsQuery } from './graphql/GetSettings.generated';
import { useUpsertSettingsMutation } from './graphql/UpsertSettings.generated';
import { toast } from 'react-toastify';

export const ManageSettings: FC = () => {
  const [{ data }, refetch] = useGetSettingsQuery();

  const loan_complete_message_template = data?.settings?.find(
    (s) => s.id == 'loan_complete_message_template',
  );
  const payment_received_message_template = data?.settings?.find(
    (s) => s.id == 'payment_received_message_template',
  );

  const [formData, setFormData] = useState({
    loan_complete_message_template: '',
    payment_received_message_template: '',
  });

  useEffect(() => {
    if (data) {
      setFormData({
        loan_complete_message_template:
          loan_complete_message_template?.value || '',
        payment_received_message_template:
          payment_received_message_template?.value || '',
      });
    }
  }, [data]);

  const saveMutation = useWrappedUrqlMutation(useUpsertSettingsMutation, {
    onSuccess: () => toast.success('Saved'),
  });
  const save = async () => {
    await saveMutation.dispatch({
      objects: [
        {
          id: 'payment_received_message_template',
          description: payment_received_message_template?.description,
          value: formData.payment_received_message_template,
        },
        {
          id: 'loan_complete_message_template',
          description: loan_complete_message_template?.description,
          value: formData.loan_complete_message_template,
        },
      ],
    });
  };

  return (
    <div className="flex flex-col flex-grow overflow-hidden bg-gblue-700 rounded-tl-md">
      <div className="flex self-stretch justify-start">
        <div className="w-2/3 flex-grow h-screen overflow-y-scroll overflow-x-hidden AdminScrollbars mt-2 ml-2">
          <section className="self-stretch">
            <h3 className="p-3 pb-3 bg-white rounded-t flex space-x-2 font-semibold">
              <CogOutline path={undefined} />
              <span>Settings</span>
            </h3>

            <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
              <div className="flex justify-end p-2">
                <button
                  className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-500 disabled:opacity-50"
                  onClick={() => save()}
                  disabled={
                    formData.loan_complete_message_template ==
                      loan_complete_message_template?.value &&
                    formData.payment_received_message_template ==
                      payment_received_message_template?.value
                  }
                >
                  Save
                </button>
              </div>
            </div>

            <div className="flex flex-col space-y-3 p-3 pb-10 bg-white rounded-b">
              <h3 className="text-sm font-semibold">Notification Templates</h3>

              <ul>
                <li>
                  <code>{`{{user_name}}`}</code> The recipient's name
                </li>
                <li>
                  <code>{`{{payment_amount}}`}</code> The amount received
                  (number, no currency prefix)
                </li>
                <li>
                  <code>{`{{loan_reference}}`}</code> The loan reference (not
                  including GRF prefix)
                </li>
              </ul>

              <InputWrapper labelText="Payment Recieved">
                <textarea
                  className="text-base p-2 text-black disabled:bg-gray-100 disabled:text-gray-600"
                  rows={12}
                  value={formData.payment_received_message_template}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      payment_received_message_template: e.target.value,
                    });
                  }}
                />
              </InputWrapper>

              <ul>
                <li>
                  <code>{`{{user_name}}`}</code> The recipient's name
                </li>
                <li>
                  <code>{`{{customer_name}}`}</code> The name of the customer
                  account
                </li>
                <li>
                  <code>{`{{loan_reference}}`}</code> The loan reference (not
                  including GRF prefix)
                </li>
                <li>
                  <code>{`{{loan_amount}}`}</code> The principal amount borrowed
                  (number, no currency prefix)
                </li>
              </ul>

              <InputWrapper labelText="Loan Complete">
                <textarea
                  className="text-base p-2 text-black disabled:bg-gray-100 disabled:text-gray-600"
                  rows={12}
                  value={formData.loan_complete_message_template}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      loan_complete_message_template: e.target.value,
                    });
                  }}
                />
              </InputWrapper>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
