import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import React from 'react';
import { AwsAuthComponents } from 'toolbox';
import Stack from 'toolbox/src/components/Stack/Stack';
import { IdentificationOutline, KeyOutline } from 'heroicons-react';
import { ReactComponent as GouldsonLogo } from 'assets/GouldsonLogo.svg';

import { SignInForm } from './Forms';

export const SignIn: AwsAuthComponents['signIn'] = ({
  signIn,
  apiState,
  goToForgottenPassword,
}) => {
  const loading = apiState.type === 'loading';
  return (
    <SignInForm.Wrapper
      onSubmit={(values) =>
        signIn({
          ...values,
          username: `${values.username}`.toLowerCase(),
        })
      }
      className="flex flex-col items-center justify-center w-full"
    >
      <GouldsonLogo className="h-16 mb-5" />
      <Stack className="flex flex-col justify-center w-full max-w-sm p-5 pb-1 bg-white rounded-lg">
        <SignInForm.Inputs.username
          autoFocus
          label="Username"
          type="email"
          autoComplete="username"
          icon={<IdentificationOutline path={undefined} />}
          disabled={loading}
          placeholder="name@email.com"
        />
        <SignInForm.Inputs.password
          label="Password"
          type="password"
          autoComplete="current-password"
          icon={<KeyOutline path={undefined} />}
          disabled={loading}
        />
        <button
          type="button"
          className="ForgotPasswordButton"
          disabled={loading}
          onClick={goToForgottenPassword}
        >
          Forgot Password?
        </button>
        <ButtonBase
          type="submit"
          loading={loading}
          className="justify-center text-2xl text-white bg-gnavy-800"
        >
          Sign In
        </ButtonBase>
        <div className="font-semibold text-center">
          {apiState.type === 'error' && (
            <p className="text-red-600">{apiState.errorMessage}</p>
          )}
        </div>
      </Stack>
    </SignInForm.Wrapper>
  );
};
