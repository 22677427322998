import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type RejectLoanByIdMutationVariables = {
  id: Types.Scalars['uuid']
};


export type RejectLoanByIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_loans_by_pk: Types.Maybe<(
    { __typename?: 'loans' }
    & Pick<Types.Loans, 'id'>
  )> }
);


export const RejectLoanByIdDocument = gql`
    mutation RejectLoanById($id: uuid!) {
  update_loans_by_pk(_set: {is_declined: true}, pk_columns: {id: $id}) {
    id
  }
}
    `;

export function useRejectLoanByIdMutation() {
  return Urql.useMutation<RejectLoanByIdMutation, RejectLoanByIdMutationVariables>(RejectLoanByIdDocument);
};