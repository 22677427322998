import React, { FC, useState, Suspense } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { EditRatePage } from './EditRatePage';
import { GetRatesQuery, useGetRatesQuery } from './graphql/GetRates.generated';

export const ManageRates: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query, refresh] = useGetRatesQuery();
  const [expandedRate, setExpandedRate] = useState(
    location.pathname.split('/admin/rates/')?.[1] || null,
  );

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-col flex-grow overflow-hidden bg-gblue-700 rounded-tl-md">
        <div className="flex self-stretch justify-start">
          <div className="relative flex-grow pb-2 overflow-x-hidden overflow-y-scroll border-l-8 border-r-8 AdminScrollbars border-gblue-700 w-32">
            <h3 className="mt-2 text-lg font-semibold text-white">
              Rate Cards
            </h3>
            <div className="flex items-stretch justify-end py-2">
              <Link
                to="/admin/rates/new"
                className="flex items-center p-2 my-px ml-2 text-sm font-semibold text-teal-700 bg-teal-200 rounded"
                onClick={() => setExpandedRate('new')}
              >
                <span className="truncate">New Rate</span>
              </Link>
            </div>
            <div className="overflow-hidden rounded">
              {query?.fetching ? (
                <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-gray-500">
                    Loading...
                  </div>
                </li>
              ) : query?.error ? (
                <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-red-700">
                    Error: {query?.error?.message}
                  </div>
                </li>
              ) : (
                query?.data?.rate_cards
                  .sort((a, b) => (a?.id > b?.id ? 1 : -1))
                  .map(
                    (rate_card) =>
                      rate_card?.id && (
                        <RateCardRow
                          key={rate_card.id}
                          rateCard={rate_card}
                          expanded={expandedRate === rate_card.id}
                          openHandler={() => {
                            setExpandedRate(rate_card.id);
                          }}
                          closeHandler={() => {
                            setExpandedRate(null);
                            history.push('/admin/rates');
                            refresh();
                          }}
                        />
                      ),
                  )
              )}
            </div>
          </div>

          <div className="w-2/3 flex-grow h-screen overflow-y-scroll overflow-x-hidden AdminScrollbars mt-2">
            {expandedRate ? (
              <EditRatePage
                rateId={expandedRate}
                closeHandler={() => setExpandedRate(null)}
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
                Choose a rate to edit
              </div>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

interface IRateCardRow {
  rateCard: GetRatesQuery['rate_cards'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}
const RateCardRow: FC<IRateCardRow> = ({
  rateCard,
  expanded = false,
  openHandler,
  closeHandler,
}) => {
  if (!rateCard?.id) return null;
  return (
    <NavLink
      to={`/admin/rates/${rateCard?.id}`}
      className="flex flex-col justify-center p-2 text-xs bg-teal-100"
      activeClassName="bg-teal-300"
      id={rateCard?.id}
      onClick={expanded ? closeHandler : openHandler}
    >
      <h3
        className={`font-semibold text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        }`}
      >
        Rate {rateCard?.id.toString()}
      </h3>
    </NavLink>
  );
};
