import React, { FC, useState } from 'react';
import { GetLoansQuery } from './graphql/GetLoans.generated';
import { useGetLoanDetailsQuery } from './graphql/GetLoanDetails.generated';
import { useDeleteLoanByIdMutation } from './graphql/DeleteLoanById.generated';
import { useDeletePaymentByIdMutation } from './graphql/DeletePaymentById.generated';
import { useApproveLoanByIdMutation } from './graphql/ApproveLoanById.generated';
import { useRejectLoanByIdMutation } from './graphql/RejectLoanById.generated';
import { useUpsertLoanMutation } from './graphql/UpsertLoan.generated';
import {
  HeroIconCheckCircle,
  HeroIconTrash,
  useWrappedUrqlMutation,
  HeroIconRefresh,
} from 'toolbox';
import { toast } from 'react-toastify';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { formatCurrency } from 'helpers/formatCurrency';
import { useUpsertPaymentMutation } from './graphql/UpsertPayment.generated';
import { EditPaymentRow } from './EditPayment';
import { Loans } from 'graphql/schemaTypes.generated';
import { makeMutationForm } from 'components/makeMutationForm';
import dayjs from 'dayjs';
import { calcEarlySettlementFee } from 'helpers/earlySettlement';

interface IEditLoanPage {
  loanId?: GetLoansQuery['loans'][number]['id'] | null;
  closeHandler: () => any;
}

const EditLoanForm = makeMutationForm<Partial<Loans>>({
  config: {
    type: { type: 'select', label: 'Loan Type' },
    custom_type_name: { type: 'text' },
    contract_date: { type: 'date' },
    first_payment_date: {
      type: 'date',
    },
    amount: {
      type: 'text',
    },
    term_months: {
      type: 'text',
    },
    apr: {
      type: 'text',
    },
    fees: {
      type: 'text',
    },
    customer_name: {
      type: 'text',
    },
    last_payment_fee: {
      type: 'number',
    },
    early_settlement_penalty_months_interest: {
      type: 'number',
    },
  },
  showErrorsOnTouched: true,
  validate: ({ type, custom_type_name, contract_date }) => {
    if (!contract_date)
      return { contract_date: 'Loan must have a contract date' };
    if (!type) return { type: 'Loan must have a type' };
    if (type === 'bespoke' && !custom_type_name)
      return { custom_type_name: 'Custom loans must have a description' };
  },
});

export const EditLoanPage: FC<IEditLoanPage> = ({ loanId, closeHandler }) => {
  const [query, refresh] = useGetLoanDetailsQuery({
    variables: { loanId: loanId },
    // pollInterval: 10000,
  });

  const [activePaymentId, setActivePaymentId] = useState<string>('');
  const [isAddingNewPayment, setIsAddingNewPayment] = useState<boolean>(false);

  const toggleAddingNewPayment = () =>
    setIsAddingNewPayment(!isAddingNewPayment);

  const loan = query?.data?.loans_by_pk;

  const deleteLoan = useWrappedUrqlMutation(useDeleteLoanByIdMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  const deletePayment = useWrappedUrqlMutation(useDeletePaymentByIdMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  const rejectLoan = useWrappedUrqlMutation(useRejectLoanByIdMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  const approveLoan = useWrappedUrqlMutation(useApproveLoanByIdMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  const upsertPayment = useWrappedUrqlMutation(useUpsertPaymentMutation, {
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (e) => console.warn(e),
  });

  const upsertLoanMutation = useWrappedUrqlMutation(useUpsertLoanMutation, {
    onSuccess: (res) => {
      toast.success('Updated loan succesfully');
    },
    onError: (e) => {
      console.warn(e);
      toast.error('Could not save Loan changes');
    },
  });

  const updateLoan = async (object: any) => {
    await upsertLoanMutation.dispatch({
      object,
    });
  };

  const approve = () => {
    if (
      window.confirm('Are you sure you want to approve this application?') &&
      loan?.id
    ) {
      approveLoan.dispatch({ id: loan?.id });
      toast.success('Approved application, converted to loan');
      // setImmediate(() => closeHandler());
    }
  };

  const reject = () => {
    if (
      window.confirm('Are you sure you want to reject this application?') &&
      loan?.id
    ) {
      rejectLoan.dispatch({ id: loan?.id });
      toast.success('Rejected application');
      setImmediate(() => closeHandler());
    }
  };

  const removeLoan = () => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${
          loan?.is_approved ? 'loan' : 'application'
        }?`,
      ) &&
      loan?.id
    ) {
      deleteLoan.dispatch({ id: loan?.id });
      toast.success('Deleted loan');
      setImmediate(() => closeHandler());
    }
  };

  const reconcilePayment = (payment: any, reconciled = true) => {
    const {
      id,
      payment_due_date,
      amount,
      split_principal,
      split_fee,
      split_interest,
      loan_id,
      customer_id,
    } = payment;
    upsertPayment.dispatch({
      object: {
        id,
        payment_due_date: dayjs(payment_due_date).format('YYYY-MM-DD'),
        amount,
        split_principal,
        split_fee,
        split_interest,
        loan_id,
        customer_id,
        reconciled,
      },
    });

    toast.info('Updated payment(s)', { autoClose: 1000 });
  };

  const deferPayment = (payment: any) => {
    const paymentsToUpdate = [
      payment,
      ...(loan?.payments?.filter((p) =>
        dayjs(p.payment_due_date).isAfter(payment.payment_due_date),
      ) ?? []),
    ];

    paymentsToUpdate?.forEach((p) =>
      upsertPayment.dispatch({
        object: {
          id: p.id,
          payment_due_date: dayjs(p.payment_due_date)
            .add(1, 'month')
            .format('YYYY-MM-DD'),
          amount: p.amount,
          split_principal: p.split_principal,
          split_fee: p.split_fee,
          split_interest: p.split_interest,
          loan_id: p.loan_id,
          customer_id: p.customer_id,
          reconciled: p.reconciled,
          deferred: p.id === payment.id ? true : false,
        },
      }),
    );

    toast.info('Updated payment(s)', { autoClose: 1000 });
  };

  const settlementFee = calcEarlySettlementFee(
    loan?.payments ?? [],
    loan?.early_settlement_penalty_months_interest ?? 1,
    loan?.last_payment_fee ?? 0,
  );

  const settleEarly = async () => {
    const outstandingPayments =
      loan?.payments?.filter((p) => p.reconciled === false) ?? [];

    for await (const outstandingPayment of outstandingPayments) {
      await deletePayment.dispatch({ id: outstandingPayment.id });
    }

    const { data: newPayment } = await upsertPayment.dispatch({
      object: {
        loan_id: loan?.id,
        payment_due_date: dayjs().format('YYYY-MM-DD'),
        amount: settlementFee.total,
        split_principal: settlementFee.principal,
        split_fee: settlementFee.fee,
        split_interest: settlementFee.interest,
        customer_id: loan?.customer_id,
        deferred: false,
        reconciled: false,
      },
    });

    // run this separately so that it triggers the UPDATE hasura trigger
    // if the payment is reconciled upon creation the 'loan_complete' notification will not fire
    await upsertPayment.dispatch({
      object: {
        id: newPayment.insert_payments_one.id,
        loan_id: loan?.id,
        payment_due_date: dayjs().format('YYYY-MM-DD'),
        amount: settlementFee.total,
        split_principal: settlementFee.principal,
        split_fee: settlementFee.fee,
        split_interest: settlementFee.interest,
        customer_id: loan?.customer_id,
        deferred: false,
        reconciled: true, // CHANGED TO TRUE
      },
    });
  };

  if (query.fetching) return <p>loading...</p>;

  return (
    <section className="self-stretch">
      <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
        <div className="flex justify-end p-2">
          {loan?.is_approved ? (
            <ButtonBase
              className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
              onClick={removeLoan}
            >
              Delete
            </ButtonBase>
          ) : (
            <>
              <ButtonBase
                className="p-2 px-4 mr-2 text-sm font-semibold text-green-500 bg-white rounded-md shadow-none btn hover:text-green-800 hover:bg-green-200 hover:shadow-md"
                onClick={approve}
              >
                Approve
              </ButtonBase>
              <ButtonBase
                className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
                onClick={reject}
              >
                Reject
              </ButtonBase>
            </>
          )}
        </div>
      </div>

      <div className="p-3 bg-white rounded-t">
        <h3 className="p-3 text-2xl font-bold">
          {loan?.is_approved ? 'Loan GRF' : 'Application QUO'}
          {loan?.reference?.toString().padStart(4, '0')}
        </h3>

        <div className="flex">
          <div className="p-3 m-3 text-xs border-2 border-gray-400 rounded">
            <EditLoanForm.Wrapper
              onSubmit={updateLoan}
              initialValues={{
                id: loan?.id,
                type: loan?.type || 'personal',
                custom_type_name: loan?.custom_type_name || '',
                amount: loan?.amount,
                fees: loan?.fees,
                first_payment_date:
                  loan?.payments?.sort((a, b) =>
                    a?.payment_due_date > b?.payment_due_date ? 1 : -1,
                  )?.[0]?.payment_due_date ?? loan?.first_payment_date,
                contract_date: loan?.contract_date,
                customer_id: loan?.customer_id,
                customer_name: loan?.customer_name,
                last_payment_fee: loan?.last_payment_fee,
                reference: loan?.reference,
                apr: loan?.apr?.toFixed(2),
                is_approved: loan?.is_approved,
                is_declined: loan?.is_declined,
                term_months: loan?.term_months,
                early_settlement_penalty_months_interest:
                  loan?.early_settlement_penalty_months_interest,
              }}
              className="grid grid-cols-1 gap-3"
            >
              <EditLoanForm.Inputs.customer_name
                disabled
                labelText="Customer"
              />
              <EditLoanForm.Inputs.amount
                disabled
                labelText="Amount"
                prefix="£"
              />
              <EditLoanForm.Inputs.term_months
                disabled
                labelText="Term"
                suffix="months"
              />
              <EditLoanForm.Inputs.apr
                disabled
                labelText="Interest Rate"
                suffix="%"
              />
              <EditLoanForm.Inputs.contract_date
                disabled
                labelText="Contract Date"
              />
              <EditLoanForm.Inputs.first_payment_date
                disabled
                labelText="First Payment Date"
              />
              <EditLoanForm.Inputs.fees
                disabled
                labelText="Documentation Fee"
                prefix="£"
              />
              <EditLoanForm.Inputs.early_settlement_penalty_months_interest
                labelText="Early Settlement Interest Penalty (months)"
                suffix="months"
                min="1"
                max="3"
              />
              <EditLoanForm.Inputs.type
                options={[
                  { value: 'personal', label: 'Personal Loan' },
                  { value: 'hpa', label: 'Hire Purchase Agreement' },
                  { value: 'interest_only', label: 'Interest Only Loan' },
                  { value: 'bespoke', label: 'Custom Loan' },
                ]}
                valueAccessor={(option: any) => option.value as string}
                labelAccessor={(option: any) => option.label as string}
              />
              {loan?.type === 'hpa' || loan?.type === 'bespoke' ? (
                <EditLoanForm.Inputs.last_payment_fee
                  labelText="Option-to-purchase Fee"
                  prefix="£"
                />
              ) : undefined}
              <EditLoanForm.Consumer>
                {({ values, dirty }) => (
                  <>
                    {values?.type === 'bespoke' ? (
                      <EditLoanForm.Inputs.custom_type_name
                        placeholder="Personal Loan"
                        labelText="Custom Loan Description"
                      />
                    ) : undefined}
                    {dirty && !upsertLoanMutation.fetching ? (
                      <ButtonBase
                        className="p-2 px-4 mt-3 mr-2 text-sm font-semibold text-white rounded-md shadow-none btn bg-gnavy-800"
                        type="submit"
                        disabled={!dirty}
                      >
                        Update Loan
                      </ButtonBase>
                    ) : undefined}{' '}
                    {upsertLoanMutation.fetching
                      ? 'Saving changes...'
                      : undefined}
                  </>
                )}
              </EditLoanForm.Consumer>
            </EditLoanForm.Wrapper>
          </div>

          <div className="flex-grow p-3 m-3 mb-16 border-2 border-gray-400 rounded">
            {loan?.payments.length > 0 &&
            loan?.payments.some((p) => p.reconciled === false) ? (
              <>
                <p className="mb-3 text-xs text-gray-700">
                  Early settlement for{' '}
                  {formatCurrency(settlementFee.fee, 'GBP')} (
                  {loan?.early_settlement_penalty_months_interest} months
                  interest @ {formatCurrency(settlementFee.interest, 'GBP')})
                  <button
                    className="px-2 py-1 ml-4 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                    onClick={() => {
                      window.confirm('Mark this loan as repaid?') &&
                        settleEarly();
                    }}
                  >
                    Mark as settled early
                  </button>
                </p>
                <p className="mb-6 text-xs text-red-500">
                  Clicking 'Mark as paid' will automatically notify any users
                  with accesss to this account (if they have notifications
                  turned on).
                </p>
              </>
            ) : (
              <p className="mb-6 text-xs text-red-500">
                No outstanding payments
              </p>
            )}
            <h2 className="text-sm font-normal text-gray-600 uppercase">
              Payments
            </h2>
            <div className="flex items-center justify-between p-2 space-x-3 text-xs">
              <div className="w-20 text-center text-gray-600">Date</div>
              <div className="w-16 text-right text-gray-600">Repayment</div>
              <div className="w-16 text-right text-gray-600">Priciple</div>
              <div className="w-16 text-right text-gray-600">Interest</div>
              <div className="w-16 text-right text-gray-600">Fee</div>
              <div className="flex-grow" />
              <div className="text-center text-gray-600">Actions</div>
            </div>
            {loan?.payments && loan.payments?.length > 0 ? (
              <>
                {loan?.payments
                  ?.sort((a, b) =>
                    a?.payment_due_date > b?.payment_due_date ? 1 : -1,
                  )
                  .map((payment, i) => (
                    <div
                      key={payment?.id}
                      className={`flex items-center space-x-3 justify-between p-2 text-xs ${
                        i < loan.payments.length - 1 && 'border-b'
                      } ${
                        payment?.reconciled && 'text-teal-500 font-semibold'
                      }`}
                    >
                      {activePaymentId === payment?.id ? (
                        <EditPaymentRow
                          paymentId={payment?.id}
                          loanId={loan?.id}
                          customerId={loan?.customer_id}
                          paymentDueDate={payment?.payment_due_date}
                          setActivePaymentId={(payment: any) =>
                            setActivePaymentId(payment?.id)
                          }
                          amount={payment?.amount}
                          splitFee={payment?.split_fee}
                          splitInterest={payment?.split_interest}
                          splitPrincipal={payment?.split_principal}
                        />
                      ) : (
                        <>
                          <div className="pr-3 whitespace-no-wrap">
                            {payment?.payment_due_date}
                          </div>
                          <div className="w-16 text-right">
                            {formatCurrency(payment?.amount, 'GBP')}
                          </div>
                          <div className="w-16 text-right text-teal-500">
                            {formatCurrency(payment?.split_principal, 'GBP')}
                          </div>
                          <div className="w-16 text-right text-teal-500">
                            {formatCurrency(payment?.split_interest, 'GBP')}
                          </div>
                          <div className="w-16 text-right text-teal-500">
                            {formatCurrency(payment?.split_fee, 'GBP')}
                          </div>
                          <div className="flex-grow" />
                        </>
                      )}

                      <div className="flex items-center">
                        {payment?.reconciled ? (
                          <>
                            {!loan.payments[i + 1]?.reconciled && (
                              <HeroIconTrash
                                className="mr-1 text-red-200 cursor-pointer hover:text-red-400"
                                onClick={() => reconcilePayment(payment, false)}
                              />
                            )}
                            <HeroIconCheckCircle className="text-teal-500" />
                          </>
                        ) : i === 0 || loan.payments[i - 1]?.reconciled ? (
                          <>
                            <button
                              className="px-2 py-1 mr-2 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                              onClick={() =>
                                window.confirm(
                                  'Are you sure you want to defer this payment by 1 month?',
                                ) && deferPayment(payment)
                              }
                            >
                              Defer 1 mo.
                            </button>
                            <button
                              className="px-2 py-1 mr-2 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                              onClick={() => reconcilePayment(payment, true)}
                            >
                              Mark as paid
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                        {!payment?.reconciled && (
                          <button
                            className="px-2 py-1 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                            onClick={() =>
                              activePaymentId === payment?.id
                                ? setActivePaymentId('')
                                : setActivePaymentId(payment?.id)
                            }
                          >
                            {activePaymentId === payment?.id
                              ? 'Cancel'
                              : 'Edit'}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                {!isAddingNewPayment ? (
                  <div className="flex items-center justify-end py-4 pr-2 space-x-2 text-xs">
                    <button
                      className="px-2 py-1 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                      onClick={toggleAddingNewPayment}
                    >
                      Add Payment
                    </button>
                  </div>
                ) : undefined}
              </>
            ) : (
              <p className="my-4 text-xs italic text-center text-gray-600">
                No loan repayments have been generated for this loan yet.
                <br />
                <ButtonBase
                  className="mx-auto mt-2 text-xs text-blue-600 bg-blue-100"
                  onClick={refresh}
                >
                  <HeroIconRefresh className="w-3 h-3 mr-2" /> Click to Refresh
                  Payments
                </ButtonBase>
              </p>
            )}

            {isAddingNewPayment ? (
              <div className="flex items-center justify-end py-4 pr-2 space-x-2 text-xs">
                <EditPaymentRow
                  paymentId={undefined}
                  loanId={loan?.id}
                  customerId={loan?.customer_id}
                  paymentDueDate={undefined}
                  amount={undefined}
                  splitFee={undefined}
                  splitInterest={undefined}
                  splitPrincipal={undefined}
                  setActivePaymentId={() => setActivePaymentId('')}
                  onSuccess={() => setIsAddingNewPayment(false)}
                />
                <button
                  className="px-2 py-1 font-semibold text-teal-500 border-2 border-teal-500 rounded"
                  onClick={() => setIsAddingNewPayment(false)}
                >
                  Cancel
                </button>
              </div>
            ) : undefined}

            {/*
            <h2 className="text-sm font-normal text-gray-600 uppercase">
              Message History
            </h2>
            <div className="flex items-center justify-between p-2 space-x-3 text-xs text-gray-600">
              <div className="flex-none w-32">Type</div>
              <div className="flex-none w-24">Date</div>
              <div className="flex-none w-32">To</div>
              <div className="flex-auto">Message</div>
            </div>
            */}

            {/*loan?.messages_sents && loan.messages_sents?.length > 1 ? (
              <>
                {loan?.messages_sents.map((message, i) => (
                  <div
                    key={message.id}
                    className={`flex items-center space-x-3 justify-between p-2 text-xs text-teal-500 font-semibold ${
                      i < loan.messages_sents.length - 1 && 'border-b'
                    }`}
                  >
                    <div className="flex-none w-32 whitespace-no-wrap">
                      {startCase(message.message_template.template_type_id)}
                    </div>
                    <div className="flex-none w-24">
                      {dayjs(message.sent_at).format('YYYY-MM-DD')}
                    </div>
                    <div className="flex-none w-32">{message.user.name}</div>
                    <div className="flex-1">{message.message_content}</div>
                  </div>
                ))}
              </>
            ) : (
              <p className="mt-4 text-xs italic text-center text-gray-600">
                No message record for this loan yet.
              </p>
            )*/}
          </div>
        </div>
      </div>
    </section>
  );
};
