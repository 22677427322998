import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { MessageModal } from 'components/MessageModal/MessageModal';
import { ModalToggleLink } from 'components/ModalBase/ModalToggleLink/ModalToggleLink';
import { BellOutline } from 'heroicons-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const MessageButton = ({
  newMessageCount,
}: {
  newMessageCount?: number;
}) => {
  const history = useHistory();

  return (
    <ModalToggleLink
      path="/loans/messages"
      exact
      modal={
        <MessageModal
          closeHandler={() => {
            history.push('/loans');
          }}
        />
      }
    >
      <ButtonBase className="relative flex items-center px-1 py-1 ml-1 text-xs shadow-none bg-gnavy-100 text-gnavy-700 sm:text-sm sm:px-2">
        <BellOutline
          path={undefined}
          className="flex-shrink-0 w-3 h-3 mb-px mr-1 sm:w-4 sm:h-4"
          onClick={() => {
            history.push('/loans/messages');
          }}
        />
        Messages
        {Boolean(newMessageCount) && (
          <div className="absolute top-0 right-0 px-2 text-xs font-semibold text-white transform translate-x-1 -translate-y-2 bg-red-600 rounded-full shadow-xs">
            {newMessageCount}
          </div>
        )}
      </ButtonBase>
    </ModalToggleLink>
  );
};
