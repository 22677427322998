import React, { FC, useEffect, FocusEvent } from 'react';
import { GetLoansQuery } from './graphql/GetLoans.generated';
import { NewLoanForm } from './newLoanForm';
import { useGetCustomersQuery } from 'components/Admin/ManageCustomers/graphql/GetCustomers.generated';
import { useGetCustomerDetailsQuery } from 'components/Admin/ManageCustomers/graphql/GetCustomerDetails.generated';
import { useUpsertLoanMutation } from './graphql/UpsertLoan.generated';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { Customers, Loans } from 'graphql/schemaTypes.generated';
import { useWrappedUrqlMutation } from 'toolbox';
import { useParamsAndSearch } from 'routeMap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface INewLoanPage {
  nextLoanId?: GetLoansQuery['loans'][number]['id'] | null;
  closeHandler: (loan_id?: string) => any;
}

export const NewLoanPage: FC<INewLoanPage> = ({
  nextLoanId = 0,
  closeHandler,
}) => {
  const { search: urlSearchParams } = useParamsAndSearch('adminNewLoan');
  const [query] = useGetCustomersQuery();
  const customers = query?.data?.customers ?? [];

  const upsertLoan = useWrappedUrqlMutation(useUpsertLoanMutation, {
    onSuccess: (res) => {
      toast.success('Updated loan succesfully');
    },
    onError: (e) => {
      console.warn(e);
      toast.error('Could not save Loan changes');
    },
  });

  const submitForm = async (formValues: Partial<Loans>) => {
    const new_loan = await upsertLoan.dispatch({
      object: {
        customer_id: formValues?.customer_id,
        reference: formValues?.reference,
        amount: formValues?.amount,
        contract_date: formValues?.contract_date,
        first_payment_date: formValues?.first_payment_date,
        term_months: formValues?.term_months,
        apr: formValues?.apr,
        fees: formValues?.fees,
        type: formValues?.type,
        custom_type_name: formValues?.custom_type_name,
        last_payment_fee: formValues?.last_payment_fee,
        customer_name:
          customers.find((customer) => customer?.id === formValues?.customer_id)
            ?.name ?? '',
        is_approved: true,
        historic_loan: true,
        email_address: '', // todo: set this to the primary email address for the account
      },
    });

    closeHandler?.(new_loan?.data?.insert_loans_one?.id);
  };

  const customerDocFee = (customer_id?: string | null) =>
    customer_id ? customers?.find((c) => c?.id === customer_id)?.loan_fee : 0;

  return (
    <section className="self-stretch">
      <NewLoanForm.Wrapper
        onSubmit={submitForm}
        initialValues={{
          customer_id: urlSearchParams?.customerId,
          reference: nextLoanId,
          amount: 0,
          contract_date: new Date().toISOString().substring(0, 10),
          first_payment_date: dayjs()
            .startOf('month')
            .add(1, 'month')
            .toISOString()
            .substring(0, 10),
          term_months: 12,
          apr: 0,
          fees: customerDocFee(urlSearchParams?.customerId?.toString()),
          type: 'personal',
          custom_type_name: '',
          last_payment_fee: 0,
        }}
      >
        <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
          <div className="flex justify-end p-2">
            <NewLoanForm.Consumer>
              {({ handleReset }) => (
                <button
                  className="p-2 px-4 mr-2 text-sm font-semibold text-teal-600 bg-gray-100 rounded-md shadow-none hover:bg-white btn hover:shadow-md"
                  type="reset"
                  onClick={handleReset}
                >
                  Reset
                </button>
              )}
            </NewLoanForm.Consumer>
            <ButtonBase
              className="p-2 px-4 mr-2 text-sm font-semibold text-white bg-teal-500 rounded-md shadow-none btn hover:bg-teal-600 hover:shadow-md"
              type="submit"
            >
              Save
            </ButtonBase>
          </div>
        </div>

        <div className="p-3 bg-white rounded-t">
          <h3 className="mb-5 text-2xl font-bold">New Loan</h3>

          <div className="grid grid-cols-12 gap-3">
            <div className="flex flex-col col-span-4">
              <NewLoanForm.Inputs.customer_id
                id="customer_id"
                label="Customer"
                options={customers}
                valueAccessor={(customer: Customers) => customer.id}
                labelAccessor={(customer: Customers) => customer.name}
              />
              <NewLoanForm.Consumer>
                {({ values }) => <>{values?.customer_id && <CustomerInfo />}</>}
              </NewLoanForm.Consumer>
            </div>
            <div className="grid col-span-8 gap-3 mt-4">
              <NewLoanForm.Inputs.reference
                labelText="Reference"
                prefix="GRF"
                placeholder="0000"
              />
              <NewLoanForm.Inputs.contract_date labelText="Contract Date" />
              <NewLoanForm.Inputs.first_payment_date labelText="First Payment Date" />
              <NewLoanForm.Consumer>
                {({ values }) =>
                  values?.first_payment_date && (
                    <p className="text-xs text-center text-gray-500">
                      Subsequent payments will be on the{' '}
                      {dayjs(values.first_payment_date).format('Do')}
                      of each month
                    </p>
                  )
                }
              </NewLoanForm.Consumer>
              <NewLoanForm.Inputs.amount labelText="Amount" prefix="£" />
              <NewLoanForm.Inputs.term_months
                labelText="Term"
                placeholder="12"
                suffix="months"
              />
              <NewLoanForm.Consumer>
                {({ setFieldValue, values }) => (
                  <>
                    <NewLoanForm.Inputs.apr
                      labelText="Interest Rate"
                      suffix="%"
                      onBlur={(e: FocusEvent<HTMLInputElement>) =>
                        setFieldValue('apr', e.target.value.replace('%', ''))
                      }
                    />
                    <NewLoanForm.Inputs.type
                      labelText="Type"
                      options={[
                        { labelText: 'Personal Loan', value: 'personal' },
                        { labelText: 'Hire Purchase Agreement', value: 'hpa' },
                        {
                          labelText: 'Interest Only Loan',
                          value: 'interest_only',
                        },
                        { labelText: 'Custom Loan', value: 'bespoke' },
                      ]}
                    />
                    {values?.type === 'bespoke' ? (
                      <NewLoanForm.Inputs.custom_type_name
                        labelText="Custom Loan Description"
                        placeholder="Personal Loan"
                      />
                    ) : undefined}
                  </>
                )}
              </NewLoanForm.Consumer>
              <NewLoanForm.Inputs.fees
                labelText="Documentation Fee"
                prefix="£"
              />
              <NewLoanForm.Consumer>
                {({ values }) => (
                  <>
                    {values?.type === 'hpa' || values?.type === 'bespoke' ? (
                      <NewLoanForm.Inputs.last_payment_fee
                        labelText="Option-to-purchase Fee"
                        prefix="£"
                      />
                    ) : undefined}
                  </>
                )}
              </NewLoanForm.Consumer>
            </div>
          </div>
          <div className="h-24" />
        </div>
      </NewLoanForm.Wrapper>
    </section>
  );
};

const CustomerInfo: FC = () => {
  const formContext = NewLoanForm.useContext();
  const customer_id = formContext.values.customer_id;
  const loan_amount = formContext.values.amount;

  const [detailsQuery] = useGetCustomerDetailsQuery({
    variables: { customer_id },
    pause: !customer_id,
  });
  const customer = detailsQuery?.data?.customers_by_pk;

  let apr = 0;
  if (loan_amount <= 3000) apr = customer?.rate?.apr_lte_3000;
  if (loan_amount > 3000 && loan_amount <= 5000) {
    apr = customer?.rate?.apr_gt_3000_lte_5000;
  }
  if (loan_amount > 5000 && loan_amount <= 10000) {
    apr = customer?.rate?.apr_gt_5000_lte_10000;
  }
  if (loan_amount > 10000 && loan_amount <= 15000) {
    apr = customer?.rate?.apr_gt_10000_lte_15000;
  }
  if (loan_amount > 15000 && loan_amount <= 25000) {
    apr = customer?.rate?.apr_gt_15000_lte_25000;
  }
  if (loan_amount > 25000) apr = customer?.rate?.apr_gt_25000;

  useEffect(() => {
    if (customer) {
      formContext.setValues({
        ...formContext.values,
        apr: apr,
      });
    }
  }, [customer, loan_amount]);

  return (
    <div className="pl-1 mt-4 text-xs">
      <div>APR Guide (Rate {customer?.rate?.id}):</div>
      <ul className="pt-2 pl-4 text-gray-500">
        <li
          className={
            !!loan_amount && loan_amount <= 3000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount <= 3000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          Up to £3000: {customer?.rate?.apr_lte_3000}%
        </li>
        <li
          className={
            !!loan_amount && loan_amount > 3000 && loan_amount <= 5000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount > 3000 && loan_amount <= 5000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          £3000.01-£5000: {customer?.rate?.apr_gt_3000_lte_5000}%
        </li>
        <li
          className={
            !!loan_amount && loan_amount > 5000 && loan_amount <= 10000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount > 5000 && loan_amount <= 10000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          £5000.01-£10,000: {customer?.rate?.apr_gt_5000_lte_10000}%
        </li>
        <li
          className={
            !!loan_amount && loan_amount > 10000 && loan_amount <= 15000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount > 10000 && loan_amount <= 15000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          £10,000.01-£15,000: {customer?.rate?.apr_gt_10000_lte_15000}%
        </li>
        <li
          className={
            !!loan_amount && loan_amount > 15000 && loan_amount <= 25000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount > 15000 && loan_amount <= 25000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          £15,000.01-£25,000: {customer?.rate?.apr_gt_15000_lte_25000}%
        </li>
        <li
          className={
            !!loan_amount && loan_amount > 25000
              ? 'text-gray-900 font-semibold'
              : undefined
          }
        >
          {!!loan_amount && loan_amount > 25000 && (
            <span className="mr-2" style={{ marginLeft: '-14px' }}>
              •
            </span>
          )}
          &gt;£25,000.01: {customer?.rate?.apr_gt_25000}%
        </li>
      </ul>

      <div className="mt-5">Documentation Fee Guide:</div>

      <ul className="pt-2 pl-2 text-gray-500">
        <li>£{customer?.loan_fee ?? 125}</li>
      </ul>
    </div>
  );
};
