import React, { FC, Suspense, useEffect, useState } from 'react';
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { EditMessagePage } from './EditMessagePage';
import {
  useGetMessagesQuery,
  GetMessagesQuery,
} from './graphql/GetMessages.generated';

export const ManageMessages: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query, refresh] = useGetMessagesQuery();
  const [expandedMessage, setExpandedMessage] = useState(
    location.pathname.split('/admin/messages/')?.[1] || null,
  );

  useEffect(() => {
    setExpandedMessage(
      location.pathname.split('/admin/messages/')?.[1] || null,
    );
  }, [location]);

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-col flex-grow overflow-hidden bg-gblue-700 rounded-tl-md">
        <div className="flex self-stretch justify-start">
          <div className="max-h-screen h-full relative flex flex-col flex-grow pb-2 border-l-8 border-r-8 border-gblue-700 w-32">
            <h3 className="mt-2 text-lg font-semibold text-white">Messages</h3>
            <div className="flex items-stretch justify-end py-2">
              <Link
                to="/admin/messages/new"
                className="flex items-center p-2 my-px text-sm font-semibold text-teal-700 bg-teal-200 rounded w-full justify-center"
                onClick={() => setExpandedMessage('new')}
              >
                <span className="truncate">New Message</span>
              </Link>
            </div>

            <div className="text-2xs mb-2 text-white bg-blue-500 p-2 rounded">
              ℹ️ Messages are displayed in the Messages screen (in-app) and may
              also trigger SMS/PUSH/Email notifications depending on the
              recipient's preferences
            </div>

            <div className="overflow-hidden flex-grow rounded pb-10">
              {query?.fetching ? (
                <div className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-gray-500">
                    Loading...
                  </div>
                </div>
              ) : query?.error ? (
                <div className="!bg-red-200 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-red-700">
                    Error: {query?.error?.message}
                  </div>
                </div>
              ) : (
                <div className="overflow-y-scroll AdminScrollbars h-full rounded">
                  {query?.data?.messages.map(
                    (message) =>
                      message?.id && (
                        <MessageRow
                          key={message.id}
                          message={message}
                          expanded={expandedMessage === message.id}
                          openHandler={() => {
                            setExpandedMessage(message.id);
                          }}
                          closeHandler={() => {
                            setExpandedMessage(null);
                            history.push('/admin/messages');
                            refresh();
                          }}
                        />
                      ),
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="w-2/3 flex-grow h-screen overflow-y-scroll overflow-x-hidden AdminScrollbars mt-2">
            {expandedMessage ? (
              <EditMessagePage
                messageId={expandedMessage}
                onClose={() => refresh()}
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
                Choose a message to edit
              </div>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

interface IMessageRow {
  message: GetMessagesQuery['messages'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}

const MessageRow: FC<IMessageRow> = ({ message, expanded = false }) => {
  if (!message?.id) return null;
  return (
    <NavLink
      to={`/admin/messages/${message?.id}`}
      className="flex flex-col justify-center py-1 px-2 border-b border-teal-500 border-opacity-25 text-xs bg-teal-100"
      activeClassName="bg-teal-300"
      id={message?.id}
      // onClick={expanded ? closeHandler : openHandler}
    >
      <h3
        className={`text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        } flex justify-between items-center`}
      >
        <div className="truncate flex-grow font-semibold">
          {message?.subject}
        </div>
      </h3>
      <h4
        className={`text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        } flex justify-between items-center text-2xs`}
      >
        <div className="flex-shrink-0">
          {dayjs(message?.created_at).format('YYYY-MM-DD')}
        </div>

        {message?.recipients?.length > 0 && (
          <>
            <div className="flex-shrink-0 px-1">&rarr;</div>
            <div className="truncate flex-grow">
              {message?.recipients?.length > 1
                ? `${message?.recipients.length} recipients`
                : message?.recipients?.[0].user.email}
            </div>
          </>
        )}
      </h4>
    </NavLink>
  );
};
