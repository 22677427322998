import { ReactComponent as CalculatorSvg } from 'assets/calculator.svg';
import cssBase from 'components/CompactCard.module.css';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

import css from './NewLoanCardCompact.module.css';

interface INewLoanCardCompact {
  disabled?: boolean;
  onClick?: () => void;
}

export const NewLoanCardCompact: FC<INewLoanCardCompact> = ({
  disabled,
  onClick = () => null,
}) => {
  return (
    <motion.div
      onClick={disabled ? () => null : onClick}
      className={[css.NewLoanCardCompact, cssBase.CompactCard].join(' ')}
    >
      <div className={css.NewLoanGraphic}>
        <CalculatorSvg />
      </div>
      <main>
        <h2>New Loan</h2>
        <h4>Calculator</h4>
      </main>
      <footer>Apply Now</footer>
    </motion.div>
  );
};
