import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetUserDetailsQueryVariables = {
  cognito_sub: Types.Scalars['String']
};


export type GetUserDetailsQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk: Types.Maybe<(
    { __typename?: 'users' }
    & Pick<Types.Users, 'cognito_sub' | 'email' | 'phone_number' | 'name' | 'title' | 'gender' | 'maiden_name' | 'date_of_birth' | 'place_of_birth' | 'nationality' | 'residential_status' | 'marital_status' | 'number_of_children' | 'self_employed' | 'current_employer' | 'current_occupation' | 'years_at_current_employer' | 'previous_employer' | 'previous_occupation' | 'years_at_previous_employer' | 'notify_by_email' | 'notify_by_sms' | 'notify_by_app' | 'notify_on_payment_received' | 'selected_customer_id'>
    & { selected_customer: Types.Maybe<(
      { __typename?: 'customers' }
      & Pick<Types.Customers, 'name'>
    )>, customers: Array<(
      { __typename?: 'delegates' }
      & Pick<Types.Delegates, 'id'>
      & { customer: (
        { __typename?: 'customers' }
        & Pick<Types.Customers, 'id' | 'name'>
      ) }
    )>, user_devices: Array<(
      { __typename?: 'user_devices' }
      & Pick<Types.User_Devices, 'uuid' | 'platform' | 'manufacturer' | 'model' | 'token' | 'channel_type' | 'os' | 'os_version' | 'created_at' | 'updated_at'>
    )> }
  )>, customers: Array<(
    { __typename?: 'customers' }
    & Pick<Types.Customers, 'id' | 'name'>
  )> }
);


export const GetUserDetailsDocument = gql`
    query GetUserDetails($cognito_sub: String!) {
  users_by_pk(cognito_sub: $cognito_sub) {
    cognito_sub
    email
    phone_number
    name
    title
    gender
    maiden_name
    date_of_birth
    place_of_birth
    nationality
    residential_status
    marital_status
    number_of_children
    self_employed
    current_employer
    current_occupation
    years_at_current_employer
    previous_employer
    previous_occupation
    years_at_previous_employer
    notify_by_email
    notify_by_sms
    notify_by_app
    notify_on_payment_received
    selected_customer_id
    selected_customer {
      name
    }
    customers(order_by: {customer: {name: asc}}) {
      id
      customer {
        id
        name
      }
    }
    user_devices {
      uuid
      platform
      manufacturer
      model
      token
      channel_type
      os
      os_version
      created_at
      updated_at
    }
  }
  customers(order_by: {name: asc}) {
    id
    name
  }
}
    `;

export function useGetUserDetailsQuery(options: Omit<Urql.UseQueryArgs<GetUserDetailsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetUserDetailsQuery>({ query: GetUserDetailsDocument, ...options });
};