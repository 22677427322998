import React, { FC, useEffect, useState } from 'react';
import { GetCustomersQuery } from './graphql/GetCustomers.generated';
import { EditCustomerForm } from './editCustomerForm';
import { AddAddressForm } from './addAddressForm';
import { useGetRatesQuery } from 'components/Admin/ManageRates/graphql/GetRates.generated';
import { useGetCustomerDetailsQuery } from './graphql/GetCustomerDetails.generated';
import { ResetButton } from 'components/ResetButton/ResetButton';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import { formatCurrency } from 'helpers/formatCurrency';
import { Link, useHistory } from 'react-router-dom';
import { useUpsertCustomerMutation } from './graphql/UpsertCustomer.generated';
import { useAddCustomerAddressMutation } from './graphql/AddCustomerAddress.generated';
import { useDeleteAddressMutation } from './graphql/DeleteAddress.generated';
import { HeroIconExternalLink, useWrappedUrqlMutation } from 'toolbox';
import { Addresses, Customers } from 'graphql/schemaTypes.generated';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { TrashOutline } from 'heroicons-react';
import * as Sentry from '@sentry/react';

interface IEditCustomerPage {
  customerId: GetCustomersQuery['customers'][number]['id'];
  closeHandler: () => void;
}

export const EditCustomerPage: FC<IEditCustomerPage> = ({
  customerId,
  closeHandler,
}) => {
  const [detailsQuery] = useGetCustomerDetailsQuery({
    variables: { customer_id: customerId },
  });
  const customer = detailsQuery?.data?.customers_by_pk;
  const [ratesQuery] = useGetRatesQuery();
  const rates = ratesQuery?.data?.rate_cards;
  const history = useHistory();

  const isNew = !customer || customerId === 'new';

  const upsertCustomer = useWrappedUrqlMutation(useUpsertCustomerMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.log(e),
  });

  const deleteAddress = useWrappedUrqlMutation(useDeleteAddressMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.log(e),
  });

  const upsertCustomerHandler = async (
    formValues: Partial<Customers> & { type: 'individual' | 'business' },
  ) => {
    try {
      await upsertCustomer.dispatch({
        object: {
          id: isNew ? undefined : formValues?.id,
          name: formValues?.name,
          // business_unit_id: formValues?.business_unit_id,
          date_established: formValues?.date_established,
          date_incorporated: formValues?.date_incorporated,
          individual: formValues?.type == 'individual' ? true : false,
          loan_fee: formValues?.loan_fee,
          nature_of_business: formValues?.nature_of_business,
          place_of_incorporation: formValues?.place_of_incorporation,
          rate_id: formValues?.rate_id,
        },
      });
      toast.success('Updated customer succesfully');
      // setImmediate(() => closeHandler());
    } catch (e) {
      console.warn(e);
      toast.error('Could not save Customer changes');
      Sentry.captureException(e);
    }
  };

  const deleteAddressHandler = async (address_id: string) => {
    try {
      await deleteAddress.dispatch({
        id: address_id,
      });
      toast.success('Deleted address succesfully');
      // setImmediate(() => closeHandler());
    } catch (e) {
      console.warn(e);
      toast.error('Could not delete address');
      Sentry.captureException(e);
    }
  };

  const approved_loans =
    customer?.loans?.filter((loan) => loan?.is_approved) ?? [];
  const unapproved_loans =
    customer?.loans?.filter(
      (loan) => !loan?.is_approved && !loan?.is_declined,
    ) ?? [];

  return (
    <>
      <section>
        <EditCustomerForm.Wrapper
          onSubmit={upsertCustomerHandler}
          initialValues={{
            id: customer?.id,
            name: customer?.name ?? '',
            // business_unit_id: customer?.business_unit_id,
            date_established: customer?.date_established,
            date_incorporated: customer?.date_incorporated,
            type: customer?.individual ? 'individual' : 'business',
            loan_fee: customer?.loan_fee ?? 125,
            nature_of_business: customer?.nature_of_business ?? '',
            place_of_incorporation:
              customer?.place_of_incorporation ?? 'Jersey',
            rate_id: customer?.rate_id ?? 'A',
          }}
        >
          <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
            <div className="flex justify-end p-2">
              <ResetButton className="p-2 px-4 mr-2 text-sm font-semibold text-gray-600 bg-gray-100 rounded-md shadow-md btn">
                Reset
              </ResetButton>
              <SubmitButton className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-200">
                {isNew ? 'Create Customer' : 'Save Changes'}
              </SubmitButton>
            </div>
          </div>
          <div className="grid grid-flow-row gap-3 p-3 pb-10 bg-white rounded-t">
            <h2 className="text-2xl font-bold">
              {isNew ? 'New Customer' : customer?.name ?? customer?.id}
            </h2>

            <div className="p-4 border-2 border-gray-400 rounded">
              <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                Customer Details
              </h2>
              <EditCustomerForm.Inputs.name
                labelText="Account Name"
                labelPosition="left"
                className="mb-4"
              />
              <EditCustomerForm.Inputs.type
                id="type"
                name="type"
                labelText="Account Type"
                options={[
                  {
                    value: 'individual',
                    labelText: `Individual/Personal`,
                    hintText: '',
                  },
                  {
                    value: 'business',
                    labelText: `Business/Corporate`,
                    hintText: '',
                  },
                ]}
              />
              <EditCustomerForm.Inputs.date_established
                labelText="Date Established"
                labelPosition="left"
                className="my-4"
              />
              <EditCustomerForm.Inputs.date_incorporated
                labelText="Date Incorporated"
                labelPosition="left"
                className="mb-4"
              />
              <EditCustomerForm.Inputs.place_of_incorporation
                labelText="Place of Incorporation"
                labelPosition="left"
                className="mb-4"
              />
              <EditCustomerForm.Inputs.nature_of_business
                labelText="Nature of Business"
                labelPosition="left"
                className="mb-4"
              />
              <EditCustomerForm.Inputs.loan_fee
                labelText="Default Doc Fee"
                prefix="£"
                labelPosition="left"
                className="mb-4"
              />
              <EditCustomerForm.Inputs.rate_id
                id="rate_id"
                name="rate_id"
                labelText="Rate for new Loans"
                options={
                  rates?.map((rate) => ({
                    value: rate?.id,
                    labelText: `${rate.id} (${rate.apr_gt_25000}-${rate.apr_lte_3000}%)`,
                    hintText: '',
                  })) || []
                }
              />
            </div>

            {!isNew && (
              <div className="p-4 border-2 border-gray-400 rounded">
                <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                  Users with Access (
                  {customer?.delegates_aggregate?.aggregate?.count || 0})
                </h2>
                <ul className="text-sm list-disc">
                  {customer?.delegates?.map(({ user }, i) => (
                    <li key={i} className="ml-5">
                      {user?.email}
                    </li>
                  ))}
                </ul>
                <p className="pt-4 text-xs text-gray-500">
                  Edit this list via the{' '}
                  <Link
                    to={`/admin/users/`}
                    className="text-blue-500 underline"
                  >
                    User admin page
                  </Link>
                </p>
              </div>
            )}

            {!isNew && (
              <div className="p-4 border-2 border-gray-400 rounded">
                <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                  Active Loans
                </h2>
                <ul className="text-sm list-disc">
                  {approved_loans?.length > 0 ? (
                    approved_loans.map((loan) => (
                      <li key={loan?.id} className="ml-5">
                        <Link
                          to={`/admin/loans/${loan?.id}`}
                          className="flex items-center text-sm text-blue-500 underline"
                        >
                          GRF{loan?.reference?.toString().padStart(4, '0')} for{' '}
                          {formatCurrency(loan?.amount, 'GBP')}{' '}
                          <HeroIconExternalLink className="w-3 h-3 ml-1 text-gray-400" />
                        </Link>
                      </li>
                    ))
                  ) : (
                    <p className="text-xs italic text-gray-600">
                      This customer has no Active Loans
                    </p>
                  )}
                </ul>
                <button
                  type="button"
                  className="w-full p-2 mt-4 text-gray-800 bg-gray-300 rounded"
                  onClick={() => {
                    history.push(`/admin/loans/new?customerId=${customer?.id}`);
                  }}
                >
                  Add Loan
                </button>
              </div>
            )}

            {!isNew && (
              <div className="p-4 border-2 border-gray-400 rounded">
                <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                  Pending Applications
                </h2>
                <ul className="text-sm list-disc">
                  {unapproved_loans?.length > 0 ? (
                    unapproved_loans?.map((loan) => (
                      <li key={loan?.id} className="ml-5">
                        <Link
                          to={`/admin/loans/${loan?.id}`}
                          className="flex items-center text-sm text-blue-500 underline"
                        >
                          GRF{loan?.reference?.toString().padStart(4, '0')}
                          for {formatCurrency(loan?.amount, 'GBP')}{' '}
                          <HeroIconExternalLink className="w-3 h-3 ml-1 text-gray-400" />
                        </Link>
                      </li>
                    ))
                  ) : (
                    <p className="text-xs italic text-gray-600">
                      This customer has no unapproved Loan applications
                    </p>
                  )}
                </ul>
              </div>
            )}

            <div className="p-4 border-2 border-gray-400 rounded">
              <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                Current Address
              </h2>
              <div className="flex flex-col pb-5 text-sm">
                {(customer?.addresses ?? []).length > 0 ? (
                  <p>
                    {customer?.addresses?.[0]?.address1},{' '}
                    {customer?.addresses?.[0]?.address2},{' '}
                    {customer?.addresses?.[0]?.address3},{' '}
                    {customer?.addresses?.[0]?.country},{' '}
                    {customer?.addresses?.[0]?.postcode}
                  </p>
                ) : (
                  <p className="text-xs italic text-gray-600">
                    This customer has no current address
                  </p>
                )}
              </div>
              <NewAddressForm
                customerId={customerId}
                addressCount={customer?.addresses?.length}
              />
            </div>

            <div className="p-4 mb-48 border-2 border-gray-400 rounded">
              <h2 className="pb-3 text-sm font-normal text-gray-600 uppercase">
                Previous Addresses
              </h2>
              <ul className="text-sm list-disc">
                {(customer?.addresses ?? []).length > 1 ? (
                  customer?.addresses.map((addr, i) => {
                    if (i === 0) return null;
                    return (
                      <li key={addr?.id} className="ml-5">
                        {addr?.address1}, {addr?.address2}, {addr?.address3},{' '}
                        {addr?.country}, {addr?.postcode}{' '}
                        <TrashOutline
                          path={undefined}
                          className="inline w-5 h-5 mb-1 text-red-400 cursor-pointer hover:text-red-700"
                          onClick={() => {
                            deleteAddressHandler(addr?.id);
                          }}
                        />
                      </li>
                    );
                  })
                ) : (
                  <p className="text-xs italic text-gray-600">
                    This customer has no previous addresses
                  </p>
                )}
              </ul>
            </div>
          </div>
        </EditCustomerForm.Wrapper>
      </section>
    </>
  );
};

interface INewAddressForm {
  customerId: GetCustomersQuery['customers'][number]['id'];
  addressCount?: number;
}

const NewAddressForm: FC<INewAddressForm> = ({
  customerId,
  addressCount = 0,
}) => {
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  const addAddress = useWrappedUrqlMutation(useAddCustomerAddressMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.log(e),
  });

  const saveNewAddress = async (formValues: Partial<Addresses>) => {
    try {
      await addAddress.dispatch({
        object: {
          customer_id: customerId,
          address1: formValues?.address1,
          address2: formValues?.address1,
          address3: formValues?.address1,
          country: formValues?.country,
          postcode: formValues?.postcode,
          movein_date: formValues?.movein_date,
          moveout_date: formValues?.moveout_date,
        },
      });
      toast.success('Added address succesfully');
      setIsAddingAddress(false);
      // setImmediate(() => closeHandler());
    } catch (e) {
      console.warn(e);
      toast.error('Could not add new address');
      Sentry.captureException(e);
    }
  };

  return (
    <div className="grid grid-flow-row gap-3">
      {isAddingAddress ? (
        <div>
          <AddAddressForm.Wrapper
            onSubmit={saveNewAddress}
            initialValues={{
              address1: '',
              address2: '',
              address3: '',
              country: '',
              postcode: '',
              movein_date: dayjs().toISOString().substring(0, 10),
              moveout_date: null,
            }}
          >
            <div className="grid gap-3 bg-white">
              <AddAddressForm.Inputs.address1 labelText="Address Line 1" />
              <AddAddressForm.Inputs.address2 labelText="Address Line 2" />
              <AddAddressForm.Inputs.address3 labelText="Address Line 3" />
              <AddAddressForm.Inputs.country labelText="Country" />
              <AddAddressForm.Inputs.postcode labelText="Postcode" />
              <AddAddressForm.Inputs.movein_date labelText="Move In Date" />
              <AddAddressForm.Inputs.moveout_date labelText="Move Out Date" />
            </div>
            <div className="flex justify-end mt-3">
              <button
                type="reset"
                onClick={() => {
                  setIsAddingAddress(false);
                }}
                className="px-3 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <SubmitButton className="px-3 py-2 ml-2 text-white bg-teal-500 rounded">
                Submit
              </SubmitButton>
            </div>
          </AddAddressForm.Wrapper>
        </div>
      ) : (
        <button
          type="button"
          className="p-2 text-gray-800 bg-gray-300 rounded"
          onClick={() => {
            setIsAddingAddress(true);
          }}
        >
          {addressCount > 0 ? 'Replace Current Address' : 'Add Address'}
        </button>
      )}
    </div>
  );
};
