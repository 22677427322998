import './ReactSelect.css';
import React from 'react';
import { components } from 'react-select';
import SelectBase, {
  SelectBaseProps,
} from 'toolbox/src/components/SelectBase/SelectBase';
import { Input } from 'components/Inputs/Input';
import { ChevronDown } from 'heroicons-react';

interface ISelect<T> extends SelectBaseProps<T> {
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  className?: string;
  value: string | number | undefined;
  error?: string;
  name?: string;
  id?: string;
  hint?: string | JSX.Element;
  small?: boolean;
}

export function Select<T>({
  disabled,
  readOnly,
  label,
  className,
  value,
  error,
  hint,
  name,
  small,
  id,
  ...props
}: ISelect<T>) {
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDown
          path={undefined}
          className={` ${small ? 'text-gray-500' : 'text-white'} h-full`}
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="w-full -mt-2">
      {readOnly ? (
        <Input
          readOnly={true}
          value={value as string}
          labelText={label}
          name={name}
          small={small}
          disabled
        />
      ) : (
        <label className="pl-1 text-xs font-semibold tracking-wide text-gray-600 uppercase">
          {label}
          <SelectBase
            id={id}
            name={name}
            disabled={disabled}
            {...props}
            className="react-select-container"
            classNamePrefix={small ? 'small_react-select' : 'react-select'}
            components={{
              DropdownIndicator,
            }}
            value={value}
            aria-label={!label && name}
          />
        </label>
      )}
      {error && <p className="pt-1 mx-10 text-danger">{error}</p>}
      {hint && <p className="pt-1 mx-10 text-purple-800">{hint}</p>}
    </div>
  );
}
