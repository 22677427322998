import { makeMutationForm } from 'components/makeMutationForm';
import { Addresses } from 'graphql/schemaTypes.generated';

export const AddAddressForm = makeMutationForm<Partial<Addresses>>({
  config: {
    address1: {
      type: 'text',
    },
    address2: {
      type: 'text',
    },
    address3: {
      type: 'text',
    },
    country: {
      type: 'text',
    },
    postcode: {
      type: 'text',
    },
    movein_date: {
      type: 'date',
    },
    moveout_date: {
      type: 'date',
    },
  },
  showErrorsOnTouched: true,
  validate: ({
    address1,
    address2,
    address3,
    country,
    postcode,
    movein_date,
    moveout_date,
  }) => {
    if (!address1) {
      return {
        address1: 'Please enter the first line of the address',
      };
    }
    if (!movein_date) {
      return {
        movein_date: 'Please enter the date when the customer moved in',
      };
    }
  },
});
