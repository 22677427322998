import React, { FC, Suspense, useEffect, useState } from 'react';
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom';
import { EditGroupPage } from './EditGroupPage';
import {
  GetGroupsQuery,
  useGetGroupsQuery,
} from './graphql/GetGroups.generated';

export const ManageGroups: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query, refresh] = useGetGroupsQuery();
  const [expandedGroup, setExpandedGroup] = useState(
    location.pathname.split('/admin/groups/')?.[1] || null,
  );

  useEffect(() => {
    setExpandedGroup(location.pathname.split('/admin/groups/')?.[1] || null);
  }, [location.pathname]);

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-col flex-grow overflow-hidden bg-gblue-700 rounded-tl-md">
        <div className="flex self-stretch justify-start">
          <div className="relative flex-grow pb-2 overflow-x-hidden overflow-y-scroll border-l-8 border-r-8 AdminScrollbars border-gblue-700 w-32">
            <h3 className="mt-2 text-lg font-semibold text-white">Groups</h3>
            <div className="flex items-stretch justify-end py-2">
              <Link
                to="/admin/groups/new"
                className="flex items-center p-2 my-px text-sm font-semibold text-teal-700 bg-teal-200 rounded w-full justify-center"
                onClick={() => setExpandedGroup('new')}
              >
                <span className="truncate">New Group</span>
              </Link>
            </div>

            <div className="text-2xs mb-2 text-white bg-blue-500 p-2 rounded">
              ℹ️ Groups are used to filter which Notices are visible to users
            </div>

            <div className="overflow-hidden rounded">
              {query?.fetching ? (
                <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-gray-500">
                    Loading...
                  </div>
                </li>
              ) : query?.error ? (
                <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                  <div className="w-full font-semibold text-center text-red-700">
                    Error: {query?.error?.message}
                  </div>
                </li>
              ) : (
                query?.data?.groups
                  .sort((a, b) => (a?.id > b?.id ? 1 : -1))
                  .map(
                    (group) =>
                      group?.id && (
                        <GroupRow
                          key={group.id}
                          group={group}
                          expanded={expandedGroup === group.id}
                          openHandler={() => {
                            setExpandedGroup(group.id);
                          }}
                          closeHandler={() => {
                            setExpandedGroup(null);
                            history.push('/admin/groups');
                            refresh();
                          }}
                        />
                      ),
                  )
              )}
            </div>
          </div>

          <div className="w-2/3 flex-grow h-screen overflow-y-scroll overflow-x-hidden AdminScrollbars mt-2">
            {expandedGroup ? (
              <EditGroupPage
                groupId={expandedGroup}
                closeHandler={() => setExpandedGroup(null)}
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
                Choose a group to edit
              </div>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

interface IGroupRow {
  group: GetGroupsQuery['groups'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}

const GroupRow: FC<IGroupRow> = ({
  group,
  expanded = false,
  // openHandler,
  // closeHandler,
}) => {
  if (!group?.id) return null;
  return (
    <NavLink
      to={`/admin/groups/${group?.id}`}
      className="flex flex-col justify-center p-2 text-xs bg-teal-100"
      activeClassName="bg-teal-300"
      id={group?.id}
      // onClick={expanded ? closeHandler : openHandler}
    >
      <h3
        className={`font-semibold text-teal-800 w-full ${
          expanded ? 'text-teal-900' : ''
        }`}
      >
        {group?.id}
      </h3>
    </NavLink>
  );
};
