import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type RenameGroupMutationVariables = {
  groupId: Types.Scalars['String'],
  newName: Types.Scalars['String']
};


export type RenameGroupMutation = (
  { __typename?: 'mutation_root' }
  & { update_groups_by_pk: Types.Maybe<(
    { __typename?: 'groups' }
    & Pick<Types.Groups, 'id'>
  )> }
);


export const RenameGroupDocument = gql`
    mutation RenameGroup($groupId: String!, $newName: String!) {
  update_groups_by_pk(pk_columns: {id: $groupId}, _set: {id: $newName}) {
    id
  }
}
    `;

export function useRenameGroupMutation() {
  return Urql.useMutation<RenameGroupMutation, RenameGroupMutationVariables>(RenameGroupDocument);
};