import React, { FC } from 'react';
import { LoanList } from 'components/MyLoansPage/MyLoansPage';

export const Home: FC = () => {
  // return (
  //   <PageTransition>
  //     <LoanList />
  //   </PageTransition>
  // );
  return <LoanList />;
};
