import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetLoanDetailsQueryVariables = {
  loanId: Types.Scalars['uuid']
};


export type GetLoanDetailsQuery = (
  { __typename?: 'query_root' }
  & { loans_by_pk: Types.Maybe<(
    { __typename?: 'loans' }
    & Pick<Types.Loans, 'id' | 'first_payment_date' | 'contract_date' | 'customer_name' | 'customer_id' | 'fees' | 'last_payment_fee' | 'reference' | 'amount' | 'apr' | 'is_approved' | 'is_declined' | 'term_months' | 'type' | 'custom_type_name' | 'early_settlement_penalty_months_interest'>
    & { customer: (
      { __typename?: 'customers' }
      & Pick<Types.Customers, 'name'>
    ), payments: Array<(
      { __typename?: 'payments' }
      & Pick<Types.Payments, 'id' | 'payment_due_date' | 'loan_id' | 'customer_id' | 'reconciled' | 'amount' | 'split_principal' | 'split_interest' | 'split_fee'>
    )>, messages_sents: Array<(
      { __typename?: 'messages_sent' }
      & Pick<Types.Messages_Sent, 'id' | 'message_content' | 'sent_at'>
      & { message_template: (
        { __typename?: 'message_templates' }
        & Pick<Types.Message_Templates, 'template_type_id'>
      ), user: (
        { __typename?: 'users' }
        & Pick<Types.Users, 'name'>
      ) }
    )> }
  )> }
);


export const GetLoanDetailsDocument = gql`
    query GetLoanDetails($loanId: uuid!) {
  loans_by_pk(id: $loanId) {
    id
    first_payment_date
    contract_date
    customer_name
    customer_id
    customer {
      name
    }
    fees
    last_payment_fee
    reference
    amount
    apr
    is_approved
    is_declined
    term_months
    type
    custom_type_name
    early_settlement_penalty_months_interest
    payments(order_by: {payment_due_date: asc}) {
      id
      payment_due_date
      loan_id
      customer_id
      reconciled
      amount
      split_principal
      split_interest
      split_fee
    }
    messages_sents(order_by: {sent_at: desc}) {
      id
      message_content
      message_template {
        template_type_id
      }
      user {
        name
      }
      sent_at
    }
  }
}
    `;

export function useGetLoanDetailsQuery(options: Omit<Urql.UseQueryArgs<GetLoanDetailsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetLoanDetailsQuery>({ query: GetLoanDetailsDocument, ...options });
};