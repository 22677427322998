import React, { FC } from 'react';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { useAuthWrapperContext } from 'toolbox/src/components/AuthWrapper/AuthWrapper';

import Auth from '@aws-amplify/auth';

import css from './LogoutButton.module.css';

export const LogoutButton: FC = ({ ...props }) => {
  const authContext = useAuthWrapperContext();

  return (
    <ButtonBase
      className={css.LogoutButton}
      onClick={() =>
        Auth.signOut().then(() => {
          authContext.refreshAuthState();
        })
      }
      {...props}
    >
      Logout
    </ButtonBase>
  );
};
