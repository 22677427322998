import { makeMutationForm } from 'components/makeMutationForm';
import { Loans } from 'graphql/schemaTypes.generated';

export const NewLoanForm = makeMutationForm<Partial<Loans>>({
  config: {
    customer_id: {
      type: 'hidden',
    },
    email_address: {
      type: 'hidden',
    },
    customer_name: {
      type: 'hidden',
    },
    amount: {
      type: 'number',
    },
    term_months: {
      type: 'number',
    },
    type: {
      type: 'radioGroup',
    },
  },
  showErrorsOnTouched: true,
  validate: (values) => {
    if (!values?.amount || isNaN(values?.amount))
      return { amount: 'You must specify an amount' };
    if (values?.amount <= 0)
      return { amount: 'Amount must be greater than zero' };
    if (values?.amount > 25000)
      return { amount: 'Amount must be less than 25,000' };
    if (!values?.term_months || isNaN(values?.term_months))
      return { term_months: 'You must specify a term' };
    if (values?.term_months > 60)
      return { term_months: 'Term must be 60 months or less' };
    if (values?.term_months < 1)
      return { term_months: 'Term must be at least 1 month' };
    // values.amount = Math.round(values.amount);
    if (!isNaN(values.term_months) && values.term_months > 0)
      values.term_months = Math.round(values.term_months);
  },
});
