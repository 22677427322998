import * as Types from '../schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertUserDeviceMutationVariables = {
  object: Types.User_Devices_Insert_Input
};


export type UpsertUserDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_devices_one: Types.Maybe<(
    { __typename?: 'user_devices' }
    & Pick<Types.User_Devices, 'updated_at'>
  )> }
);


export const UpsertUserDeviceDocument = gql`
    mutation UpsertUserDevice($object: user_devices_insert_input!) {
  insert_user_devices_one(object: $object, on_conflict: {constraint: user_devices_pkey, update_columns: [token, channel_type, os_version]}) {
    updated_at
  }
}
    `;

export function useUpsertUserDeviceMutation() {
  return Urql.useMutation<UpsertUserDeviceMutation, UpsertUserDeviceMutationVariables>(UpsertUserDeviceDocument);
};