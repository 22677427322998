import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import React from 'react';
import { AwsAuthComponents } from 'toolbox';
import Stack from 'toolbox/src/components/Stack/Stack';

import { CodeForm } from './Forms';

export const ConfirmSignIn: AwsAuthComponents['confirmSignIn'] = ({
  confirmSignIn,
  apiState,
  goToSignIn,
  challengeType,
}) => {
  let title = 'Confirm Sign In';
  let description = '';
  if (challengeType === 'SMS_MFA') {
    title = 'Sign In Via SMS';
    description = `We need to confirm your details, so we've just sent you a code to log in via SMS.`;
  } else if (challengeType === 'SOFTWARE_TOKEN_MFA') {
    title = 'Sign In With Authenticator App';
    description = `Please confirm your identity by entering a code from your authenticator app`;
  }
  return (
    <div className="w-full max-w-sm p-5 bg-white">
      <CodeForm.Wrapper onSubmit={confirmSignIn}>
        <Stack className="rounded-lg">
          <h2 className="text-xl font-semibold text-center text-gray-700">
            {title}
          </h2>
          {description && (
            <p className="text-sm text-gray-600">{description}</p>
          )}
          <CodeForm.Inputs.code label="Code" autoComplete="off" />

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <ButtonBase type="button" onClick={goToSignIn}>
              Back To Sign In
            </ButtonBase>
            <ButtonBase type="submit" loading={apiState.type === 'loading'}>
              Confirm Code
            </ButtonBase>
          </div>
          {apiState.type === 'error' && (
            <div className="font-semibold text-center">
              <p className="text-red-600">{apiState.errorMessage}</p>
            </div>
          )}
        </Stack>
      </CodeForm.Wrapper>
    </div>
  );
};
