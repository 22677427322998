import classNames from 'classnames';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import css from 'components/ModalBase/Modal.module.css';
import { ModalTopBar } from 'components/ModalBase/ModalTopBar/ModalTopBar';
import { Overlay } from 'components/Overlay/Overlay';
import dayjs from 'dayjs';
import { motion, useReducedMotion } from 'framer-motion';
import {
  AnnotationOutline,
  CheckOutline,
  InformationCircleOutline,
} from 'heroicons-react';
import React, { useEffect, FC } from 'react';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import { useMarkMessagesAsReadMutation } from './graphql/MarkMessagesAsRead.generated';
import { useGetUserMessagesQuery } from './graphql/GetMessages.generated';
import { useWrappedUrqlMutation } from 'toolbox';

interface IMessageModal {
  closeHandler?: () => void;
}

export const MessageModal: FC<IMessageModal> = ({ closeHandler }) => {
  const { user, refreshUserData } = useLoggedInUser();
  const reducedMotion = useReducedMotion();

  const [{ data }, reload] = useGetUserMessagesQuery({
    variables: {
      userId: user?.cognito_sub as string,
    },
    pause: !user,
    // pollInterval: 1000,
  });

  const markMessagesAsRead = useWrappedUrqlMutation(
    useMarkMessagesAsReadMutation,
    {
      onSuccess: (res) => {
        refreshUserData();
      },
      onError: (e) => {},
    },
  );

  const messages = data?.messages || [];
  const unread = data?.unread.aggregate?.count || 0;

  return (
    <div className={css.Modal}>
      <Overlay>
        <ModalTopBar
          title="Messages"
          buttons={
            <div className="flex space-x-2">
              <ButtonBase
                onClick={() =>
                  user?.cognito_sub &&
                  markMessagesAsRead
                    .dispatch({
                      objects: messages
                        .filter((m) => m.is_read == false)
                        .map((m) => ({
                          message_id: m.id,
                          user_cognito_sub: user.cognito_sub,
                          type: 'app',
                        })),
                    })
                    .then(() => reload())
                }
                className="text-white bg-gnavy-700 btn"
                disabled={unread < 1}
              >
                Mark All As Read
              </ButtonBase>
              <ButtonBase
                onClick={closeHandler}
                className="text-white bg-teal-400 btn"
              >
                Close
              </ButtonBase>
            </div>
          }
        />
        <div className={css.CardPadding}>
          <motion.div
            className={classNames(css.Card)}
            initial={
              reducedMotion
                ? { opacity: 1, scale: 1 }
                : { opacity: 0, scale: 0 }
            }
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 200 }}
          >
            <section className="grid gap-3 rounded-lg bg-gradient-to-t from-gblue-700 to-gblue-500">
              <h2 className="flex items-center text-xl font-semibold text-gblue-100">
                Messages
              </h2>
              {messages.map(
                (
                  { id, content, is_read, subject, created_at, type },
                  index,
                ) => {
                  const personalisedContent = user?.name
                    ? content
                        .replace('{{name}}', user.name)
                        .replace(
                          '{{firstname}}',
                          user.name.split(' ')?.slice(0, -1)?.join(' ') ||
                            user.name,
                        )
                    : content;

                  return (
                    <div
                      className={`p-4 ${
                        type === 'custom_message' ? 'bg-white' : 'bg-gray-100'
                      } rounded-md`}
                      key={index}
                    >
                      <h2 className="flex items-center text-xl">
                        <span className="mr-2 flex items-center">
                          {type === 'payment_received' ? (
                            <CheckOutline
                              path={undefined}
                              className="inline w-5 h-5"
                            />
                          ) : type === 'loan_complete' ? (
                            <InformationCircleOutline
                              path={undefined}
                              className="inline w-5 h-5"
                            />
                          ) : (
                            // type must be custom_message
                            <AnnotationOutline
                              path={undefined}
                              className="inline w-5 h-5"
                            />
                          )}
                        </span>
                        {subject}
                        <div className="flex-grow" />
                        {is_read ? undefined : (
                          <div
                            className="bg-red-500 mr-2 rounded-full w-4 h-4 cursor-pointer"
                            onClick={() =>
                              user?.cognito_sub &&
                              markMessagesAsRead
                                .dispatch({
                                  objects: [
                                    {
                                      message_id: id,
                                      user_cognito_sub: user.cognito_sub,
                                      type: 'app',
                                    },
                                  ],
                                })
                                .then(() => reload())
                            }
                          />
                        )}
                      </h2>
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: personalisedContent as string,
                        }}
                        className="py-2 font-sans"
                      />
                      <p className="mt-4 text-gray-600 text-xs">
                        <span className="bg-gray-200 py-1 px-2 rounded">
                          {dayjs(created_at).format('DD MMM YYYY')}
                        </span>

                        <span className="ml-3">
                          <span className="bg-gray-300 py-1 px-2 rounded-l">
                            to:
                          </span>
                          <span className="bg-gray-200 py-1 px-2 rounded-r">
                            {user?.email}
                          </span>
                        </span>
                      </p>
                    </div>
                  );
                },
              )}
            </section>
          </motion.div>
        </div>
      </Overlay>
    </div>
  );
};
