import React, { useState, useEffect, FC } from 'react';
import { useGetTemplateByIdQuery } from './graphql/GetTemplateById.generated';
import { useWrappedUrqlMutation } from 'toolbox';
import { useUpsertMessageTemplateMutation } from './graphql/UpsertMessageTemplate.generated';
import { useArchiveMessageTemplateByIdMutation } from './graphql/ArchiveMessageTemplateById.generated';
import { useGetMessageTemplateTypesQuery } from './graphql/GetMessageTemplateTypes.generated';
import { useActiveMessageTemplateMutation } from './graphql/ActiveMessageTemplate.generated';
import { usePublishMessageTemplateMutation } from './graphql/PublishMessageTemplate.generated';
import { GetTemplatesQuery } from './graphql/GetTemplates.generated';
import { EditMessageTemplateForm } from './editMessageTemplateForm';
// import { toast } from 'react-toastify';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import {
  Message_Templates,
  Message_Templates_Pk_Columns_Input,
} from 'graphql/schemaTypes.generated';
import type { Messages_Sent_Insert_Input } from 'graphql/schemaTypes.generated';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'routeMap';
import { useParamsAndSearch } from 'routeMap';
import { useGetUsersQuery } from 'components/Admin/ManageUsers/graphql/GetUsers.generated';
import { useInsertMessageTemplateMutation } from './graphql/InsertMessageTemplate.generated';
import { useUpdateMessageTemplateMutation } from './graphql/UpdateMessageTemplate.generated';

export const EditMessageTemplate: FC = () => {
  const { params } = useParamsAndSearch('adminMessageTemplateById');
  const templateId = params?.id;

  const isNewTemplate = templateId === 'new';

  const [q] = useGetTemplateByIdQuery({
    variables: { id: templateId },
    pause:
      isNewTemplate ||
      (typeof templateId === 'string' && templateId?.length !== 36),
  });

  const [messageTemplateTypes] = useGetMessageTemplateTypesQuery({});
  const [users] = useGetUsersQuery();
  const usersLength = users.data?.users.length!;
  const emptyValues = { to: users.data?.users.map((user) => user.cognito_sub) };
  const navigate = useNavigate();

  const insertTemplate = useWrappedUrqlMutation(
    useInsertMessageTemplateMutation,
    {
      onSuccess: (res) => {
        // toast.success('Update message template successfully');
        navigate.adminMessageTemplateById({
          params: {
            id: res?.insert_message_templates_one?.id,
          },
        });
      },
      onError: (e) => {
        // toast.error('Could not update message template');
      },
    },
  );
  const updateTemplate = useWrappedUrqlMutation(
    useUpdateMessageTemplateMutation,
    {
      onSuccess: (res) => {
        // toast.success('Update message template successfully');
      },
      onError: (e) => {
        // toast.error('Could not update message template');
      },
    },
  );

  const archiveMessageTemplate = useWrappedUrqlMutation(
    useArchiveMessageTemplateByIdMutation,
    {
      onSuccess: (res) => {
        // toast.success('Deleted message template successfully');
      },
      onError: (e) => {
        // toast.error('Could not delete message template');
      },
    },
  );

  const activeMessageTemplate = useWrappedUrqlMutation(
    useActiveMessageTemplateMutation,
    {
      onSuccess: (res) => {
        // toast.success('Activated message template successfully');
      },
      onError: (e) => {
        // toast.error('Couldn not active message template');
      },
    },
  );

  const publishMessageTemplate = useWrappedUrqlMutation(
    usePublishMessageTemplateMutation,
    {
      onSuccess: (res) => {
        // toast.success('Published message successfully');
      },
      onError: (e) => {
        // toast.error('Could not publish message');
      },
    },
  );

  if (q.fetching) return <section>Fetching message template...</section>;
  if (q.error) return <section>Error: {q.error?.message}</section>;

  const template = q.data?.message_templates_by_pk;

  if (templateId !== 'new' && !template)
    return (
      <section>Error: could not find message with id: {templateId}</section>
    );

  const save = async (
    formValues: Partial<Message_Templates> & {
      to?: string[];
    },
  ) => {
    try {
      isNewTemplate
        ? insertTemplate.dispatch({
            object: {
              id: formValues?.id,
              template_title: formValues?.template_title,
              template_body: formValues?.template_body
                ?.split('\n')
                .join('<br>'),
              template_type_id: formValues?.template_type_id,
              is_active: formValues?.is_active,
              is_archived: formValues?.is_archived,
              messages_sents: {
                data:
                  formValues.to?.length === usersLength
                    ? []
                    : (formValues.to?.map((to) => ({
                        user_id: to,
                      })) as Messages_Sent_Insert_Input[]),
              },
            },
          })
        : updateTemplate.dispatch({
            object: {
              id: formValues?.id,
              template_title: formValues?.template_title,
              template_body: formValues?.template_body
                ?.split('\n')
                .join('<br>'),
              template_type_id: formValues?.template_type_id,
              is_active: formValues?.is_active,
              is_archived: formValues?.is_archived,
            },
            templateId: params?.id!,
            messagesSentTemplateId: params?.id!,
            messagesSentInsertInput:
              formValues.to?.length === usersLength
                ? []
                : (formValues.to?.map((to) => ({
                    user_id: to,
                    message_template_id: params?.id,
                  })) as Messages_Sent_Insert_Input[]),
          });
    } catch (e) {
      // toast.error('Could not add/update rate card');
      Sentry.captureException(e);
    }
  };

  const remove = () => {
    if (
      window.confirm('Are you sure you want to delete this template?') &&
      templateId
    ) {
      archiveMessageTemplate.dispatch({ id: templateId });
      setImmediate(() => navigate.adminMessageTemplates());
    }
  };

  return (
    <section className="self-stretch">
      <h3 className="p-3 pb-3 bg-white rounded-t">
        {isNewTemplate
          ? `New Template`
          : template?.message_template_type?.title}{' '}
        {!isNewTemplate && template?.is_active ? (
          <span className="px-2 py-1 ml-2 font-semibold text-red-800 bg-red-200 rounded text-2xs">
            <div
              title="Active"
              className="inline-block w-2 h-2 mr-2 bg-red-800 border-2 border-red-800 rounded-full"
            />
            {template?.template_type_id === 'promo_message'
              ? 'PUBLISHED'
              : 'ACTIVE TEMPLATE'}
          </span>
        ) : (
          <span className="px-2 py-1 ml-2 font-semibold text-gray-700 bg-gray-200 rounded text-2xs">
            DRAFT
          </span>
        )}
      </h3>
      <EditMessageTemplateForm.Wrapper
        onSubmit={save}
        initialValues={
          isNewTemplate
            ? emptyValues
            : {
                id: template?.id,
                template_title: template?.template_title,
                template_body: template?.template_body,
                template_type_id: template?.template_type_id,
                is_active: template?.is_active,
                is_archived: template?.is_archived,
                // if message is sent to all, we don't need to
                // specify the recipients, so we get an empty array,
                // then we set all the users in the form `to` field
                to: Boolean(
                  q.data?.message_templates_by_pk?.messages_sents.map(
                    (sent) => sent.user_id,
                  ).length,
                )
                  ? q.data?.message_templates_by_pk?.messages_sents.map(
                      (sent) => sent.user_id,
                    )
                  : users.data?.users.map((user) => user.cognito_sub),
              }
        }
      >
        <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
          <EditMessageTemplateForm.Consumer>
            {({ values }) => (
              <div className="flex justify-end p-2 space-x-4 ">
                {isNewTemplate ? (
                  <ButtonBase
                    className="p-2 px-4 text-sm font-semibold text-gray-500 bg-white rounded-md shadow-none btn hover:text-gray-800 hover:bg-gray-200 hover:shadow-md"
                    onClick={() => navigate.adminMessageTemplates()}
                  >
                    Cancel
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    className="p-2 px-4 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
                    onClick={remove}
                  >
                    Delete
                  </ButtonBase>
                )}
                <SubmitButton className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-200">
                  Save
                </SubmitButton>
                {!template?.is_active ? (
                  <ButtonBase
                    className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-800 disabled:bg-gray-200"
                    onClick={() =>
                      template?.template_type_id === 'promo_message'
                        ? publishMessageTemplate.dispatch({
                            id: template?.id,
                          })
                        : activeMessageTemplate.dispatch({
                            id: template?.id,
                            templateTypeId: template?.template_type_id as string,
                          })
                    }
                    disabled={isNewTemplate || template?.is_active}
                  >
                    {values.template_type_id !== 'promo_message'
                      ? template?.is_active
                        ? 'This template is active'
                        : 'Activate template'
                      : null}
                    {values.template_type_id === 'promo_message'
                      ? template?.is_active
                        ? 'Published'
                        : 'Publish'
                      : null}
                  </ButtonBase>
                ) : undefined}
              </div>
            )}
          </EditMessageTemplateForm.Consumer>
        </div>
        <div className="grid grid-cols-6 gap-3 p-3 pb-10 bg-white rounded-b">
          <EditMessageTemplateForm.Inputs.template_title
            labelText="Title"
            className="col-span-3 col-start-1"
          />
          {isNewTemplate ? (
            <EditMessageTemplateForm.Inputs.template_type_id
              id="template_type_id"
              name="template_type_id"
              className="col-span-3 col-start-1"
              labelText="Template Type"
              options={
                messageTemplateTypes.data?.message_template_types?.map(
                  (type) => ({
                    value: type?.id,
                    labelText: `${type.title} ${
                      type.description ? `(${type.description})` : ''
                    }`,
                    hintText: '',
                  }),
                ) || []
              }
            />
          ) : undefined}

          <EditMessageTemplateForm.Consumer>
            {({ values }) => {
              const usersCount = users.data?.users.length!;
              if (values.template_type_id === 'promo_message') {
                return (
                  <EditMessageTemplateForm.Inputs.to
                    labelText={`${isNewTemplate ? 'To' : 'Sent to'}`}
                    name="to"
                    options={users.data?.users
                      .sort((a, b) =>
                        (a?.name ?? '') > (b?.name ?? '') ? 1 : -1,
                      )
                      .map((user) => ({
                        value: user.cognito_sub,
                        labelText: `${user.name} (${user.email})`,
                      }))}
                    enableSelectAll={true}
                    selectAllLabel={`All (${usersCount}) users`}
                    className="col-span-3 col-start-1"
                  />
                );
              } else {
                return null;
              }
            }}
          </EditMessageTemplateForm.Consumer>

          <EditMessageTemplateForm.Inputs.template_body
            labelText="Content"
            className="col-span-3 col-start-1"
          />
          <EditMessageTemplateForm.Consumer>
            {({ values }) => (
              <div className="col-span-3 col-start-1 text-xs">
                {values.template_type_id === 'payment_received' ? (
                  <div>
                    Content Hint: shortcodes{' '}
                    <ShortCodes
                      codes={[
                        'user_name',
                        'customer_name',
                        'loan_reference',
                        'loan_amount',
                        'payment_amount',
                        'payment_due_date',
                      ]}
                    />{' '}
                    are available for dynamic content.
                  </div>
                ) : null}
                {values.template_type_id === 'loan_completed' ? (
                  <div>
                    Content Hint: shortcodes{' '}
                    <ShortCodes
                      codes={[
                        'user_name',
                        'customer_name',
                        'loan_reference',
                        'loan_amount',
                      ]}
                    />{' '}
                    are available for dynamic content.
                  </div>
                ) : null}
              </div>
            )}
          </EditMessageTemplateForm.Consumer>
        </div>
      </EditMessageTemplateForm.Wrapper>
    </section>
  );
};

interface ShortCodesProps {
  codes: Array<string>;
}
const ShortCodes: FC<ShortCodesProps> = ({ codes }) => (
  <span className="inline-flex space-x-2">
    {codes.map((code, i, { length }) => (
      <ShortCode key={i}>{code}</ShortCode>
    ))}
  </span>
);

const ShortCode: FC = ({ children }) => (
  <code className="text-teal-800 bg-gray-100">
    &#123;&#123;{children}&#125;&#125;
  </code>
);
