/*
Calculate Loan Payments and Costs
Reference: https://www.thebalance.com/loan-payment-calculations-315564
Verified against: https://keisan.casio.com/exec/system/1235645799
*/

/* Periodic Interest Rate */
const PIR = (APR: number) => APR / 12;

/* Discount Factor */
const D = (APR: number, n: number) =>
  (Math.pow(1 + PIR(APR), n) - 1) / (Math.pow(1 + PIR(APR), n) * PIR(APR));

/* Monthly Periodic Payment */
export const repayment = (principal: number, APR: number, n: number) =>
  principal / D(APR / 100, n);

/* Compound Interest */
// const totalInterest = (principal: number, APR: number, n: number) =>
//   repayment(principal, APR, n) * n - principal;
