import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertCustomerMutationVariables = {
  object: Types.Customers_Insert_Input
};


export type UpsertCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { insert_customers_one: Types.Maybe<(
    { __typename?: 'customers' }
    & Pick<Types.Customers, 'business_unit_id' | 'date_established' | 'date_incorporated' | 'id' | 'individual' | 'loan_fee' | 'name' | 'nature_of_business' | 'place_of_incorporation' | 'rate_id'>
  )> }
);


export const UpsertCustomerDocument = gql`
    mutation UpsertCustomer($object: customers_insert_input!) {
  insert_customers_one(object: $object, on_conflict: {constraint: customers_pkey, update_columns: [business_unit_id, date_established, date_incorporated, id, individual, loan_fee, name, nature_of_business, place_of_incorporation, rate_id]}) {
    business_unit_id
    date_established
    date_incorporated
    id
    individual
    loan_fee
    name
    nature_of_business
    place_of_incorporation
    rate_id
  }
}
    `;

export function useUpsertCustomerMutation() {
  return Urql.useMutation<UpsertCustomerMutation, UpsertCustomerMutationVariables>(UpsertCustomerDocument);
};