import { makeMutationForm } from 'components/makeMutationForm';
import { Message_Templates } from 'graphql/schemaTypes.generated';

export const EditMessageTemplateForm = makeMutationForm<
  Partial<Message_Templates> & { to?: string[] }
>({
  config: {
    id: {
      type: 'text',
    },
    template_title: { type: 'text' },
    template_body: { type: 'textarea' },
    template_type_id: { type: 'radioGroup' },
    to: { type: 'checkboxGroup' },
    is_active: { type: 'checkbox' },
    is_archived: { type: 'checkbox' },
  },
  showErrorsOnTouched: true,
  validate: ({
    id,
    template_title,
    template_body,
    template_type_id,
    to,
    is_active,
    is_archived,
  }) => {
    if (!template_title) {
      return {
        id: 'You must specify a template title',
      };
    }
    if (!template_body) {
      return {
        id: 'You must specify a template body',
      };
    }
    if (!template_type_id) {
      return {
        id: 'You must specify a template type',
      };
    }
    if (to?.length === 0) {
      return {
        id: 'You must specify at least one user to send',
      };
    }
  },
});
