import { Input } from 'components/Inputs/Input';
import { Textarea } from 'components/Inputs/Textarea';
import { RadioGroup } from 'components/Inputs/RadioGroup';
import { CheckboxGroup } from 'components/Inputs/Checkbox/CheckboxGroup';
import { Select } from 'components/Inputs/SelectDropdown/Select';
import { ToggleSwitch } from 'components/Inputs/ToggleSwitch/ToggleSwitch';
import React from 'react';
import { declareMakeMutationForm } from 'toolbox';
import { SelectBaseProps } from 'toolbox/src/components/SelectBase/SelectBase';

export const makeMutationForm = declareMakeMutationForm({
  inputs: {
    hidden: () => null,
    text: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="text" {...props} />,
    textarea: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Textarea {...props} />,
    email: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="email" {...props} />,
    number: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="number" {...props} />,
    password: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="password" {...props} />,
    date: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="date" {...props} />,
    time: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => <Input type="time" {...props} />,
    checkbox: ({
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => {
      return <ToggleSwitch checked={Boolean(props.value)} {...props} />;
    },
    checkboxGroup: ({
      value,
      options,
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => {
      return <CheckboxGroup value={value || []} options={options} {...props} />;
    },
    radioGroup: ({
      value,
      options,
      initialError,
      initialValue,
      initialTouched,
      touched,
      ...props
    }) => {
      return (
        <>
          <RadioGroup
            value={(value as string) || ''}
            options={options}
            {...props}
          />
        </>
      );
    },
    select: ({
      value,
      initialError,
      initialValue,
      initialTouched,
      touched,
      name,
      options,
      ...props
    }: SelectBaseProps) => {
      return (
        <Select
          // options={props.options || []}
          // valueAccessor={(({ value }: any) => value) as any}
          // labelAccessor={(({ label }: any) => label) as any}
          value={value as string}
          name={name}
          options={options}
          {...props}
        />
      );
    },
  },
});
