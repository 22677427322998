import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as CurveTopSvg } from 'assets/curve-top.svg';
import { AccountSwitcherModalButton } from 'components/AccountSwitcherModal/AccountSwitcherModalButton';
import { AdminMenuButton } from 'components/Admin/AdminMenuButton';
import { Authenticator } from 'components/Auth/Authenticator';
import { GouldsonLogo } from 'components/GouldsonLogo/GouldsonLogo';
import GreetingText from 'components/GreetingText/GreetingText';
import LoadingDots from 'components/Loading/LoadingDots';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import { LogoutButton } from 'components/LogoutButton/LogoutButton';
import { UsernameText } from 'components/UsernameText/UsernameText';
import { NotFoundPage } from 'pages/404';
import { Home } from 'pages/Home';
import { Admin } from 'pages/Admin';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import { routeMap, useNavigate } from 'routeMap';
import * as Sentry from '@sentry/react';

import AuthWrapper from 'toolbox/src/components/AuthWrapper/AuthWrapper';
import { useAuthWrapperContext } from 'toolbox/src/components/AuthWrapper/AuthWrapper';
import LoggedInUserProvider from 'components/LoggedInUserProvider/LoggedInUserProvider';

import { Amplify } from '@aws-amplify/core';
import awsconfig from './aws-exports';
import { ToastNotificationContainer } from 'components/ToastNotification/ToastNotification';
import { UrqlWrapperWithAuth } from 'components/UrqlWrapper';
import { useWrappedUrqlMutation } from 'toolbox';
import { useUpsertUserDeviceMutation } from './graphql/mutations/UpsertUserDevice.generated';

import { ServiceWorkerAlertBar } from 'components/ServiceWorkerAlertBar';
import { MyDetailsModalButton } from 'components/MyDetailsModal/MyDetailsModalButton';
import { MessageButton } from 'components/MessageButton/MessageButton';
import { AutoLogout } from 'AutoLogout';

Amplify.configure({ ...awsconfig, ssr: false });

const useFixMobileHeight = () => {
  useEffect(() => {
    const onResize = () => {
      try {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
};

const App: FC = () => {
  useFixMobileHeight();

  return (
    <BrowserRouter>
      <AuthWrapper fallback={null}>
        <UrqlWrapperWithAuth>
          <LoggedInUserProvider
          /* 
              Cognito issues an idToken that is valid for 60 minutes.
              We need this token to authenticate the Urql graphql queries sent to Hasura.
              Here we specify that a new idToken should be fetched every 50 minutes.
              Auth.currentAuthenticatedUser (from @aws-amplify/auth) uses the refreshToken (which was also issued by Cognito at signIn) to do this where required.
              */
          // autoRefreshIdTokenInterval={3000000}
          >
            <AutoLogout>
              <Switch>
                <Route exact path={routeMap.authenticator()}>
                  <Authenticator />
                </Route>

                <ProtectedAuthRouteWrapper>
                  <div className="flex flex-col w-screen h-screen overflow-x-hidden">
                    <Switch>
                      <Route path={routeMap.admin()}>
                        <Admin />
                      </Route>
                      <Route
                        path={[
                          routeMap.loans(),
                          '/mydetails',
                          '/switchaccount',
                        ]}
                      >
                        <AppHeader />
                        <div className="z-10 flex flex-col flex-grow flex-shrink-0 pt-8 md:pt-12">
                          <CurveTopSvg
                            className="flex-shrink-0 w-full h-20 fill-current text-gnavy-700 md:hidden"
                            style={{ marginBottom: '-1px' }}
                          />
                          <div
                            className="flex items-start justify-center flex-grow"
                            style={{
                              background:
                                'linear-gradient(0deg, #162648 0%, #20679D 100%)',
                            }}
                          >
                            <div className="w-full h-full max-w-5xl -mt-32 md:-mt-16">
                              <Home />
                            </div>
                          </div>
                        </div>
                      </Route>
                      <Route exact path={routeMap.root()}>
                        <Redirect to="/loans" />
                      </Route>
                    </Switch>
                  </div>
                </ProtectedAuthRouteWrapper>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </AutoLogout>
          </LoggedInUserProvider>
          <ServiceWorkerAlertBar />
        </UrqlWrapperWithAuth>
      </AuthWrapper>
      <ToastNotificationContainer />
    </BrowserRouter>
  );
};

const ProtectedAuthRouteWrapper: FC = ({ children }) => {
  const { status, refreshAuthState } = useAuthWrapperContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'loggedOut') {
      console.log(
        'detected unauthenticated user, redirecting to authenticator screen',
      );
      navigate.authenticator();
    }
  }, [status]);

  return <>{children}</>;
};

const TopSection = () => {
  const auth = useAuthWrapperContext();
  const { newMessageCount } = useLoggedInUser();

  return (
    <div className="flex items-center justify-between w-full max-w-5xl p-3 pb-6 mx-auto">
      <GouldsonLogo />
      <div className="flex flex-col items-stretch p-2 pt-1 rounded-lg bg-frost-100">
        <UsernameText />
        <div className="flex items-stretch justify-end">
          {auth?.groups?.includes('Admins') && <AdminMenuButton />}
          <MessageButton newMessageCount={newMessageCount} />
          <MyDetailsModalButton />
          <LogoutButton />
        </div>
      </div>
    </div>
  );
};

const AppHeader: FC = () => {
  const { user } = useLoggedInUser();

  const upsertUserDevice = useWrappedUrqlMutation(useUpsertUserDeviceMutation, {
    onSuccess: (res) =>
      console.log(
        'registered device for push notifications',
        JSON.stringify(res),
      ),
    onError: (e) => {
      const error = e instanceof Error ? e.message : JSON.stringify(e);
      console.warn('failed to register device for push notifications', error);
      Sentry.captureException(e);
    },
  });

  if (!user)
    return (
      <div className="flex-shrink-0">
        <TopSection />
        <div className="flex justify-center w-screen min-w-full text-lg font-semibold text-white">
          <div>
            Loading <LoadingDots className="w-2 h-2 mr-1 bg-white" />
          </div>
        </div>
      </div>
    );

  if (user.customers?.length < 1)
    return (
      <div className="flex-shrink-0">
        <TopSection />
        <div className="col-span-6 mt-12 text-center text-gray-100">
          You do not have any customer accounts assigned to your app login.
          <br />
          Email <a href="mailto:carl@gouldson.co.uk">carl@gouldson.co.uk</a> for
          support
        </div>
      </div>
    );

  return (
    <div className="flex-shrink-0">
      <TopSection />
      <div className="z-10 grid w-full max-w-5xl grid-cols-6 gap-2 px-3 pb-10 mx-auto">
        <GreetingText name={user?.name || ''} />
        <AccountSwitcherModalButton
          accountName={
            user?.selected_customer?.name ||
            user?.customers?.[0]?.customer?.name ||
            ''
          }
          isPersonalAccount={user?.selected_customer?.individual || false}
        />
      </div>
    </div>
  );
};

export default App;
