import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { ModalToggleLink } from 'components/ModalBase/ModalToggleLink/ModalToggleLink';
import React, { FC } from 'react';
import { MyDetailsModal } from './MyDetailsModal';
import { UserOutline } from 'heroicons-react';
import { useHistory } from 'react-router-dom';

export const MyDetailsModalButton: FC = () => {
  const history = useHistory();

  return (
    <ModalToggleLink
      path="/mydetails"
      exact
      modal={
        <MyDetailsModal
          closeHandler={() => {
            history.push('/loans');
          }}
        />
      }
    >
      <ButtonBase
        onClick={() => {
          history.push('/loans/mydetails');
        }}
        className="flex items-center px-1 py-1 ml-1 text-xs truncate shadow-none bg-gnavy-100 text-gnavy-700 sm:text-sm sm:px-2"
      >
        <UserOutline
          path={undefined}
          className="flex-shrink-0 w-3 h-3 mb-px mr-1 sm:w-4 sm:h-4"
        />
        My Details
      </ButtonBase>
    </ModalToggleLink>
  );
};
