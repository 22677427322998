import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetSettingsQueryVariables = {};


export type GetSettingsQuery = (
  { __typename?: 'query_root' }
  & { settings: Array<(
    { __typename?: 'settings' }
    & Pick<Types.Settings, 'description' | 'id' | 'value'>
  )> }
);


export const GetSettingsDocument = gql`
    query GetSettings {
  settings {
    description
    id
    value
  }
}
    `;

export function useGetSettingsQuery(options: Omit<Urql.UseQueryArgs<GetSettingsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetSettingsQuery>({ query: GetSettingsDocument, ...options });
};