import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import React, { useState } from 'react';
import { AwsAuthComponents } from 'toolbox';
import Stack from 'toolbox/src/components/Stack/Stack';

import { ForgotPasswordForm, UsernameOnlyForm } from './Forms';

export const ForgotPassword: AwsAuthComponents['forgotPassword'] = ({
  apiState,
  delivery,
  sendCode,
  submitNewPassword,
  goToSignIn,
  codeState,
}) => {
  const [cachedUsername, setCachedUsername] = useState('');
  return (
    <div className="w-full max-w-sm p-5 bg-white">
      <Stack className="rounded-lg">
        <h2 className="text-xl font-semibold text-center text-gray-700">
          Reset Your Password
        </h2>
        {codeState === 'codeSent' && (
          <p className="text-sm text-gray-600">
            A verification code has been sent by {delivery?.DeliveryMedium} to{' '}
            {delivery?.Destination}. Fill out the form below to reset your
            password.
          </p>
        )}
        {codeState === 'yetToSendRequest' && (
          <p className="text-sm text-gray-600">
            We&apos;ll send a one-time code to you to reset your password. Fill
            out the form below to receive it.
          </p>
        )}
        {codeState === 'yetToSendRequest' && (
          <UsernameOnlyForm.Wrapper
            onSubmit={({ username }) => {
              setCachedUsername(username);
              sendCode({ username });
            }}
          >
            <Stack>
              <UsernameOnlyForm.Inputs.username
                autoFocus
                labelText="Username (email)"
              />
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <ButtonBase
                  onClick={goToSignIn}
                  className="justify-center text-xl bg-gnavy-200"
                >
                  Cancel
                </ButtonBase>
                <ButtonBase
                  type="submit"
                  loading={apiState.type === 'loading'}
                  className="justify-center text-xl text-white bg-gnavy-800"
                >
                  Send Code
                </ButtonBase>
              </div>
            </Stack>
          </UsernameOnlyForm.Wrapper>
        )}
        {codeState === 'codeSent' && (
          <ForgotPasswordForm.Wrapper
            onSubmit={submitNewPassword}
            initialValues={{ username: cachedUsername }}
          >
            <Stack>
              <ForgotPasswordForm.Inputs.code
                labelText="Code"
                autoComplete="off"
              />
              <ForgotPasswordForm.Inputs.username labelText="Username" />
              <ForgotPasswordForm.Inputs.password labelText="New Password" />
              <ForgotPasswordForm.Inputs.confirmPassword labelText="Confirm New Password" />
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <ButtonBase
                  type="button"
                  onClick={goToSignIn}
                  className="justify-center text-xl bg-gnavy-200"
                >
                  Cancel
                </ButtonBase>
                <ButtonBase
                  type="submit"
                  loading={apiState.type === 'loading'}
                  className="justify-center text-xl text-white bg-gnavy-800"
                >
                  Submit
                </ButtonBase>
              </div>
            </Stack>
          </ForgotPasswordForm.Wrapper>
        )}
        {apiState.type === 'error' && (
          <div className="font-semibold text-center">
            <p className="text-red-600">{apiState.errorMessage}</p>
          </div>
        )}
      </Stack>
    </div>
  );
};
