import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertUserMutationVariables = {
  object: Types.Users_Insert_Input
};


export type UpsertUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one: Types.Maybe<(
    { __typename?: 'users' }
    & Pick<Types.Users, 'cognito_sub' | 'current_employer' | 'current_occupation' | 'date_of_birth' | 'email' | 'gender' | 'maiden_name' | 'marital_status' | 'name' | 'nationality' | 'number_of_children' | 'phone_number' | 'place_of_birth' | 'previous_employer' | 'previous_occupation' | 'residential_status' | 'selected_customer_id' | 'self_employed' | 'title' | 'years_at_current_employer' | 'years_at_previous_employer' | 'notify_by_email' | 'notify_by_sms' | 'notify_by_app' | 'notify_on_payment_received'>
    & { customers: Array<(
      { __typename?: 'delegates' }
      & Pick<Types.Delegates, 'customer_id' | 'delegate_cognito_user_id' | 'id'>
    )> }
  )> }
);


export const UpsertUserDocument = gql`
    mutation UpsertUser($object: users_insert_input!) {
  insert_users_one(object: $object, on_conflict: {constraint: users_pkey, update_columns: [current_employer, current_occupation, date_of_birth, gender, maiden_name, marital_status, name, nationality, number_of_children, phone_number, place_of_birth, previous_employer, previous_occupation, residential_status, selected_customer_id, self_employed, title, years_at_current_employer, years_at_previous_employer, notify_by_email, notify_by_sms, notify_by_app, notify_on_payment_received]}) {
    cognito_sub
    current_employer
    current_occupation
    date_of_birth
    email
    customers {
      customer_id
      delegate_cognito_user_id
      id
    }
    gender
    maiden_name
    marital_status
    name
    nationality
    number_of_children
    phone_number
    place_of_birth
    previous_employer
    previous_occupation
    residential_status
    selected_customer_id
    self_employed
    title
    years_at_current_employer
    years_at_previous_employer
    notify_by_email
    notify_by_sms
    notify_by_app
    notify_on_payment_received
  }
}
    `;

export function useUpsertUserMutation() {
  return Urql.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument);
};