import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type SetSelectedCustomerMutationVariables = {
  cognito_sub: Types.Scalars['String'],
  selected_customer_id: Types.Scalars['uuid']
};


export type SetSelectedCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { update_users: Types.Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Types.Users, 'cognito_sub' | 'selected_customer_id'>
    )> }
  )> }
);


export const SetSelectedCustomerDocument = gql`
    mutation SetSelectedCustomer($cognito_sub: String!, $selected_customer_id: uuid!) {
  update_users(where: {cognito_sub: {_eq: $cognito_sub}}, _set: {selected_customer_id: $selected_customer_id}) {
    returning {
      cognito_sub
      selected_customer_id
    }
  }
}
    `;

export function useSetSelectedCustomerMutation() {
  return Urql.useMutation<SetSelectedCustomerMutation, SetSelectedCustomerMutationVariables>(SetSelectedCustomerDocument);
};