import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';

import LoadingDots from './LoadingDots';
import css from './LoadingOverlay.module.css';

const LoadingOverlay: React.FC = () => {
  const reducedMotion = useReducedMotion();
  return (
    <motion.div
      className={css.LoadingOverlay}
      initial={reducedMotion ? {} : { opacity: 0 }}
      animate={reducedMotion ? {} : { opacity: 1 }}
      exit={reducedMotion ? {} : { opacity: 0 }}
    >
      <div className={css.LoadingBar}>
        Loading
        <LoadingDots className={css.LoadingDots} />
      </div>
    </motion.div>
  );
};

export default LoadingOverlay;
