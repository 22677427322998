import React, { FC, ChangeEvent } from 'react';
import { RadioChoice } from './RadioChoice';

interface IRadioGroup {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  options: { value: string; labelText: string; hintText?: string }[];
  className?: string;
  labelText?: string;
  small?: boolean;
}

export const RadioGroup: FC<IRadioGroup> = ({
  name,
  options,
  value,
  labelText,
  onChange,
  className,
  small = false,
  ...props
}) => {
  return (
    <label className={className}>
      <div
        className="bg-gray-100 border-2 border-gray-400 rounded focus-within:border-blue-500"
        {...props}
      >
        <div className="p-2 text-xs font-semibold tracking-wide text-gray-700 uppercase border-b border-gray-400">
          {labelText}
        </div>
        <div className="grid grid-flow-row gap-3 p-3 bg-white rounded-b-sm">
          {options?.length > 0 &&
            options?.map(({ value: optionValue, labelText, hintText }) => (
              <RadioChoice
                key={optionValue}
                value={optionValue?.toString()}
                labelText={labelText}
                hintText={hintText}
                checked={String(value) === String(optionValue)}
                onChange={onChange}
                name={name}
                small={small}
              />
            ))}
        </div>
      </div>
    </label>
  );
};

// <RadioChoice
// checked={choice === 'personal'}
// onChange={(e) => setChoice(e.target.value)}
// value="personal"
// labelText="Personal Loan"
// />
// <RadioChoice
// checked={choice === 'hirepurchase'}
// onChange={(e) => setChoice(e.target.value)}
// value="hirepurchase"
// labelText="Hire Purchase Agreement"
// />
