import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useServiceWorker } from 'toolbox';

export const ServiceWorkerAlertBar = () => {
  const { canUpdate, forceUpdate } = useServiceWorker();
  return (
    <>
      <AnimatePresence>
        {canUpdate && (
          <motion.button
            initial={{ y: -56 }}
            animate={{ y: 0 }}
            exit={{ y: -56 }}
            className="fixed top-0 left-0 z-50 block w-full px-8 py-6 bg-red-600 hover:bg-red-700"
            onClick={forceUpdate}
          >
            <p className="font-semibold text-white">
              New updates to this app are available. Click here to update and
              refresh.
            </p>
          </motion.button>
        )}
      </AnimatePresence>
    </>
  );
};
