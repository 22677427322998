import { useFormikContext } from 'formik';
import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { Checkbox } from './Checkbox';

interface ICheckboxGroup {
  name: string;
  enableSelectAll?: boolean;
  selectAllLabel?: string;
  value: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  options: { value: string; labelText: string; hintText?: string }[];
  className?: string;
  labelText?: string;
  small?: boolean;
  extra?: React.ReactNode;
}

export const CheckboxGroup: FC<ICheckboxGroup> = ({
  name,
  options,
  value,
  labelText,
  onChange,
  className,
  extra,
  enableSelectAll = false,
  selectAllLabel = 'Select All',
  small = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [ifSelectAll, setIfSelectAll] = useState<boolean | undefined>(true);

  useEffect(() => {
    if (value?.length === options?.length) {
      setIfSelectAll(true);
    } else {
      setIfSelectAll(false);
    }
  }, [value]);

  return (
    <div className={className}>
      <div
        className="bg-gray-100 border-2 border-gray-400 rounded focus-within:border-blue-500"
        {...props}
      >
        <div className="p-2 text-xs font-semibold tracking-wide text-gray-700 uppercase border-b border-gray-400">
          {labelText}
        </div>
        {enableSelectAll && (
          <div className="flex items-center p-3 space-x-3">
            <ToggleSwitch
              small={true}
              checked={ifSelectAll}
              onChange={() => {
                !ifSelectAll
                  ? setFieldValue(
                      name,
                      options.map(({ value }) => value),
                    )
                  : setFieldValue(name, []);
                setIfSelectAll(!ifSelectAll);
              }}
            />
            <div className="text-xs font-semibold text-gray-700 uppercase">
              {selectAllLabel}
            </div>
          </div>
        )}
        <div
          id="checkboxes"
          className="grid grid-flow-row gap-3 p-3 bg-white rounded-b-sm"
        >
          {options?.length > 0 &&
            options?.map(({ value: optionValue, labelText, hintText }) => (
              <Checkbox
                key={optionValue}
                value={optionValue}
                labelText={labelText}
                hintText={hintText}
                checked={ifSelectAll || value.includes(optionValue)}
                name={name}
                small={small}
                onChange={onChange}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
