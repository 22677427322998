import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertPaymentMutationVariables = {
  object: Types.Payments_Insert_Input
};


export type UpsertPaymentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_payments_one: Types.Maybe<(
    { __typename?: 'payments' }
    & Pick<Types.Payments, 'id' | 'payment_due_date' | 'amount' | 'split_principal' | 'split_fee' | 'split_interest' | 'reconciled' | 'loan_id' | 'customer_id'>
  )> }
);


export const UpsertPaymentDocument = gql`
    mutation UpsertPayment($object: payments_insert_input!) {
  insert_payments_one(object: $object, on_conflict: {constraint: payments_pkey, update_columns: [payment_due_date, amount, split_principal, split_fee, split_interest, reconciled]}) {
    id
    payment_due_date
    amount
    split_principal
    split_fee
    split_interest
    reconciled
    loan_id
    customer_id
  }
}
    `;

export function useUpsertPaymentMutation() {
  return Urql.useMutation<UpsertPaymentMutation, UpsertPaymentMutationVariables>(UpsertPaymentDocument);
};