import React, { FC } from 'react';
import { CheckCircle } from 'heroicons-react';
import ReactTooltip from 'react-tooltip';
import { InformationCircle } from 'heroicons-react';

interface IRadioChoice extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  hintText?: string;
  name: string;
  small?: Boolean;
}

export const RadioChoice: FC<IRadioChoice> = ({
  children,
  labelText,
  hintText,
  name,
  small = false,
  ...props
}) => {
  return (
    <label className="flex items-center text-gray-800 cursor-pointer select-none focus-within:text-blue-500 focus-within:font-semibold focus-within:underline">
      <input
        type="radio"
        className="w-0 h-0 opacity-0"
        name={name}
        {...props}
      />
      <div className="flex items-center justify-center w-5 h-5 p-0 mb-px mr-2 bg-gray-200 border-2 border-gray-500 rounded-full">
        {props.checked && (
          <CheckCircle path={undefined} className="w-6 -m-1 text-blue-500" />
        )}
      </div>
      <span className={small ? 'text-xs w-full' : 'text-sm w-full'}>
        {labelText}
      </span>
      {hintText && (
        <InformationCircle
          path={undefined}
          className="w-4 mx-1 text-gray-400 hover:text-blue-500"
          data-tip={hintText}
        />
      )}
      <ReactTooltip
        effect="solid"
        backgroundColor="black"
        className="max-w-xs"
      />
    </label>
  );
};
