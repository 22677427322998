import React, { FC } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import { ManageCustomers } from './ManageCustomers/ManageCustomers';
import { ManageUsers } from './ManageUsers/ManageUsers';
import { ManageRates } from './ManageRates/ManageRates';
import { ManageMessageTemplates } from './ManageMessageTemplates/ManageMessageTemplates';
import { AdminMenu } from './AdminMenu';
import { ArrowCircleLeftOutline } from 'heroicons-react';
import { ManageLoans } from 'components/Admin/ManageLoans/ManageLoans';
import { routeMap } from 'routeMap';
import { ManageMessages } from './ManageMessages/ManageMessages';
import { ManageBroadcasts } from './ManageBroadcasts/ManageBroadcasts';
import { ManageSettings } from './ManageSettings/ManageSettings';
import { ManageGroups } from './ManageGroups/ManageGroups';

export const AdminPage: FC = () => {
  return (
    <div className="fixed inset-0 flex flex-col max-h-screen">
      <Link to="/">
        <div className="flex items-center justify-start h-10 px-4 text-sm font-semibold bg-transparent text-gnavy-200">
          {/* <span>Admin Settings</span> */}
          <span className="flex items-center">
            <ArrowCircleLeftOutline
              path={undefined}
              className="w-4 h-4 mt-px mr-2"
            />
            Return to App
          </span>
        </div>
      </Link>
      <div className="flex h-full">
        <AdminMenu />
        <Switch>
          <Route path="/admin/users" component={ManageUsers} />
          <Route path="/admin/customers" component={ManageCustomers} />
          <Route path="/admin/loans" component={ManageLoans} />
          <Route path="/admin/rates" component={ManageRates} />
          <Route
            path={routeMap.adminMessageTemplates()}
            component={ManageMessageTemplates}
          />

          <Route path="/admin/messages" component={ManageMessages} />
          <Route path="/admin/groups" component={ManageGroups} />
          <Route path="/admin/notices" component={ManageBroadcasts} />
          <Route path="/admin/settings" component={ManageSettings} />

          <Route path="/admin">
            <Redirect to="/admin/users" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
