import React, { FC } from 'react';
import { ReactComponent as GouldsonLogoSvg } from 'assets/GouldsonLogo.svg';
import { NavLink } from 'react-router-dom';
import { routeMap } from 'routeMap';

import css from './GouldsonLogo.module.css';

export const GouldsonLogo: FC = ({ ...props }) => {
  return (
    <NavLink exact to={routeMap.root()}>
      <div className={css.GouldsonLogo} {...props}>
        <GouldsonLogoSvg id="logo" />
      </div>
    </NavLink>
  );
};
