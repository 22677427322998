import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import React from 'react';
import { AwsAuthComponents } from 'toolbox';
import Stack from 'toolbox/src/components/Stack/Stack';

import { CodeForm } from './Forms';

export const VerifyContact: AwsAuthComponents['verifyContact'] = ({
  beginVerify,
  attributeToVerify,
  submitVerify,
  currentStep,
  apiState,
}) => {
  return (
    <div className="w-full max-w-sm p-5 bg-white">
      <Stack>
        <h2 className="text-xl font-semibold text-center text-gray-700">
          Verify Contact Information
        </h2>
        {currentStep === 'noCodeSent' && (
          <>
            {attributeToVerify === 'email' && (
              <p className="text-sm text-gray-600">
                We need to verify your email address. Press the button below to
                send a code to your email.
              </p>
            )}
            {attributeToVerify === 'phone_number' && (
              <p className="text-sm text-gray-600">
                We need to verify your phone number. Press the button below to
                send a code to your phone.
              </p>
            )}
            <ButtonBase
              onClick={beginVerify}
              className="text-white bg-gnavy-800"
            >
              Send Code
            </ButtonBase>
          </>
        )}
        {currentStep === 'codeHasBeenSent' && (
          <CodeForm.Wrapper onSubmit={submitVerify}>
            <Stack>
              <p className="text-sm text-gray-600">
                Enter the code you received{' '}
                {attributeToVerify === 'email'
                  ? 'at your email address'
                  : 'on your phone'}{' '}
                below.
              </p>
              <CodeForm.Inputs.code label="Code" autoComplete="off" />
              <ButtonBase type="submit" className="text-white bg-gnavy-800">
                Submit
              </ButtonBase>
            </Stack>
          </CodeForm.Wrapper>
        )}
        {apiState.type === 'error' && (
          <div className="font-semibold text-center">
            <p className="text-red-600">{apiState.errorMessage}</p>
          </div>
        )}
      </Stack>
    </div>
  );
};
