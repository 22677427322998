import { makeMutationForm } from 'components/makeMutationForm';

export const SignInForm = makeMutationForm<{
  username: string;
  password: string;
}>({
  config: {
    password: {
      type: 'password',
    },
    username: {
      type: 'text',
    },
  },
  validate: ({ password, username }) => {
    if (!username) {
      return { username: 'You must enter your username' };
    }
    if (!password) {
      return { password: 'You must enter your password' };
    }
  },
});

export const UsernameOnlyForm = makeMutationForm<{ username: string }>({
  config: {
    username: {
      type: 'text',
    },
  },
  validate: ({ username }) => {
    if (!username) {
      return {
        username: 'You must include a username.',
      };
    }
  },
});

export const ForgotPasswordForm = makeMutationForm<{
  username: string;
  password: string;
  confirmPassword: string;
  code: string;
}>({
  config: {
    username: {
      type: 'hidden',
    },
    password: {
      type: 'password',
    },
    confirmPassword: {
      type: 'password',
    },
    code: {
      type: 'text',
    },
  },
  validate: ({ confirmPassword, code, password }) => {
    if (!code) {
      return {
        code: 'You must include the code.',
      };
    }
    if (!password) {
      return {
        password: 'You must include a password.',
      };
    }
    if (!confirmPassword) {
      return {
        confirmPassword: 'You must confirm your password.',
      };
    }
    if (password !== confirmPassword) {
      return {
        confirmPassword: 'The two passwords you provided do not match.',
      };
    }
  },
});

export const CodeForm = makeMutationForm<{ code: string }>({
  config: {
    code: {
      type: 'text',
    },
  },
  validate: ({ code }) => {
    if (!code) {
      return { code: 'You must provide a code.' };
    }
  },
});

export const PasswordOnlyForm = makeMutationForm<{
  password: string;
  confirmPassword: string;
  email: string;
  phone_number: string;
}>({
  config: {
    password: {
      type: 'password',
    },
    confirmPassword: {
      type: 'password',
    },
    email: {
      type: 'text',
    },
    phone_number: {
      type: 'text',
    },
  },
});
