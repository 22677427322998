import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteBroadcastByIdMutationVariables = {
  id: Types.Scalars['uuid']
};


export type DeleteBroadcastByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_broadcasts_by_pk: Types.Maybe<(
    { __typename?: 'broadcasts' }
    & Pick<Types.Broadcasts, 'id'>
  )> }
);


export const DeleteBroadcastByIdDocument = gql`
    mutation DeleteBroadcastById($id: uuid!) {
  delete_broadcasts_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteBroadcastByIdMutation() {
  return Urql.useMutation<DeleteBroadcastByIdMutation, DeleteBroadcastByIdMutationVariables>(DeleteBroadcastByIdDocument);
};