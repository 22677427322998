import React, { FC } from 'react';
// import { ButtonBase } from 'components/ButtonBase/ButtonBase';
// import { ResetButton } from 'components/ResetButton/ResetButton';
// import { SubmitButton } from 'components/SaveButton/SaveButton';
// import { toast } from 'react-toastify';
import { useGetRateByIdQuery } from './graphql/GetRateById.generated';
import { useWrappedUrqlMutation } from 'toolbox';
import { useUpsertRateMutation } from './graphql/UpsertRate.generated';
import { useDeleteRateByIdMutation } from './graphql/DeleteRateById.generated';
import { GetRatesQuery } from './graphql/GetRates.generated';
import { EditRateForm } from './editRateForm';
import { toast } from 'react-toastify';
import { ResetButton } from 'components/ResetButton/ResetButton';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import { Rate_Cards } from 'graphql/schemaTypes.generated';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import * as Sentry from '@sentry/react';

interface IEditRatePage {
  rateId?: GetRatesQuery['rate_cards'][number]['id'] | null;
  closeHandler: () => void;
}

export const EditRatePage: FC<IEditRatePage> = ({ rateId, closeHandler }) => {
  const isNewRate = rateId === 'new';
  const [q] = useGetRateByIdQuery({
    variables: { id: rateId || '' },
    pause: !rateId || isNewRate,
  });

  const upsertRate = useWrappedUrqlMutation(useUpsertRateMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  const deleteRate = useWrappedUrqlMutation(useDeleteRateByIdMutation, {
    onSuccess: (res) => console.log(res),
    onError: (e) => console.warn(e),
  });

  if (q.fetching) return <section>Fetching rate info...</section>;
  if (q.error) return <section>Error: {q.error?.message}</section>;

  const rate = q.data?.rate_cards_by_pk;

  const save = async (formValues: Partial<Rate_Cards>) => {
    try {
      upsertRate.dispatch({
        object: {
          id: formValues?.id,
          apr_lte_3000: formValues?.apr_lte_3000,
          apr_gt_3000_lte_5000: formValues?.apr_gt_3000_lte_5000,
          apr_gt_5000_lte_10000: formValues?.apr_gt_5000_lte_10000,
          apr_gt_10000_lte_15000: formValues?.apr_gt_10000_lte_15000,
          apr_gt_15000_lte_25000: formValues?.apr_gt_15000_lte_25000,
          apr_gt_25000: formValues?.apr_gt_25000,
        },
      });
      toast.success('Saved successfully');
      // setImmediate(() => closeHandler());
    } catch (e) {
      toast.error('Could not add/update rate card');
      Sentry.captureException(e);
    }
  };

  const remove = () => {
    if (
      window.confirm('Are you sure you want to delete this rate?') &&
      rateId
    ) {
      deleteRate.dispatch({ id: rateId });
      toast.success('Deleted rate');
      setImmediate(() => closeHandler());
    }
  };

  const emptyValues = {};

  return (
    <section className="self-stretch">
      <h3 className="p-3 pb-3 bg-white rounded-t">
        {rateId === 'new' ? `New Rate` : `Rate ${rateId}`}
      </h3>
      <EditRateForm.Wrapper
        onSubmit={save}
        initialValues={
          isNewRate
            ? emptyValues
            : {
                id: rate?.id,
                apr_lte_3000: rate?.apr_lte_3000,
                apr_gt_3000_lte_5000: rate?.apr_gt_3000_lte_5000,
                apr_gt_5000_lte_10000: rate?.apr_gt_5000_lte_10000,
                apr_gt_10000_lte_15000: rate?.apr_gt_10000_lte_15000,
                apr_gt_15000_lte_25000: rate?.apr_gt_15000_lte_25000,
                apr_gt_25000: rate?.apr_gt_25000,
              }
        }
      >
        <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
          <div className="flex justify-end p-2">
            <ButtonBase
              className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
              onClick={remove}
            >
              Delete
            </ButtonBase>
            <ResetButton className="p-2 px-4 mr-2 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-md btn">
              Reset
            </ResetButton>
            <SubmitButton className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-200">
              Save
            </SubmitButton>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-3 p-3 pb-10 bg-white rounded-b">
          <EditRateForm.Inputs.id labelText="Rate ID" className="col-span-3" />
          <EditRateForm.Inputs.apr_lte_3000
            labelText="Interest Rate ≤3000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
          <EditRateForm.Inputs.apr_gt_3000_lte_5000
            labelText="Interest Rate >3000 ≤5000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
          <EditRateForm.Inputs.apr_gt_5000_lte_10000
            labelText="Interest Rate >5000 ≤10000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
          <EditRateForm.Inputs.apr_gt_10000_lte_15000
            labelText="Interest Rate >10000 ≤15000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
          <EditRateForm.Inputs.apr_gt_15000_lte_25000
            labelText="Interest Rate >15000 ≤25000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
          <EditRateForm.Inputs.apr_gt_25000
            labelText="Interest Rate >25000"
            suffix="%"
            className="col-span-2 col-start-1"
          />
        </div>
      </EditRateForm.Wrapper>
    </section>
  );
};
