import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type InsertGroupMutationVariables = {
  groupId: Types.Scalars['String']
};


export type InsertGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_groups_one: Types.Maybe<(
    { __typename?: 'groups' }
    & Pick<Types.Groups, 'id'>
  )> }
);


export const InsertGroupDocument = gql`
    mutation InsertGroup($groupId: String!) {
  insert_groups_one(object: {id: $groupId}) {
    id
  }
}
    `;

export function useInsertGroupMutation() {
  return Urql.useMutation<InsertGroupMutation, InsertGroupMutationVariables>(InsertGroupDocument);
};