import React, { FC, ReactNode } from 'react';
import { Link, Route } from 'react-router-dom';

interface IModalToggleLink {
  path: string;
  exact?: boolean;
  modal: ReactNode;
  className?: string;
}

export const ModalToggleLink: FC<IModalToggleLink> = ({
  children,
  path, // the URL used to open the Modal
  exact = false,
  modal, // the contents of the Modal
  className,
  ...props
}) => {
  return (
    <>
      <Link to={path} className={className} {...props}>
        {children}
      </Link>

      <Route path={path} exact={exact} render={() => modal} />
    </>
  );
};
