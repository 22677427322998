import { ReactComponent as LoadingSpinnerSvg } from 'assets/LoadingSpinner.svg';
import cssBase from 'components/CompactCard.module.css';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

import css from './MessageCard.module.css';

interface IMessageCard {
  disabled?: boolean;
  newMessageCount?: number;
  onClick?: () => void;
}

export const MessageCard: FC<IMessageCard> = ({
  disabled,
  newMessageCount,
  onClick = () => null,
}) => {
  return (
    <motion.div
      onClick={disabled ? () => null : onClick}
      className={[css.NewMessageCardCompact, cssBase.CompactCard].join(' ')}
    >
      <div className={css.NewMessageGraphic}>
        <LoadingSpinnerSvg />
      </div>
      <main className="flex-grow px-3 py-2">
        <h2 className="text-sm font-semibold truncate sm:text-lg">Messages</h2>
        <h4 className="font-semibold text-2xs text-gblue-100 sm:text-xs">
          You've got {newMessageCount ?? ''} new messages.
        </h4>
      </main>
      <footer className="z-10 px-4 py-3 text-xs font-semibold text-right text-white truncate bg-gblue-500">
        View All Messages
      </footer>
    </motion.div>
  );
};
