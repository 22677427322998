import React from 'react';
import { Users } from 'graphql/schemaTypes.generated';
import { useWrappedUrqlMutation } from 'toolbox';
import { useAddUserToGroupMutation } from './graphql/AddUserToGroup.generated';
import { useDeleteUserFromGroupMutation } from './graphql/DeleteUserFromGroup.generated';

export const GroupMember: React.FC<{
  groupId: string;
  user: Partial<Users>;
  isMember?: boolean;
  callback?: () => void;
}> = ({ groupId, user, isMember = false, callback }) => {
  const addMemberMutation = useWrappedUrqlMutation(useAddUserToGroupMutation);
  const removeMemberMutation = useWrappedUrqlMutation(
    useDeleteUserFromGroupMutation,
  );

  const addMember = async () => {
    await addMemberMutation.dispatch({
      group_id: groupId,
      user_id: user.cognito_sub,
    });
    callback?.();
  };
  const removeMember = async () => {
    await removeMemberMutation.dispatch({
      group_id: groupId,
      user_id: user.cognito_sub,
    });
    callback?.();
  };

  return (
    <li className="flex justify-between items-center border-b py-2 px-2">
      <span className="text-xs">{user.email}</span>
      <button
        type="button"
        className={`rounded text-xs ${
          isMember ? 'bg-red-200' : 'bg-gteal-400'
        } px-2 py-1`}
        onClick={() => {
          if (isMember) removeMember();
          else addMember();
        }}
      >
        {isMember ? 'remove' : 'add'}
      </button>
    </li>
  );
};

export default GroupMember;
