import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetBroadcastsQueryVariables = {};


export type GetBroadcastsQuery = (
  { __typename?: 'query_root' }
  & { broadcasts: Array<(
    { __typename?: 'broadcasts' }
    & Pick<Types.Broadcasts, 'id' | 'created_at' | 'activate_at' | 'disable_at' | 'subject' | 'recipient_group_id'>
  )> }
);


export const GetBroadcastsDocument = gql`
    query GetBroadcasts {
  broadcasts(order_by: {activate_at: asc}) {
    id
    created_at
    activate_at
    disable_at
    subject
    recipient_group_id
  }
}
    `;

export function useGetBroadcastsQuery(options: Omit<Urql.UseQueryArgs<GetBroadcastsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetBroadcastsQuery>({ query: GetBroadcastsDocument, ...options });
};