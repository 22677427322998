import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type AddUserPermissionsMutationVariables = {
  objects: Array<Types.Delegates_Insert_Input>
};


export type AddUserPermissionsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_delegates: Types.Maybe<(
    { __typename?: 'delegates_mutation_response' }
    & Pick<Types.Delegates_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'delegates' }
      & Pick<Types.Delegates, 'id' | 'customer_id' | 'delegate_cognito_user_id'>
    )> }
  )> }
);


export const AddUserPermissionsDocument = gql`
    mutation AddUserPermissions($objects: [delegates_insert_input!]!) {
  insert_delegates(objects: $objects) {
    returning {
      id
      customer_id
      delegate_cognito_user_id
    }
    affected_rows
  }
}
    `;

export function useAddUserPermissionsMutation() {
  return Urql.useMutation<AddUserPermissionsMutation, AddUserPermissionsMutationVariables>(AddUserPermissionsDocument);
};