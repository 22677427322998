import React, { FC, ChangeEvent, useState } from 'react';
import { InputWrapper, IInputWrapper } from './InputWrapper';
import classNames from 'classnames';
import { Backspace, LockClosed } from 'heroicons-react';

interface ITextarea
  extends IInputWrapper,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  cols?: number;
  rows?: number;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  clearButton?: boolean;
  clearButtonHandler?: () => void;
  prefix?: string;
  suffix?: string;
  error?: string | null;
  disabled?: boolean;
}

export const Textarea: FC<ITextarea> = ({
  value = '',
  onChange = () => undefined,
  small = false,
  clearButton = false,
  clearButtonHandler = () => undefined,
  hintText,
  labelText,
  labelPosition,
  prefix,
  className,
  suffix,
  placeholder,
  rows = 8,
  spellCheck = false,
  autoCapitalize = 'false',
  disabled = false,
  error = null,
  ...props
}) => {
  const defaultFilter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 27 && clearButtonHandler) clearButtonHandler(); // clear on Esc key
  };

  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <InputWrapper
      isFocussed={isFocussed}
      disabled={disabled}
      error={error}
      hintText={hintText}
      className={className}
      labelPosition={labelPosition}
      labelText={labelText}
      {...props}
    >
      <div
        className={classNames(
          'w-full flex justify-between items-center overflow-hidden',
          disabled ? '' : 'bg-white focus-within:bg-white',
          (hintText || error) && 'pt-0',
        )}
      >
        {prefix && (
          <span
            className={classNames(
              'pl-3 font-normal flex-shrink',
              small ? 'text-sm' : 'text-base',
            )}
          >
            {prefix}
          </span>
        )}

        <textarea
          aria-label={labelText as string}
          rows={rows}
          value={value.split('<br>').join('\n') || ''}
          disabled={disabled}
          onChange={onChange}
          className={classNames(
            'min-w-0 flex-grow bg-transparent placeholder-gray-400 focus:outline-none px-2 py-2',
            small ? 'text-xs' : 'text-base',
            disabled
              ? 'cursor-default select-none text-gray-600'
              : 'text-gray-900',
          )}
          placeholder={placeholder ? placeholder : undefined}
          onKeyDown={(e) => defaultFilter(e)}
          onFocusCapture={() => setIsFocussed(true)}
          onBlurCapture={() => setIsFocussed(false)}
          spellCheck={spellCheck}
          autoCapitalize={autoCapitalize}
          {...props}
        />

        {suffix && (
          <span
            className={classNames(
              'pr-3 font-normal text-gray-500 flex-shrink-0',
              small ? 'text-2xs' : 'text-xs',
            )}
          >
            {suffix}
          </span>
        )}

        {disabled === true ? (
          <span className="flex items-center mr-1 text-gray-400">
            <LockClosed path={undefined} className="inline w-5 mr-1" />
          </span>
        ) : (
          clearButton &&
          value?.length > 0 && (
            <Backspace
              path={undefined}
              className="w-5 mr-2 text-gray-600 opacity-25 cursor-pointer hover:opacity-100"
              onClick={clearButtonHandler}
            />
          )
        )}
      </div>
    </InputWrapper>
  );
};
