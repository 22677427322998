import { ReactComponent as BusinessAccountSvg } from 'assets/business.svg';
import { ReactComponent as PersonalAccountSvg } from 'assets/personal.svg';
import classNames from 'classnames';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import { ModalToggleLink } from 'components/ModalBase/ModalToggleLink/ModalToggleLink';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { AccountSwitcherModal } from './AccountSwitcherModal';
import { ChevronRight } from 'heroicons-react';

interface IAccountSwitcherModalButton {
  isPersonalAccount?: boolean;
  accountName?: string;
}

export const AccountSwitcherModalButton: FC<IAccountSwitcherModalButton> = ({
  isPersonalAccount = false,
  accountName = '',
}) => {
  const { user } = useLoggedInUser();
  const history = useHistory();
  const multipleAccounts = user?.customers && user?.customers?.length > 1;

  if (!user?.selected_customer) return <AccountSwitcherModal />;

  return (
    <ModalToggleLink
      path="/switchaccount"
      exact
      modal={<AccountSwitcherModal />}
      className="relative flex h-16 col-span-6 md:col-span-3"
    >
      <motion.div
        className={classNames(
          'p-0 bg-teal-300 bg-gradient-to-t from-teal-400 to-teal-300 rounded-lg text-left flex w-full select-none shadow-sm',
          {
            'cursor-pointer': multipleAccounts,
            'cursor-default': !multipleAccounts,
          },
        )}
      >
        <figure className="items-center justify-center flex-shrink-0 hidden w-20 sm:flex">
          {isPersonalAccount ? (
            <PersonalAccountSvg
              className="absolute w-full ml-1 text-teal-500 fill-current"
              style={{ height: '95%' }}
            />
          ) : (
            <BusinessAccountSvg
              className="absolute w-full text-teal-500 fill-current"
              style={{ height: '120%' }}
            />
          )}
        </figure>

        <main className="relative flex flex-col justify-center flex-grow px-4 overflow-hidden font-semibold leading-tight text-gnavy-900">
          <h2 className="text-base truncate sm:text-lg">
            {isPersonalAccount ? 'Personal' : 'Business'} Account
          </h2>
          <h3 className="text-xs tracking-wide uppercase truncate opacity-75 sm:text-sm">
            {accountName}
          </h3>
        </main>

        {multipleAccounts && (
          <footer className="flex items-center flex-shrink-0 pl-4 pr-3 text-xs font-semibold bg-teal-400 rounded-r-lg text-gnavy-900 bg-gradient-to-t from-teal-500 to-teal-400">
            Change
            <ChevronRight path={undefined} className="inline w-4 h-4" />
          </footer>
        )}
      </motion.div>
    </ModalToggleLink>
  );
};
