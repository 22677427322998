import React from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import { X } from 'heroicons-react';
import './ToastNotification.css';

export const ToastNotificationContainer: React.FC = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    closeButton={<NotificationCloseButton />}
    draggable={false}
    transition={Flip}
  />
);

const NotificationCloseButton = () => (
  <X path={undefined} className="w-4 h-4 -mt-1 -mr-1" />
);
