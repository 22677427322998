import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { AwsAuthenticator } from 'toolbox';
import { useAuthWrapperContext } from 'toolbox/src/components/AuthWrapper/AuthWrapper';

import { ConfirmSignIn } from './ConfirmSignIn';
import { ForgotPassword } from './ForgotPassword';
import { RequireNewPassword } from './RequireNewPassword';
import { SignIn } from './SignIn';
import { VerifyContact } from './VerifyContact';

export const Authenticator: FC = () => {
  const authContext = useAuthWrapperContext();

  if (authContext.status == 'pending') return null; // don't show login screen until Amplify has initialised

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full">
      <AwsAuthenticator
        components={{
          confirmSignIn: ConfirmSignIn,
          forgotPassword: ForgotPassword,
          requireNewPassword: RequireNewPassword,
          signIn: SignIn,
          verifyContact: VerifyContact,
          pending: () => null,
          signedIn: () => <Redirect to="/" />,
        }}
        isLoggedIn={authContext.status === 'loggedIn'}
        onLogInSuccess={async () => {
          authContext.refreshAuthState();
          // setImmediate(() => navigate.root());
        }}
      />
    </div>
  );
};
