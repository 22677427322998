import React, { FC, useEffect, useState } from 'react';
import { useWrappedUrqlMutation } from 'toolbox';
import { useRemoveUserPermissionsMutation } from './graphql/RemoveUserPermissions.generated';
import { useGetUserDetailsQuery } from './graphql/GetUserDetails.generated';
import { useDeleteUserMutation } from './graphql/DeleteUser.generated';
import { useAddUserPermissionsMutation } from './graphql/AddUserPermissions.generated';
import { useUpsertUserMutation } from './graphql/UpsertUser.generated';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { GetUsersQuery } from './graphql/GetUsers.generated';
import { EditUserForm } from './editUserForm';
import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ManageDevices } from './ManageDevices';

import {
  ArrowLeft,
  ArrowRight,
  EyeOffOutline,
  EyeOutline,
} from 'heroicons-react';
import { v4 as uuid } from 'uuid';
import {
  Customers,
  Delegates,
  Users,
  User_Devices,
} from 'graphql/schemaTypes.generated';
import { ResetButton } from 'components/ResetButton/ResetButton';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import Auth from '@aws-amplify/auth';
import AWS from 'aws-sdk';
import AwsExports from '../../../aws-exports';
import { useHistory } from 'react-router-dom';

interface IEditUserPage {
  cognitoSub: GetUsersQuery['users'][number]['cognito_sub'];
  closeHandler: (nextCognitoId?: string) => void;
}

export const EditUserPage: FC<IEditUserPage> = ({
  closeHandler,
  cognitoSub,
}) => {
  const addPermissions = useWrappedUrqlMutation(useAddUserPermissionsMutation, {
    onError: (e) => console.log(e),
  });

  const removePermissions = useWrappedUrqlMutation(
    useRemoveUserPermissionsMutation,
    { onError: (e) => console.log(e) },
  );

  const upsertUser = useWrappedUrqlMutation(useUpsertUserMutation, {
    onError: (e) => console.log(e),
  });

  const deleteUser = useWrappedUrqlMutation(useDeleteUserMutation, {
    onError: (e) => console.log(e),
  });

  const history = useHistory();

  const [userDetails, refresh] = useGetUserDetailsQuery({
    variables: { cognito_sub: cognitoSub },
    pause: !cognitoSub,
  });
  const user = userDetails?.data?.users_by_pk;

  useEffect(() => {
    if (cognitoSub && cognitoSub !== 'new') refresh();
  }, [cognitoSub]);

  useEffect(() => {
    if (!cognitoSub || cognitoSub === 'new') setLinkedCustomers([]);
    if (linked_customers) {
      setLinkedCustomers(linked_customers);
      setUnlinkedCustomers(unlinked_customers);
    }
  }, [userDetails]);

  const [linkedCustomers, setLinkedCustomers] = useState<any[]>([]);
  const [unlinkedCustomers, setUnlinkedCustomers] = useState<any[]>([]);
  const [selectedLeft, setSelectedLeft] = useState<any[]>([]); // form state
  const [selectedRight, setSelectedRight] = useState<any[]>([]); // form state
  const [newPassword, setnewPassword] = useState<String>('');

  const all_customers = userDetails?.data?.customers || [];
  const linked_customers = userDetails?.data?.users_by_pk?.customers || [];
  const unlinked_customers =
    all_customers?.filter(
      (customer) =>
        !linked_customers?.some(
          (delegation) => delegation?.customer?.id === customer?.id,
        ),
    ) || [];

  const handlePasswordChange = (value: String) => {
    setnewPassword(value);
  };

  const resetPermissions = () => {
    refresh();
  };

  const deleteCognitoUser = async (username: string): Promise<void> => {
    try {
      const adminCredentials = await Auth.currentUserCredentials();
      const adminCredentialsEssential = await Auth.essentialCredentials(
        adminCredentials,
      );
      const cognitoAdminAPI = new AWS.CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
        // @ts-ignore
        region: AwsExports.aws_cognito_region,
        credentials: adminCredentialsEssential,
      });

      await cognitoAdminAPI
        .adminDeleteUser({
          // @ts-ignore
          UserPoolId: AwsExports.aws_user_pools_id,
          Username: username,
        })
        .promise();
    } catch (e: any) {
      console.warn(e);
      Sentry.captureException(e);
      if (e?.message) toast.error(e?.message);
    }
  };

  const createCognitoUser = async (
    newUserEmailAddress: string,
    newUserPassword: string,
  ): Promise<string | null> => {
    try {
      const adminCredentials = await Auth.currentUserCredentials();
      const adminCredentialsEssential = await Auth.essentialCredentials(
        adminCredentials,
      );
      const cognitoAdminAPI = new AWS.CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
        // @ts-ignore
        region: AwsExports.aws_cognito_region,
        credentials: adminCredentialsEssential,
      });

      const newUser = await cognitoAdminAPI
        .adminCreateUser({
          // @ts-ignore
          UserPoolId: AwsExports.aws_user_pools_id,
          Username: newUserEmailAddress,
          UserAttributes: [
            { Name: 'email', Value: newUserEmailAddress },
            { Name: 'email_verified', Value: 'true' },
          ],
          MessageAction: 'SUPPRESS', // prevent email being sent to user
        })
        .promise();

      if (newUser?.User?.Username) {
        await cognitoAdminAPI
          .adminAddUserToGroup({
            // @ts-ignore
            UserPoolId: AwsExports.aws_user_pools_id,
            Username: newUser.User.Username,
            GroupName: 'Users',
          })
          .promise();

        await cognitoAdminAPI
          .adminSetUserPassword({
            // @ts-ignore
            UserPoolId: AwsExports.aws_user_pools_id,
            Username: newUser.User.Username,
            Password: newUserPassword,
            Permanent: true,
          })
          .promise();

        return newUser.User.Username;
      }
    } catch (e: any) {
      console.warn(e);
      Sentry.captureException(e);
      if (e?.message) toast.error(e?.message);
    }
    return null;
  };

  const save = async (
    formValues: Partial<Users & { is_new_user: boolean; password: string }>,
  ) => {
    let newUserCognitoSub = '';
    let selectedCustomerId = formValues?.selected_customer_id;

    if (formValues.is_new_user) {
      if (
        (!cognitoSub || cognitoSub === 'new') &&
        formValues?.email &&
        formValues?.password
      ) {
        newUserCognitoSub =
          (await createCognitoUser(formValues.email, formValues.password)) ||
          '';
      }
    } else {
      // if the selected_customer isn't in list of linked customers, auto-select a customer that is

      if (
        linkedCustomers.some((c) => (c.id == selectedCustomerId ? true : false))
      ) {
        console.log(
          'selected_customer is in the list of linked accounts, doing nothing...',
        );
      } else {
        console.log('changing selected_customer_id...', linkedCustomers?.[0]);
        selectedCustomerId = linkedCustomers?.[0]?.customer.id || null;
      }
    }

    try {
      await upsertUser.dispatch({
        object: {
          cognito_sub: newUserCognitoSub || cognitoSub || undefined,
          email: formValues.email,
          current_employer: formValues.current_employer,
          current_occupation: formValues.current_occupation,
          date_of_birth: formValues.date_of_birth,
          gender: formValues.gender,
          maiden_name: formValues.maiden_name,
          marital_status: formValues.marital_status,
          name: formValues.name,
          nationality: formValues.nationality,
          number_of_children: formValues.number_of_children,
          phone_number: formValues.phone_number,
          place_of_birth: formValues.place_of_birth,
          previous_employer: formValues.previous_employer,
          previous_occupation: formValues.previous_occupation,
          residential_status: formValues.residential_status,
          selected_customer_id: selectedCustomerId,
          self_employed: Boolean(formValues.self_employed),
          title: formValues.title,
          years_at_current_employer: formValues.years_at_current_employer,
          years_at_previous_employer: formValues.years_at_previous_employer,
          notify_by_email: formValues.notify_by_email,
          notify_by_sms: formValues.notify_by_sms,
          notify_by_app: formValues.notify_by_app,
          notify_on_payment_received: formValues.notify_on_payment_received,
        },
      });

      const addedDelegates = linkedCustomers.filter(
        (a: Delegates) =>
          !linked_customers.some((b) => a?.customer?.id === b?.customer?.id),
      );
      if (addedDelegates.length > 0) {
        await addPermissions.dispatch({
          objects: addedDelegates.map((delegate: any) => ({
            id: delegate.id,
            customer_id: delegate?.customer?.id,
            delegate_cognito_user_id: user?.cognito_sub,
          })),
        });
      }

      const removedDelegates = linked_customers.filter(
        (a) =>
          !linkedCustomers.some((b) => a?.customer?.id === b?.customer?.id),
      );
      if (removedDelegates.length > 0) {
        await removePermissions.dispatch({
          delegate_ids: removedDelegates.map((delegate) => delegate.id),
        });
      }

      if (formValues.is_new_user && newUserCognitoSub) {
        history.push('/admin/users/' + newUserCognitoSub);
        closeHandler(newUserCognitoSub);
      } else {
        toast.success('Updated user succesfully');
        refresh();
      }
    } catch (e) {
      console.warn(e);
      toast.error('Could not save changes');
      Sentry.captureException(e);
    }
  };

  const emptyValues = {
    is_new_user: true,
    cognito_sub: undefined,
    email: '',
    password: '',
    phone_number: undefined,
    name: undefined,
    title: undefined,
    gender: undefined,
    maiden_name: undefined,
    date_of_birth: undefined,
    place_of_birth: undefined,
    nationality: undefined,
    residential_status: undefined,
    marital_status: undefined,
    number_of_children: undefined,
    self_employed: 'false',
    current_employer: undefined,
    current_occupation: undefined,
    years_at_current_employer: undefined,
    previous_employer: undefined,
    previous_occupation: undefined,
    years_at_previous_employer: undefined,
    notify_by_email: false,
    notify_by_sms: false,
    notify_by_app: true,
    notify_on_payment_received: true,
  };

  return (
    <section>
      <EditUserForm.Wrapper
        onSubmit={save}
        // @ts-ignore
        initialValues={
          !cognitoSub || cognitoSub === 'new'
            ? emptyValues
            : {
                is_new_user: false,
                cognito_sub: cognitoSub,
                email: user?.email,
                password: undefined,
                phone_number: user?.phone_number,
                name: user?.name,
                title: user?.title,
                gender: user?.gender,
                maiden_name: user?.maiden_name,
                date_of_birth: user?.date_of_birth,
                place_of_birth: user?.place_of_birth,
                nationality: user?.nationality,
                residential_status: user?.residential_status,
                marital_status: user?.marital_status,
                number_of_children: user?.number_of_children,
                self_employed: user?.self_employed.toString(),
                current_employer: user?.current_employer,
                current_occupation: user?.current_occupation,
                years_at_current_employer: user?.years_at_current_employer,
                previous_employer: user?.previous_employer,
                previous_occupation: user?.previous_occupation,
                years_at_previous_employer: user?.years_at_previous_employer,
                notify_by_email: user?.notify_by_email,
                notify_by_sms: user?.notify_by_sms,
                notify_by_app: user?.notify_by_app,
                notify_on_payment_received: user?.notify_on_payment_received,
              }
        }
      >
        <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
          <div className="flex justify-end p-2">
            <button
              type="button"
              className="p-2 px-4 mr-2 text-xs font-semibold text-red-700 bg-white border-2 border-red-200 rounded-md btn"
              onClick={() =>
                user?.cognito_sub
                  ? window.confirm(
                      `Are you sure you want to delete ${user?.email}?`,
                    ) &&
                    deleteUser
                      .dispatch({
                        cognito_sub: user?.cognito_sub,
                      })
                      .then(() =>
                        deleteCognitoUser(user?.cognito_sub).then(() =>
                          closeHandler(),
                        ),
                      )
                  : alert('error')
              }
            >
              Delete User
            </button>
            <ResetButton
              className="p-2 px-4 mr-2 text-xs font-semibold text-gray-700 bg-gray-100 border-2 border-gray-400 rounded-md btn"
              onClick={() => resetPermissions()}
            >
              Reset
            </ResetButton>
            <SubmitButton className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-200">
              Save
            </SubmitButton>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-3 p-3 bg-white rounded-t">
          <h2 className="col-span-6 text-2xl font-bold">
            {user?.name ?? user?.email ?? 'New User'}
          </h2>

          <EditUserForm.Consumer>
            {({ setFieldValue }) => (
              <div className="col-span-6 bg-white">
                {cognitoSub && cognitoSub !== 'new' && (
                  <>
                    <h2 className="flex justify-between py-2 text-xl">
                      Account Permissions
                      {userDetails?.fetching && (
                        <LoadingSpinner className="float-right w-6 h-6 text-gblue-600" />
                      )}
                    </h2>
                    <div className="grid grid-cols-11 mb-4 rounded-b-lg">
                      <label className="col-span-5 border-2 border-red-500 rounded focus-within:border-red-500">
                        <h3 className="p-1 font-semibold text-red-600 uppercase bg-red-100 border-b border-red-200 text-2xs">
                          <EyeOutline
                            path={undefined}
                            className="inline w-3 h-3 mx-1 mb-1"
                          />
                          Linked accounts
                        </h3>
                        <select
                          name="accounts_permitted"
                          multiple
                          className="w-full h-64 p-0 overflow-y-scroll text-gray-700 border-none rounded-none"
                          onChange={(e) =>
                            setSelectedLeft(
                              Array.from(e.target.selectedOptions).map(
                                (o) => o.value,
                              ),
                            )
                          }
                          value={selectedLeft}
                        >
                          {linkedCustomers?.map((delegate: Delegates) => (
                            <option key={delegate.id} value={delegate.id}>
                              {delegate.customer.name}
                            </option>
                          ))}
                        </select>
                      </label>
                      <div className="flex flex-col col-span-1">
                        <h3 className="pb-2">&nbsp;</h3>
                        <ButtonBase
                          className="flex flex-col h-full mx-2 text-xs text-gray-600 border-2 border-gray-400 shadow-none"
                          onClick={() => {
                            setFieldValue('cognito_sub', ''); // formvalue not actually used by save(), just makes the form dirty
                            if (selectedLeft?.length > 0) {
                              selectedLeft.map((selected_id: string) => {
                                const newUnlinkedCustomer = linkedCustomers?.find(
                                  (delegate: Delegates) =>
                                    delegate?.id === selected_id,
                                )?.customer;
                                setUnlinkedCustomers(
                                  [
                                    ...unlinkedCustomers,
                                    {
                                      id: newUnlinkedCustomer?.id,
                                      name: newUnlinkedCustomer?.name || '',
                                    },
                                  ].sort((a, b) =>
                                    a.name.toLowerCase() > b.name.toLowerCase()
                                      ? 1
                                      : -1,
                                  ),
                                );
                                setLinkedCustomers(
                                  linkedCustomers
                                    .filter(
                                      (delegate: Delegates) =>
                                        delegate.customer.id !==
                                        newUnlinkedCustomer.id,
                                    )
                                    .sort((a: Delegates, b: Delegates) =>
                                      a.customer.name.toLowerCase() >
                                      b.customer.name.toLowerCase()
                                        ? 1
                                        : -1,
                                    ),
                                );
                                return {};
                              });
                            }
                          }}
                        >
                          <ArrowRight path={undefined} className="w-4 h-4" />
                          Remove
                        </ButtonBase>
                        <ButtonBase
                          className="flex flex-col h-full mx-2 mt-2 text-xs text-gray-600 border-2 border-gray-400 shadow-none"
                          onClick={() => {
                            setFieldValue('cognito_sub', ''); // formvalue not actually used by save(), just makes the form dirty
                            if (selectedRight?.length > 0) {
                              selectedRight.map((selected_id: string) => {
                                const newLinkedCustomer = unlinkedCustomers?.find(
                                  (customer: Customers) =>
                                    customer?.id === selected_id,
                                );
                                setLinkedCustomers(
                                  [
                                    ...linkedCustomers,
                                    {
                                      id: uuid(), // new uuid
                                      customer: {
                                        id: newLinkedCustomer?.id,
                                        name: newLinkedCustomer?.name || '',
                                      },
                                    },
                                  ].sort((a, b) =>
                                    a.customer.name.toLowerCase() >
                                    b.customer.name.toLowerCase()
                                      ? 1
                                      : -1,
                                  ),
                                );
                                setUnlinkedCustomers(
                                  unlinkedCustomers
                                    .filter(
                                      (customer: Customers) =>
                                        customer.id !== newLinkedCustomer.id,
                                    )
                                    .sort((a: Customers, b: Customers) =>
                                      a.name.toLowerCase() >
                                      b.name.toLowerCase()
                                        ? 1
                                        : -1,
                                    ),
                                );

                                return false;
                              });
                            }
                          }}
                        >
                          <ArrowLeft path={undefined} className="w-4 h-4" />
                          Add
                        </ButtonBase>
                      </div>
                      <label className="col-span-5 text-gray-700 border-2 border-gray-400 rounded focus-within:border-blue-500 focus-within:text-blue-600">
                        <h3 className="p-1 font-semibold uppercase bg-gray-100 border-b border-gray-200 text-2xs">
                          <EyeOffOutline
                            path={undefined}
                            className="inline w-3 h-3 mx-1 mb-1"
                          />
                          Other accounts
                        </h3>
                        <select
                          name="accounts_not_permitted"
                          multiple
                          className="w-full h-64 p-0 overflow-y-scroll text-gray-700 border-none rounded-none"
                          value={selectedRight}
                          onChange={(e) =>
                            setSelectedRight(
                              Array.from(e.target.selectedOptions).map(
                                (o) => o.value,
                              ),
                            )
                          }
                        >
                          {unlinkedCustomers?.map((customer: Customers) => (
                            <option key={customer.id} value={customer.id}>
                              {customer.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
          </EditUserForm.Consumer>

          {user?.selected_customer?.name && (
            <p className="col-span-6 text-xs">
              Last selected account was "{user.selected_customer.name}"
            </p>
          )}

          <h2 className="flex justify-between col-span-6 text-xl font-normal">
            Contact Details
          </h2>
          {!cognitoSub || cognitoSub === 'new' ? (
            <p className="col-span-6 -mb-2 text-xs font-semibold text-red-500">
              The email address is used to log in to the app
            </p>
          ) : (
            <p className="col-span-6 -mb-2 text-xs font-semibold text-red-500">
              The email address is used to log in to the app
              <br />
              If you need to change the email address you will need to create a
              new user
            </p>
          )}
          <EditUserForm.Inputs.email
            disabled={cognitoSub && cognitoSub !== 'new'}
            labelText="Email"
            labelPosition="left"
            className="col-span-5"
          />

          <EditUserForm.Consumer>
            {({ setFieldValue }) => (
              <>
                {(!cognitoSub || cognitoSub === 'new') && (
                  <div className="col-span-5">
                    <EditUserForm.Inputs.password
                      labelText="Password"
                      labelPosition="left"
                      className="col-span-5"
                      onChange={(e: any) => {
                        setFieldValue('password', e.target.value);
                        handlePasswordChange(e.target.value);
                      }}
                    />
                    <div className="col-span-5 mt-3">
                      <PasswordStrengthBar
                        password={newPassword as string}
                        minLength={8}
                      />
                      <p
                        className="text-xs font-semibold text-gray-700"
                        style={{
                          position: 'relative',
                          top: '-20px',
                          paddingRight: '100px',
                        }}
                      >
                        Your password must 8-20 characters long, contain letters
                        and numbers, and must not contain space, special
                        characters, or emoji.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </EditUserForm.Consumer>

          <EditUserForm.Inputs.phone_number
            labelText="Phone Number"
            type="tel"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />

          <h2 className="flex justify-between col-span-6 text-xl font-normal">
            Alert Channels
          </h2>
          <div className="flex items-center justify-start col-span-5 p-4 space-x-6 border-2 border-gray-400 rounded">
            <EditUserForm.Inputs.notify_by_email
              small={true}
              className="col-span-1"
              disabled={upsertUser?.fetching && userDetails?.fetching}
            />
            <EditUserForm.Inputs.notify_by_sms
              small={true}
              className="col-span-1"
              disabled={upsertUser?.fetching && userDetails?.fetching}
            />
            <EditUserForm.Inputs.notify_by_app
              small={true}
              className="col-span-1"
              disabled={upsertUser?.fetching && userDetails?.fetching}
            />
          </div>

          <ManageDevices
            devices={(user?.user_devices as User_Devices[]) || []}
          />

          <h2 className="col-span-6 text-xl font-normal">Alert Options</h2>
          <div className="flex items-center justify-start col-span-5 p-4 space-x-4 border-2 border-gray-400 rounded">
            <EditUserForm.Inputs.notify_on_payment_received
              small={true}
              disabled={upsertUser?.fetching && userDetails?.fetching}
            />
            <div className="text-xs font-semibold text-gray-700 uppercase">
              Notify when payment is received
            </div>
          </div>
          <h2 className="col-span-6 text-xl font-normal">
            Basic Info
            {userDetails?.fetching && (
              <LoadingSpinner className="float-right w-6 h-6 text-gblue-600" />
            )}
          </h2>
          <EditUserForm.Inputs.title
            labelText="Title"
            className="col-span-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.name
            labelText="Full Name"
            className="col-span-4"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.gender
            labelText="Gender"
            className="col-span-2 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.maiden_name
            labelText="Maiden Name"
            className="col-span-3"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.date_of_birth
            labelText="Date of Birth"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />

          <h2 className="flex justify-between col-span-6 text-xl font-normal">
            Domestic
          </h2>
          <EditUserForm.Inputs.place_of_birth
            labelText="Place of Birth"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.nationality
            labelText="Nationality"
            className="col-span-5"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.residential_status
            labelText="Residential Status"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.marital_status
            labelText="Marital Status"
            className="col-span-3 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.number_of_children
            labelText="Children"
            className="col-span-2"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <h2 className="col-span-6 text-xl font-normal">Employment</h2>
          <EditUserForm.Inputs.self_employed
            labelText="Current Employment Type"
            className="col-span-5"
            small
            options={[
              { value: 'true', labelText: 'Self Employed' },
              { value: 'false', labelText: 'Employee' },
            ]}
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.current_employer
            labelText="Current Employer"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.current_occupation
            labelText="Current Occupation"
            className="col-span-5"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.years_at_current_employer
            labelText="Years at Current"
            className="col-span-2"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.previous_employer
            labelText="Previous Employer"
            className="col-span-5 col-start-1"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.previous_occupation
            labelText="Previous Occupation"
            className="col-span-5"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
          <EditUserForm.Inputs.years_at_previous_employer
            labelText="Years at Previous"
            className="col-span-2"
            disabled={upsertUser?.fetching && userDetails?.fetching}
          />
        </div>
      </EditUserForm.Wrapper>
      <div className="h-10 bg-white rounded-b-lg" />
    </section>
  );
};
