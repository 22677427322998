import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { GetLoggedInUserQuery } from 'components/LoggedInUserProvider/graphql/GetLoggedInUserByUsername.generated';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import css from 'components/ModalBase/Modal.module.css';
import classNames from 'classnames';
import { ModalTopBar } from 'components/ModalBase/ModalTopBar/ModalTopBar';
import { Overlay } from 'components/Overlay/Overlay';
import { motion, useReducedMotion } from 'framer-motion';
import React, { FC, Suspense, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWrappedUrqlMutation } from 'toolbox';

import { useSetSelectedCustomerMutation } from './graphql/SetSelectedCustomer.generated';
import { RadioGroup } from 'components/Inputs/RadioGroup';
import { Customers } from 'graphql/schemaTypes.generated';
import * as Sentry from '@sentry/react';

interface IAccountSwitcherModal {
  user?: GetLoggedInUserQuery['user'];
}

export const AccountSwitcherModal: FC<IAccountSwitcherModal> = () => {
  const { user } = useLoggedInUser();
  const history = useHistory();
  const reducedMotion = useReducedMotion();

  const [selectedCustomerId, setSelectedCustomerId] = useState(
    user?.customers?.length === 1
      ? user?.customers?.[0]?.customer?.id
      : user?.selected_customer?.id,
  );

  const setSelectedCustomer = useWrappedUrqlMutation(
    useSetSelectedCustomerMutation,
    {
      onSuccess: () => {},
      onError: (err) => console.log(err),
    },
  );

  const submit = () => {
    if (user?.cognito_sub && selectedCustomerId)
      setSelectedCustomer.dispatch({
        cognito_sub: user.cognito_sub,
        selected_customer_id: selectedCustomerId,
      });
    Sentry.setContext('selectedCustomer', {
      id: selectedCustomerId,
      name: user?.customers?.find((c) => c.customer.id === selectedCustomerId)
        ?.customer?.name,
    });
  };

  if (!user || !user?.customers || user.customers?.length < 1) return null;

  return (
    <motion.div className={css.Modal}>
      <Overlay>
        <ModalTopBar
          title="Choose Account"
          buttons={
            <ButtonBase
              onClick={() => history.push('/')}
              className="text-white bg-teal-400 btn"
            >
              Cancel
            </ButtonBase>
          }
        />
        <div className={css.CardPadding}>
          <motion.div
            className={classNames(css.Card)}
            initial={
              reducedMotion
                ? { opacity: 1, scale: 1 }
                : { opacity: 0, scale: 0 }
            }
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 200 }}
          >
            <section className="pb-3">
              <Suspense fallback={null}>
                {!selectedCustomerId && (
                  <div className="py-5">
                    You do not have an account selected, please choose from the
                    list below
                  </div>
                )}
                <RadioGroup
                  name="AccountChoice"
                  labelText="Accounts which you can access"
                  value={selectedCustomerId}
                  onChange={(e) => setSelectedCustomerId(e.target.value)}
                  options={user.customers?.map(
                    ({ customer }: { customer: Partial<Customers> }) => ({
                      value: customer?.id,
                      labelText: customer?.name || '',
                      // hintText: customer?.name,
                    }),
                  )}
                />
              </Suspense>
            </section>
            <section className="flex justify-end -mt-4">
              <ButtonBase
                onClick={() => {
                  submit();
                  history.push('/');
                }}
                className="text-white bg-blue-600"
              >
                Change Account
              </ButtonBase>
            </section>
          </motion.div>
        </div>
      </Overlay>
    </motion.div>
  );
};
