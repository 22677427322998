import { ButtonBase } from 'components/ButtonBase/ButtonBase';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';
import css from 'components/ModalBase/Modal.module.css';
import { ModalTopBar } from 'components/ModalBase/ModalTopBar/ModalTopBar';
import { Overlay } from 'components/Overlay/Overlay';
import { motion, useReducedMotion } from 'framer-motion';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Users } from 'graphql/schemaTypes.generated';
import { useWrappedUrqlMutation } from 'toolbox';
import { useUpsertUserMutation } from '../Admin/ManageUsers/graphql/UpsertUser.generated';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { MyDetailsForm } from './MyDetailsForm';

interface IMyDetailsModal {
  closeHandler?: () => void;
}

export const MyDetailsModal: FC<IMyDetailsModal> = ({ closeHandler }) => {
  const history = useHistory();
  const { user } = useLoggedInUser();
  const reducedMotion = useReducedMotion();
  const upsertUser = useWrappedUrqlMutation(useUpsertUserMutation, {
    onError: (e) => console.warn(e),
  });
  const save = async (formValues: Partial<Users>) => {
    try {
      await upsertUser.dispatch({
        object: {
          cognito_sub: user?.cognito_sub,
          email: formValues.email,
          current_employer: formValues.current_employer,
          current_occupation: formValues.current_occupation,
          date_of_birth: formValues.date_of_birth,
          gender: formValues.gender,
          maiden_name: formValues.maiden_name,
          marital_status: formValues.marital_status,
          name: formValues.name,
          nationality: formValues.nationality,
          number_of_children: formValues.number_of_children,
          phone_number: formValues.phone_number,
          place_of_birth: formValues.place_of_birth,
          previous_employer: formValues.previous_employer,
          previous_occupation: formValues.previous_occupation,
          residential_status: formValues.residential_status,
          selected_customer_id: user?.selected_customer_id,
          self_employed: Boolean(formValues.self_employed),
          title: formValues.title,
          years_at_current_employer: formValues.years_at_current_employer,
          years_at_previous_employer: formValues.years_at_previous_employer,
          notify_by_email: formValues.notify_by_email,
          notify_by_sms: formValues.notify_by_sms,
          notify_by_app: formValues.notify_by_app,
          notify_on_payment_received: formValues.notify_on_payment_received,
        },
      });
      closeHandler && closeHandler();
      // toast.success('Updated user succesfully');
    } catch (e) {
      console.warn(e);
      toast.error('Could not save changes');
      Sentry.captureException(e);
    }
  };

  return (
    <MyDetailsForm.Wrapper
      onSubmit={save}
      noFormTag
      initialValues={{
        email: user?.email,
        phone_number: user?.phone_number,
        name: user?.name,
        title: user?.title,
        gender: user?.gender,
        maiden_name: user?.maiden_name,
        date_of_birth: user?.date_of_birth,
        place_of_birth: user?.place_of_birth,
        nationality: user?.nationality,
        residential_status: user?.residential_status,
        marital_status: user?.marital_status,
        number_of_children: user?.number_of_children,
        self_employed: user?.self_employed,
        current_employer: user?.current_employer,
        current_occupation: user?.current_occupation,
        years_at_current_employer: user?.years_at_current_employer,
        previous_employer: user?.previous_employer,
        previous_occupation: user?.previous_occupation,
        years_at_previous_employer: user?.years_at_previous_employer,
        notify_by_email: user?.notify_by_email,
        notify_by_sms: user?.notify_by_sms,
        notify_by_app: user?.notify_by_app,
        notify_on_payment_received: user?.notify_on_payment_received,
      }}
    >
      <motion.div className={css.Modal}>
        <Overlay>
          <MyDetailsForm.Consumer>
            {({ handleSubmit, dirty, isValid }) => (
              <ModalTopBar
                title="My Details"
                buttons={
                  <div className="flex justify-end space-x-4">
                    <ButtonBase
                      onClick={() => history.push('/loans')}
                      className="text-white bg-teal-400 btn"
                    >
                      Close
                    </ButtonBase>
                    {}
                    <ButtonBase
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-200"
                      disabled={!dirty || (dirty && !isValid)}
                    >
                      Save
                    </ButtonBase>
                  </div>
                }
              />
            )}
          </MyDetailsForm.Consumer>
          <div className={css.CardPadding}>
            <motion.div
              className={classNames(css.Card)}
              initial={
                reducedMotion
                  ? { opacity: 1, scale: 1 }
                  : { opacity: 0, scale: 0 }
              }
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ type: 'spring', damping: 20, stiffness: 200 }}
            >
              <section>
                <div className="grid grid-cols-6 gap-3">
                  <MyDetailsForm.Inputs.email
                    disabled
                    labelText="Email"
                    labelPosition="left"
                    className="col-span-6"
                  />
                  <MyDetailsForm.Inputs.phone_number
                    labelText="Phone Number"
                    type="tel"
                    className="col-span-6"
                  />
                  <div className="flex flex-col col-span-6 border-2 border-gray-400 rounded">
                    <div className="p-2 font-semibold tracking-wide text-gray-700 uppercase bg-gray-100 border-b border-gray-400 text-2xs">
                      Alert Channels
                    </div>
                    <div className="flex items-center justify-start p-2 space-x-6">
                      <MyDetailsForm.Inputs.notify_by_email
                        small={true}
                        className="col-span-1"
                      />
                      <MyDetailsForm.Inputs.notify_by_sms
                        small={true}
                        className="col-span-1"
                      />
                      <MyDetailsForm.Inputs.notify_by_app
                        small={true}
                        className="col-span-1 hidden"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col col-span-6 border-2 border-gray-400 rounded">
                    <div className="p-2 font-semibold tracking-wide text-gray-700 uppercase bg-gray-100 border-b border-gray-400 text-2xs">
                      Alert Options
                    </div>
                    <div className="flex items-center justify-start p-2 space-x-6">
                      <MyDetailsForm.Inputs.notify_on_payment_received
                        small={true}
                      />
                      <div className="text-xs font-semibold text-gray-700 uppercase">
                        Notify when payment is received
                      </div>
                    </div>
                  </div>
                  {/*<MyDetailsForm.Inputs.title
                    labelText="Title"
                    className="col-span-1"
                  />
                  <MyDetailsForm.Inputs.name
                    labelText="Full Name"
                    className="col-span-5"
                  />
                  <MyDetailsForm.Inputs.gender
                    labelText="Gender"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.maiden_name
                    labelText="Maiden Name"
                    className="col-span-4"
                  />
                  <MyDetailsForm.Inputs.date_of_birth
                    labelText="Date of Birth"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.place_of_birth
                    labelText="Place of Birth"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.nationality
                    labelText="Nationality"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.residential_status
                    labelText="Residential Status"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.marital_status
                    labelText="Marital Status"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.number_of_children
                    labelText="Number of Children"
                    className="col-span-3"
                  />
                  <MyDetailsForm.Inputs.current_employer
                    labelText="Current Employer"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.current_occupation
                    labelText="Current Occupation"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.years_at_current_employer
                    labelText="Years at Current Employer"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.self_employed
                    labelText="Current Employment Type"
                    className="col-span-6"
                    options={[
                      { value: 'true', labelText: 'Self Employed' },
                      { value: 'false', labelText: 'Employee' },
                    ]}
                  />
                  <MyDetailsForm.Inputs.previous_employer
                    labelText="Previous Employer"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.previous_occupation
                    labelText="Previous Occupation"
                    className="col-span-2"
                  />
                  <MyDetailsForm.Inputs.years_at_previous_employer
                    labelText="Years at Previous Employer"
                    className="col-span-2"
                  />*/}
                </div>
              </section>
            </motion.div>
          </div>
        </Overlay>
      </motion.div>
    </MyDetailsForm.Wrapper>
  );
};
