import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DocumentTextOutline,
  FolderOutline,
  UserOutline,
  Variable,
  BellOutline,
  SpeakerphoneOutline,
  ChatAltOutline,
  CogOutline,
  UserGroupOutline,
} from 'heroicons-react';

export const AdminMenu = () => (
  <ul
    className="flex flex-col flex-shrink-0 w-24 overflow-hidden text-xs font-semibold pointer-events-auto"
    style={{ marginTop: '90px' }}
  >
    <MenuTab
      to="/admin/users"
      label="Users"
      icon={<UserOutline path={undefined} />}
    />
    <MenuTab
      to="/admin/customers"
      label="Cust..."
      icon={<FolderOutline path={undefined} />}
    />
    <MenuTab
      to="/admin/loans"
      label="Loans"
      icon={<DocumentTextOutline path={undefined} />}
    />
    <MenuTab
      to="/admin/rates"
      label="Rates"
      icon={<Variable path={undefined} />}
    />
    {/*
      <MenuTab
      to="/admin/templates"
      label="Msgs"
      icon={<BellOutline path={undefined} />}
      className="opacity-25"
    />
    <MenuTab
      to="/admin/groups"
      label="Groups"
      icon={<UserGroupOutline path={undefined} />}
    />
    <MenuTab
      to="/admin/notices"
      label="Notices"
      icon={<SpeakerphoneOutline path={undefined} />}
    />
    */}
    <MenuTab
      to="/admin/messages"
      label="Messages"
      icon={<ChatAltOutline path={undefined} />}
    />
    <MenuTab
      to="/admin/settings"
      label="Settings"
      icon={<CogOutline path={undefined} />}
    />
  </ul>
);

interface IMenuTab {
  to: string;
  icon?: JSX.Element;
  label: string | JSX.Element;
  className?: string;
}

const MenuTab: FC<IMenuTab> = ({ to, icon, label, className }) => {
  return (
    <li className={className}>
      <NavLink
        to={to}
        activeClassName="!bg-gblue-700 !text-white"
        className="flex flex-col items-center justify-center p-3 overflow-hidden bg-transparent cursor-pointer text-gnavy-200 hover:bg-frost-200 hover:text-white"
      >
        <div>
          {icon &&
            React.cloneElement(icon, {
              className: 'w-5 h-5 ml-px flex-shrink-0',
            })}
        </div>
        <div className="truncate text-2xs text-center">{label}</div>
      </NavLink>
    </li>
  );
};
