import React, { FC } from 'react';
import classNames from 'classnames';
import { PencilAlt } from 'heroicons-react';

export interface IInputWrapper {
  isFocussed?: boolean;
  labelText?: string;
  labelPosition?: 'top' | 'bottom' | 'left' | 'right';
  hintText?: string;
  error?: string | null;
  border?: boolean;
  small?: boolean;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
}

export const InputWrapper: FC<IInputWrapper> = ({
  isFocussed = false,
  children,
  className,
  labelText,
  hintText,
  icon,
  labelPosition = icon ? 'left' : 'top',
  border = true,
  error = null,
  small = false,
  disabled = false,
  name,
}) => {
  return (
    <div
      className={classNames(
        'min-w-0 rounded flex-shrink-0 border-2 bg-white',
        error
          ? 'border-red-300 focus-within:border-red-600'
          : disabled
          ? border
            ? 'border-gray-300 focus-within:border-gray-500'
            : 'border-transparent'
          : border
          ? 'border-gray-400 focus-within:border-blue-500 focus-within:bg-white'
          : 'border-transparent',
        className,
      )}
    >
      <label
        className={classNames(
          'uppercase tracking-wide font-semibold flex bg-gray-100 items-stretch',
          error
            ? 'text-red-500 focus-within:text-red-700 focus-within:bg-red-100'
            : disabled
            ? 'text-gray-500'
            : 'border-blue-300 text-gray-700 focus-within:text-blue-600 focus-within:bg-blue-100',
          {
            'flex-col': labelPosition === 'top',
            'flex-col-reverse': labelPosition === 'bottom',
            'flex-row': labelPosition === 'left',
            'flex-row-reverse': labelPosition === 'right',
          },
          small ? 'text-2xs p-0' : 'text-xs',
        )}
        htmlFor={name}
      >
        {(error || labelText || icon) && (
          <div
            className={classNames(
              'flex items-center flex-shrink-0 overflow-hidden',
              error ? 'bg-red-100 border-red-200' : 'border-gray-200',
              {
                'border-b': labelPosition === 'top',
                'border-t': labelPosition === 'bottom',
                'border-r': labelPosition === 'left',
                'border-l': labelPosition === 'right',
              },
            )}
          >
            {icon && (
              <div className="flex items-center self-stretch justify-center ml-2 -mr-2">
                {icon}
              </div>
            )}
            <div className="flex flex-col justify-center w-full truncate">
              <div
                className={classNames(
                  'truncate w-full flex justify-between',
                  small ? 'py-px px-1' : 'py-1 px-2',
                )}
              >
                <div className="text-2xs">{labelText}</div>
                {isFocussed && labelPosition === 'top' && (
                  <PencilAlt path={undefined} className="w-4 h-4 -mr-1" />
                )}
              </div>
              {(error || hintText) && (
                <span
                  className={classNames(
                    'pb-1 px-2 truncate font-normal normal-case opacity-75',
                  )}
                >
                  {error || hintText}
                </span>
              )}
            </div>
          </div>
        )}
        {children}
      </label>
    </div>
  );
};
