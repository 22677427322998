import React, { FC } from 'react';
import { Check } from 'heroicons-react';
import ReactTooltip from 'react-tooltip';
import { InformationCircle } from 'heroicons-react';

interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  hintText?: string;
  name: string;
  small?: Boolean;
}

export const Checkbox: FC<ICheckbox> = ({
  children,
  labelText,
  hintText,
  name,
  small = false,
  ...props
}) => {
  return (
    <label className="flex items-center text-gray-800 cursor-pointer select-none focus-within:text-blue-500 focus-within:font-semibold focus-within:underline">
      <input
        type="checkbox"
        className="w-0 h-0 opacity-0"
        name={name}
        {...props}
      />
      <div
        className={`flex items-center justify-center w-5 h-5 p-0 mb-px mr-2 border-2 rounded ${
          props.checked
            ? 'bg-blue-500 border-blue-500'
            : 'bg-gray-200 border-gray-500'
        }`}
      >
        {props.checked && <Check path={undefined} className="w-4 text-white" />}
      </div>
      <span className={small ? 'text-xs w-full' : 'text-sm w-full'}>
        {labelText}
      </span>
      {hintText && (
        <InformationCircle
          path={undefined}
          className="w-4 mx-1 text-gray-400 hover:text-blue-500"
          data-tip={hintText}
        />
      )}
      <ReactTooltip
        effect="solid"
        backgroundColor="black"
        className="max-w-xs"
      />
    </label>
  );
};
