import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpdateMessageTemplateMutationVariables = {
  messagesSentTemplateId: Types.Scalars['uuid'],
  templateId: Types.Scalars['uuid'],
  object?: Types.Maybe<Types.Message_Templates_Set_Input>,
  messagesSentInsertInput: Array<Types.Messages_Sent_Insert_Input>
};


export type UpdateMessageTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_messages_sent: Types.Maybe<(
    { __typename?: 'messages_sent_mutation_response' }
    & Pick<Types.Messages_Sent_Mutation_Response, 'affected_rows'>
  )>, update_message_templates_by_pk: Types.Maybe<(
    { __typename?: 'message_templates' }
    & Pick<Types.Message_Templates, 'id' | 'is_active' | 'template_body' | 'template_title' | 'template_type_id'>
  )>, insert_messages_sent: Types.Maybe<(
    { __typename?: 'messages_sent_mutation_response' }
    & Pick<Types.Messages_Sent_Mutation_Response, 'affected_rows'>
  )> }
);


export const UpdateMessageTemplateDocument = gql`
    mutation UpdateMessageTemplate($messagesSentTemplateId: uuid!, $templateId: uuid!, $object: message_templates_set_input, $messagesSentInsertInput: [messages_sent_insert_input!]!) {
  delete_messages_sent(where: {message_template: {template_type_id: {_eq: "promo_message"}}, message_template_id: {_eq: $messagesSentTemplateId}}) {
    affected_rows
  }
  update_message_templates_by_pk(pk_columns: {id: $templateId}, _set: $object) {
    id
    is_active
    template_body
    template_title
    template_type_id
  }
  insert_messages_sent(objects: $messagesSentInsertInput) {
    affected_rows
  }
}
    `;

export function useUpdateMessageTemplateMutation() {
  return Urql.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument);
};