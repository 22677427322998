import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetCustomerDetailsQueryVariables = {
  customer_id: Types.Scalars['uuid']
};


export type GetCustomerDetailsQuery = (
  { __typename?: 'query_root' }
  & { customers_by_pk: Types.Maybe<(
    { __typename?: 'customers' }
    & Pick<Types.Customers, 'id' | 'name' | 'business_unit_id' | 'individual' | 'date_established' | 'date_incorporated' | 'nature_of_business' | 'place_of_incorporation' | 'loan_fee' | 'rate_id'>
    & { rate: (
      { __typename?: 'rate_cards' }
      & Pick<Types.Rate_Cards, 'id' | 'apr_lte_3000' | 'apr_gt_3000_lte_5000' | 'apr_gt_5000_lte_10000' | 'apr_gt_10000_lte_15000' | 'apr_gt_15000_lte_25000' | 'apr_gt_25000'>
    ), loans: Array<(
      { __typename?: 'loans' }
      & Pick<Types.Loans, 'id' | 'reference' | 'contract_date' | 'amount' | 'is_approved' | 'is_declined'>
    )>, addresses: Array<(
      { __typename?: 'addresses' }
      & Pick<Types.Addresses, 'id' | 'address1' | 'address2' | 'address3' | 'country' | 'postcode' | 'movein_date' | 'moveout_date'>
    )>, delegates_aggregate: (
      { __typename?: 'delegates_aggregate' }
      & { aggregate: Types.Maybe<(
        { __typename?: 'delegates_aggregate_fields' }
        & Pick<Types.Delegates_Aggregate_Fields, 'count'>
      )> }
    ), delegates: Array<(
      { __typename?: 'delegates' }
      & { user: (
        { __typename?: 'users' }
        & Pick<Types.Users, 'email'>
      ) }
    )> }
  )> }
);


export const GetCustomerDetailsDocument = gql`
    query GetCustomerDetails($customer_id: uuid!) {
  customers_by_pk(id: $customer_id) {
    id
    name
    business_unit_id
    individual
    date_established
    date_incorporated
    nature_of_business
    place_of_incorporation
    loan_fee
    rate_id
    rate {
      id
      apr_lte_3000
      apr_gt_3000_lte_5000
      apr_gt_5000_lte_10000
      apr_gt_10000_lte_15000
      apr_gt_15000_lte_25000
      apr_gt_25000
    }
    loans {
      id
      reference
      contract_date
      amount
      is_approved
      is_declined
    }
    addresses(order_by: {movein_date: desc}) {
      id
      address1
      address2
      address3
      country
      postcode
      movein_date
      moveout_date
    }
    delegates_aggregate {
      aggregate {
        count
      }
    }
    delegates {
      user {
        email
      }
    }
  }
}
    `;

export function useGetCustomerDetailsQuery(options: Omit<Urql.UseQueryArgs<GetCustomerDetailsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetCustomerDetailsQuery>({ query: GetCustomerDetailsDocument, ...options });
};