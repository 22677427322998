import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertMessageMutationVariables = {
  message: Types.Messages_Insert_Input
};


export type UpsertMessageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_messages_one: Types.Maybe<(
    { __typename?: 'messages' }
    & Pick<Types.Messages, 'id'>
  )> }
);


export const UpsertMessageDocument = gql`
    mutation UpsertMessage($message: messages_insert_input!) {
  insert_messages_one(object: $message, on_conflict: {constraint: messages_pkey, update_columns: [id, subject, content, draft]}) {
    id
  }
}
    `;

export function useUpsertMessageMutation() {
  return Urql.useMutation<UpsertMessageMutation, UpsertMessageMutationVariables>(UpsertMessageDocument);
};