import { ReactComponent as StatementSvg } from 'assets/statement.svg';
import cssBase from 'components/CompactCard.module.css';
import { GetLoggedInUserQuery } from 'components/LoggedInUserProvider/graphql/GetLoggedInUserByUsername.generated';
import dayjs from 'dayjs';
import { motion, useReducedMotion } from 'framer-motion';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { FC } from 'react';
import { useDeleteQuoteMutation } from './graphql/DeleteQuote.generated';
import { useWrappedUrqlMutation } from 'toolbox';

import css from './LoanCardCompact.module.css';
import { ArrowRight } from 'heroicons-react';

interface ILoanCardCompact {
  loan: NonNullable<
    NonNullable<GetLoggedInUserQuery['user']>['selected_customer']
  >['loans'][number];
  onClick: () => void;
  disabled?: boolean;
}

export const LoanCardCompact: FC<ILoanCardCompact> = ({
  loan,
  disabled,
  onClick,
}) => {
  const reducedMotion = useReducedMotion();

  const deleteQuote = useWrappedUrqlMutation(useDeleteQuoteMutation, {
    // onSuccess: () => {},
    // onError: () => {},
  });

  const start_date = dayjs(loan?.contract_date).format('MMM YYYY');

  const number_of_payments = loan?.payments?.length - 1 ?? 0;
  const end_date = dayjs(
    loan?.payments?.[number_of_payments]?.payment_due_date,
  ).format('MMM YYYY');

  const amount_borrowed = loan?.payments_aggregate.aggregate?.sum?.amount ?? 0;
  const amount_repaid =
    loan?.payments_aggregate_repaid?.aggregate?.sum?.amount ?? 0;
  const principal_outstanding =
    loan?.payments_aggregate_unpaid?.aggregate?.sum?.split_principal ?? 0;
  const pct_repaid =
    amount_repaid > 0 ? (amount_repaid / amount_borrowed) * 100 : 0;

  if (!loan?.is_approved)
    return (
      <div
        className={[
          css.LoanCardCompact,
          cssBase.CompactCard,
          'cursor-default',
        ].join(' ')}
      >
        <main className="bg-gnavy-600 text-gnavy-900">
          <h2 className={css.LoanHeader}>
            <span>QUO{loan?.reference}</span>
            <span>{formatCurrency(loan?.amount, 'GBP')}</span>
          </h2>
          <h4 className="pb-1 text-sm opacity-75 text-gnavy-900">
            Pending documentation / review
          </h4>
        </main>
        <footer
          className="!bg-gnavy-500 !text-gnavy-800 cursor-pointer"
          onClick={() =>
            confirm('Are you sure you want to cancel this quote?') &&
            deleteQuote.dispatch({ id: loan?.id })
          }
        >
          Withdraw Application
        </footer>
      </div>
    );

  return (
    <motion.div
      onClick={disabled ? () => null : onClick}
      className={[css.LoanCardCompact, cssBase.CompactCard].join(' ')}
    >
      <div className={css.LoanGraphic}>
        <StatementSvg />
      </div>
      <main>
        <h2 className={css.LoanHeader}>
          <span>GRF {loan?.reference}</span>
          <span>{formatCurrency(loan?.amount, 'GBP')}</span>
        </h2>
        <div className={css.LoanProgressBar}>
          <motion.div
            className={css.LoanProgressBarFilledArea}
            initial={reducedMotion ? {} : { width: '0%' }}
            animate={reducedMotion ? {} : { width: `${pct_repaid}%` }}
            transition={reducedMotion ? {} : { ease: 'linear', duration: 0.4 }}
          />
        </div>
        <h4 className={css.LoanDates}>
          <span className="">
            {start_date} <ArrowRight path={undefined} />
          </span>
          <span className="truncate">{end_date}</span>
        </h4>
        <h4 className="text-sm font-semibold opacity-75">
          {[
            loan?.type === 'personal' ? 'Personal Loan' : null,
            loan?.type === 'hpa' ? 'Hire Purchase Agreement' : null,
            loan?.type === 'interest_only' ? 'Interest Only Loan' : null,
            loan?.type === 'bespoke' ? loan?.custom_type_name ?? 'Loan' : null,
          ]}
        </h4>
        <h3 className={css.LoanBalance}>
          {principal_outstanding > 0 ? (
            <>
              {formatCurrency(principal_outstanding, 'GBP')}
              &nbsp;<label>NET Balance</label>
            </>
          ) : (
            'Repaid in full'
          )}{' '}
        </h3>
      </main>
      <footer>View Loan</footer>
    </motion.div>
  );
};
