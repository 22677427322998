import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type AddUserToGroupMutationVariables = {
  group_id?: Types.Maybe<Types.Scalars['String']>,
  user_id?: Types.Maybe<Types.Scalars['String']>
};


export type AddUserToGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_groups_one: Types.Maybe<(
    { __typename?: 'user_groups' }
    & Pick<Types.User_Groups, 'group_id' | 'user_id'>
  )> }
);


export const AddUserToGroupDocument = gql`
    mutation AddUserToGroup($group_id: String = "", $user_id: String = "") {
  insert_user_groups_one(object: {group_id: $group_id, user_id: $user_id}) {
    group_id
    user_id
  }
}
    `;

export function useAddUserToGroupMutation() {
  return Urql.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument);
};