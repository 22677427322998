import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetRateByIdQueryVariables = {
  id: Types.Scalars['String']
};


export type GetRateByIdQuery = (
  { __typename?: 'query_root' }
  & { rate_cards_by_pk: Types.Maybe<(
    { __typename?: 'rate_cards' }
    & Pick<Types.Rate_Cards, 'id' | 'apr_gt_10000_lte_15000' | 'apr_gt_15000_lte_25000' | 'apr_gt_25000' | 'apr_gt_3000_lte_5000' | 'apr_gt_5000_lte_10000' | 'apr_lte_3000'>
  )> }
);


export const GetRateByIdDocument = gql`
    query GetRateById($id: String!) {
  rate_cards_by_pk(id: $id) {
    id
    apr_gt_10000_lte_15000
    apr_gt_15000_lte_25000
    apr_gt_25000
    apr_gt_3000_lte_5000
    apr_gt_5000_lte_10000
    apr_lte_3000
  }
}
    `;

export function useGetRateByIdQuery(options: Omit<Urql.UseQueryArgs<GetRateByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetRateByIdQuery>({ query: GetRateByIdDocument, ...options });
};