import classNames from 'classnames';
import { motion, useReducedMotion } from 'framer-motion';
import React, { FC } from 'react';

import css from './ModalTopBar.module.css';

interface ITopBar {
  title: string;
  blue?: boolean;
  buttons: React.ReactNode;
}

export const ModalTopBar: FC<ITopBar> = ({ title, blue = false, buttons }) => {
  const reducedMotion = useReducedMotion();
  return (
    <motion.div
      className={classNames(
        css.ModalTopBar,
        blue ? 'border-blue-400' : 'border-teal-400',
      )}
      initial={reducedMotion ? {} : { translateY: -100 }}
      animate={reducedMotion ? {} : { translateY: 0 }}
      exit={reducedMotion ? {} : { translateY: -100 }}
      transition={reducedMotion ? {} : { delay: 0.1 }}
      id="ModalTopBar"
    >
      <div className={css.ModalTopBarPadding}>
        <h2>{title}</h2>
        {buttons}
      </div>
    </motion.div>
  );
};
