import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetGroupByIdQueryVariables = {
  id: Types.Scalars['String']
};


export type GetGroupByIdQuery = (
  { __typename?: 'query_root' }
  & { members: Array<(
    { __typename?: 'users' }
    & Pick<Types.Users, 'cognito_sub' | 'name' | 'email'>
  )>, non_members: Array<(
    { __typename?: 'users' }
    & Pick<Types.Users, 'cognito_sub' | 'name' | 'email'>
  )> }
);


export const GetGroupByIdDocument = gql`
    query GetGroupById($id: String!) {
  members: users(where: {groups: {group_id: {_eq: $id}}}, order_by: {email: asc}) {
    cognito_sub
    name
    email
  }
  non_members: users(where: {_not: {groups: {group_id: {_eq: $id}}}}, order_by: {email: asc}) {
    cognito_sub
    name
    email
  }
}
    `;

export function useGetGroupByIdQuery(options: Omit<Urql.UseQueryArgs<GetGroupByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGroupByIdQuery>({ query: GetGroupByIdDocument, ...options });
};