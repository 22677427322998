import React, { FC } from 'react';
import { useLoggedInUser } from 'components/LoggedInUserProvider/LoggedInUserProvider';

import css from './UsernameText.module.css';

export const UsernameText: FC = ({ ...props }) => {
  const { user } = useLoggedInUser();
  return (
    <p className={css.UsernameText} {...props}>
      {user?.email}
    </p>
  );
};
