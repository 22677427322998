import React, { useMemo } from 'react';
import {
  cacheExchange,
  Client,
  dedupExchange,
  fetchExchange,
  Provider,
} from 'urql';
import { useAuthWrapperContext } from 'toolbox/src/components/AuthWrapper/AuthWrapper';

export const UrqlWrapperWithAuth: React.FC = ({ children }) => {
  const { idTokenJwt } = useAuthWrapperContext();

  const client = useMemo(() => {
    return new Client({
      url: process.env.REACT_APP_HASURA_ENDPOINT,
      suspense: false,
      fetchOptions: () => ({
        headers: {
          Authorization: `Bearer ${idTokenJwt}`,
        },
      }),
      requestPolicy: 'cache-and-network',
      exchanges: [dedupExchange, cacheExchange, fetchExchange],
    });
  }, [idTokenJwt]);

  return <Provider value={client}>{children}</Provider>;
};
