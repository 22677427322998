import React, { FC } from 'react';
import { useWindupString } from 'windups';

import css from './GreetingText.module.css';

interface IGreetingText {
  name: string;
}

export const GreetingText: FC<IGreetingText> = ({ name, ...props }) => {
  const hour = new Date().getHours();
  const greeting =
    'Good ' +
    ((hour < 12 && 'Morning') || (hour < 18 && 'Afternoon') || 'Evening');

  const [windupText] = useWindupString(`${greeting} ${name.split(' ')[0]}`);

  return (
    <h2 className={css.GreetingText} {...props}>
      {name && windupText}
    </h2>
  );
};

export default GreetingText;
