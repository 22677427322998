import { Input } from 'components/Inputs/Input';
import React, { FC, useState, Suspense } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { EditCustomerPage } from './EditCustomerPage';
import {
  GetCustomersQuery,
  useGetCustomersQuery,
} from './graphql/GetCustomers.generated';
import { SearchOutline } from 'heroicons-react';

export const ManageCustomers: FC = () => {
  const location = useLocation();
  const [query] = useGetCustomersQuery();
  const [customerListFilter, setCustomerListFilter] = useState('');
  const [expandedCustomer, setExpandedCustomer] = useState(
    location.pathname.split('/admin/customers/')?.[1] || null,
  );

  return (
    <Suspense
      fallback={
        <div className="flex items-stretch justify-end w-full h-16 max-w-4xl p-3 border-b bg-gblue-400 border-gblue-500" />
      }
    >
      <div className="flex flex-grow bg-gblue-700">
        <div className="overflow-hidden border-l-8 border-r-8 rounded-tl-lg border-gblue-700 AdminScrollbars">
          <h3 className="mt-2 text-lg font-semibold text-white">Customers</h3>
          <div className="flex items-stretch justify-end py-2 ">
            <Input
              type="search"
              placeholder="Filter Customers"
              value={customerListFilter}
              onChange={(e) => setCustomerListFilter(e.target.value)}
              icon={<SearchOutline path={undefined} className="w-4" />}
              small
              clearButtonHandler={() => setCustomerListFilter('')}
              spellCheck="false"
              className="flex-grow"
              size={6}
            />
            <Link
              to="/admin/customers/new"
              className="flex items-center px-2 my-px ml-2 text-sm font-semibold text-teal-700 bg-teal-200 rounded"
              onClick={() => setExpandedCustomer('new')}
            >
              <span className="truncate">New Customer</span>
            </Link>
          </div>
          <div className="h-full pb-48 overflow-x-hidden overflow-y-scroll rounded h-200 AdminScrollbars">
            {query?.fetching ? (
              <li className="!bg-gray-300 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-gray-500">
                  Loading...
                </div>
              </li>
            ) : query?.error ? (
              <li className="!bg-red-200 rounded-b-md h-24 flex items-center">
                <div className="w-full font-semibold text-center text-red-700">
                  Error: {query?.error?.message}
                </div>
              </li>
            ) : (
              query?.data?.customers
                ?.filter(({ name }) =>
                  name
                    ?.toLowerCase()
                    .includes(customerListFilter.toLowerCase()),
                )
                .sort((a, b) => (a?.name > b?.name ? 1 : -1))
                .map(
                  (customer) =>
                    customer?.id && (
                      <CustomerRow
                        key={customer.id}
                        customer={customer}
                        expanded={expandedCustomer === customer?.id}
                        openHandler={() => setExpandedCustomer(customer?.id)}
                        closeHandler={() => {
                          setExpandedCustomer('');
                          // history.push('/admin/customers');
                        }}
                      />
                    ),
                )
            )}
          </div>
        </div>
        <div className="flex-grow w-2/3 h-screen mt-2 overflow-x-hidden overflow-y-scroll AdminScrollbars">
          {expandedCustomer ? (
            <EditCustomerPage
              customerId={expandedCustomer}
              closeHandler={() => setExpandedCustomer(null)}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full text-sm tracking-wide text-gnavy-900">
              Click on a customer to edit the account
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

interface ICustomerRow {
  customer: GetCustomersQuery['customers'][number];
  expanded?: boolean;
  openHandler: () => any;
  closeHandler: () => any;
}
const CustomerRow: FC<ICustomerRow> = ({
  customer,
  expanded = false,
  openHandler,
  closeHandler,
}) => {
  if (!customer?.id) return null;
  return (
    <NavLink
      to={`/admin/customers/${customer?.id}`}
      className="flex justify-between p-2 bg-teal-100"
      activeClassName="bg-teal-300"
      id={customer?.id}
      onClick={expanded ? closeHandler : openHandler}
    >
      <div className="flex items-center text-xs">
        <h3
          className={`font-semibold text-teal-800 flex items-center ${
            expanded ? 'text-teal-900' : ''
          }`}
        >
          {customer?.name}
        </h3>
      </div>
    </NavLink>
  );
};
