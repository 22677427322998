import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'routeMap';
import { useGetGroupByIdQuery } from './graphql/GetGroupById.generated';
import { Input } from 'components/Inputs/Input';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { ButtonBase, useWrappedUrqlMutation } from 'toolbox';
import { useInsertGroupMutation } from './graphql/InsertGroup.generated';
import { useRenameGroupMutation } from './graphql/RenameGroup.generated';
import { useDeleteGroupByIdMutation } from './graphql/DeleteGroupById.generated';
import GroupMember from './GroupMember';

interface IEditGroupPage {
  groupId: string;
  closeHandler: () => void;
}

export const EditGroupPage: FC<IEditGroupPage> = ({ groupId }) => {
  const navigate = useNavigate();
  const history = useHistory();

  const [formGroupName, setFormGroupName] = useState<string>('');

  const isNewGroup = groupId == 'new';

  const [q, refetch] = useGetGroupByIdQuery({
    variables: { id: groupId },
    pause: !groupId || isNewGroup,
  });

  const members = q?.data?.members;
  const non_members = q?.data?.non_members;

  // update component state after data fetch
  useEffect(() => {
    if (q?.data) {
      if (!isNewGroup) setFormGroupName(groupId);
      else setFormGroupName('');
    }
  }, [q, groupId]);

  const insertGroupMutation = useWrappedUrqlMutation(useInsertGroupMutation, {
    onSuccess: (res) => {
      // setGroupName(formGroupName);
      history.push(`/admin/groups/${formGroupName}`);
    },
    onError: (e) => console.warn(e),
  });

  const renameGroupMutation = useWrappedUrqlMutation(useRenameGroupMutation, {
    onSuccess: (res) => {
      // setGroupName(formGroupName);
      history.push(`/admin/groups/${formGroupName}`);
    },
    onError: (e) => console.warn(e),
  });

  const deleteGroupMutation = useWrappedUrqlMutation(
    useDeleteGroupByIdMutation,
    {
      onSuccess: (res) => {
        navigate.adminGroups();
      },
      onError: (e) => console.warn(e),
    },
  );

  const saveGroup = async () => {
    if (isNewGroup) {
      await insertGroupMutation.dispatch({ groupId: formGroupName });
    } else {
      await renameGroupMutation.dispatch({
        groupId: groupId,
        newName: formGroupName,
      });
    }
  };

  const deleteGroup = async () => {
    await deleteGroupMutation.dispatch({ id: groupId });
  };

  if (q?.data)
    return (
      <section className="self-stretch">
        <h3 className="p-3 pb-3 bg-white rounded-t">
          {groupId == 'new' ? `New Group` : groupId}
        </h3>

        <div className="fixed top-0 right-0 mt-12 mr-3 rounded bg-frost-800">
          <div className="flex justify-end p-2">
            {groupId !== 'new' ? (
              <ButtonBase
                className="p-2 px-4 mr-2 text-sm font-semibold text-red-500 bg-white rounded-md shadow-none btn hover:text-red-800 hover:bg-red-200 hover:shadow-md"
                onClick={() => deleteGroup()}
              >
                Delete Group
              </ButtonBase>
            ) : undefined}
            <button
              className="p-2 px-4 text-sm font-semibold text-white rounded-md shadow-md btn bg-gblue-900 disabled:bg-gray-500 disabled:opacity-50"
              disabled={formGroupName == groupId || !formGroupName}
              onClick={() => saveGroup()}
            >
              {groupId === 'new' ? 'Create Group' : 'Save Changes'}
            </button>
          </div>
        </div>

        <div className="flex flex-col space-y-3 p-3 pb-10 bg-white rounded-b">
          <Input
            type="text"
            labelText="Group Name"
            className="col-span-3"
            value={formGroupName}
            onChange={(e) => setFormGroupName(e.target.value)}
          />

          {!isNewGroup && (
            <div className="col-span-6 bg-white">
              <h2 className="flex justify-between py-2 text-xl">
                Group Members
                {q?.fetching && (
                  <LoadingSpinner className="float-right w-6 h-6 text-gblue-600" />
                )}
              </h2>
              <div className="flex mb-4 rounded-b-lg space-x-4">
                <ul className="w-1/2 h-64 overflow-y-auto border-2 rounded flex-col">
                  {members?.map((member) => (
                    <GroupMember
                      key={member.cognito_sub}
                      groupId={groupId}
                      user={member}
                      isMember={true}
                      callback={() => refetch()}
                    />
                  ))}
                </ul>

                <ul className="w-1/2 h-64 overflow-y-auto border-2 rounded flex-col">
                  {non_members?.map((non_member) => (
                    <GroupMember
                      key={non_member.cognito_sub}
                      groupId={groupId}
                      user={non_member}
                      callback={() => refetch()}
                    />
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>
    );

  return null;
};
