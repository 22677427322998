import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetMessagesQueryVariables = {};


export type GetMessagesQuery = (
  { __typename?: 'query_root' }
  & { messages: Array<(
    { __typename?: 'messages' }
    & Pick<Types.Messages, 'id' | 'created_at' | 'draft' | 'subject' | 'content'>
    & { recipients: Array<(
      { __typename?: 'messages_recipients' }
      & { user: (
        { __typename?: 'users' }
        & Pick<Types.Users, 'cognito_sub' | 'email' | 'name'>
      ) }
    )> }
  )> }
);


export const GetMessagesDocument = gql`
    query GetMessages {
  messages(where: {type: {_eq: custom_message}}) {
    id
    created_at
    draft
    subject
    content
    recipients {
      user {
        cognito_sub
        email
        name
      }
    }
  }
}
    `;

export function useGetMessagesQuery(options: Omit<Urql.UseQueryArgs<GetMessagesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetMessagesQuery>({ query: GetMessagesDocument, ...options });
};