import { makeMutationForm } from 'components/makeMutationForm';
import { Rate_Cards } from 'graphql/schemaTypes.generated';

export const EditRateForm = makeMutationForm<Partial<Rate_Cards>>({
  config: {
    id: {
      type: 'text',
    },
    apr_lte_3000: { type: 'number' },
    apr_gt_3000_lte_5000: { type: 'number' },
    apr_gt_5000_lte_10000: { type: 'number' },
    apr_gt_10000_lte_15000: { type: 'number' },
    apr_gt_15000_lte_25000: { type: 'number' },
    apr_gt_25000: { type: 'number' },
  },
  showErrorsOnTouched: true,
  validate: ({
    id,
    apr_lte_3000,
    apr_gt_3000_lte_5000,
    apr_gt_5000_lte_10000,
    apr_gt_10000_lte_15000,
    apr_gt_15000_lte_25000,
    apr_gt_25000,
  }) => {
    if (!id) {
      return {
        id: 'You must specify an ID',
      };
    }
    if (!apr_lte_3000) {
      return {
        apr_lte_3000: 'Please specify an APR for loans less than £3k',
      };
    }
    if (!apr_gt_3000_lte_5000) {
      return {
        apr_gt_3000_lte_5000:
          'Please specify an APR for loans between £3k and £5k',
      };
    }
    if (!apr_gt_5000_lte_10000) {
      return {
        apr_gt_5000_lte_10000:
          'Please specify an APR for loans between £5k and £10k',
      };
    }
    if (!apr_gt_10000_lte_15000) {
      return {
        apr_gt_10000_lte_15000:
          'Please specify an APR for loans between £10k and £15k',
      };
    }
    if (!apr_gt_15000_lte_25000) {
      return {
        apr_gt_15000_lte_25000:
          'Please specify an APR for loans between £15k and £25k',
      };
    }
    if (!apr_gt_25000) {
      return {
        apr_gt_25000: 'Please specify an APR for loans greater than £25k',
      };
    }
  },
});
