import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetGroupsQueryVariables = {};


export type GetGroupsQuery = (
  { __typename?: 'query_root' }
  & { groups: Array<(
    { __typename?: 'groups' }
    & Pick<Types.Groups, 'id'>
  )> }
);


export const GetGroupsDocument = gql`
    query GetGroups {
  groups(order_by: {id: asc}) {
    id
  }
}
    `;

export function useGetGroupsQuery(options: Omit<Urql.UseQueryArgs<GetGroupsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGroupsQuery>({ query: GetGroupsDocument, ...options });
};